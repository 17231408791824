import { Link } from "react-router-dom";

import {FaCaretRight} from "react-icons/fa";
import React from "react";
type Breadcrumb = {
    label: string;
    path?: string;
};

interface incomingProps {
	data: Breadcrumb[];
}


export default function Breadcrumbs({ data }: incomingProps) {

    return (
        <div className="breadcrumbs">
            {data.map((breadcrumb: any, i: any) => {
                if ((i + 1) == data.length) {
                    return (<p className="title" key={i}>{breadcrumb.label}</p>)
                } else {
                    return (
                        <React.Fragment key={i}>
                            {breadcrumb.path ?
                                <Link to={breadcrumb.path}>{breadcrumb.label}</Link>
                                :
                                <p>{breadcrumb.label}</p>
                            }
                            <FaCaretRight />
                        </React.Fragment>
                    )
                }
            })}
        </div>
    )

}
