import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from 'react-select';
import { useForm, Controller } from "react-hook-form";
import Header from '../components/header';
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import { useResidenceApi } from "../_common/hooks/api/residenceApiHook";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import toast from "react-hot-toast";
import { URLS } from "../_config";
import LeftPanel from "../components/leftPanel";

export default function MoreAboutProperty() {
    const navigate = useNavigate();
    const { showLoader, hideLoader } = useAppLoader();
    const commonApi = useCommonApi();
    const residenceApi = useResidenceApi();

    const residenceID = sessionStorage.getItem('residenceID');
    const [amenities, setAmenities] = useState<any>(null);
    const [prevData, setPrevData] = useState<any>(null);
    const [prevDataChecked, setPrevDataChecked] = useState<boolean>(false);

    const { register, handleSubmit, control, formState: { errors, isValid }, getValues, watch, reset } = useForm({ mode: "onChange" });

    const options: any = [
        { label: 'Yes', value: 1 },
        { label: 'No', value: 0 }
    ]

    const getResidenceAdditionalInfo = () => {
        residenceApi.getResidenceAdditionalInfo({
            ViewName: 'Nice_To_Haves'
        }, (message: string, resp: any) => {
            let _amenities: any = [];

            resp.map((amenity: any) => {
                if (amenity.recordId.search('000000') !== 0) {
                    _amenities.push(amenity);
                }
            });
            setAmenities(_amenities);
            console.log({ _amenities })
        }, (message: string) => {
            // console.log(message);
        });
    }

    const onSubmit = (data: any) => {
        console.log('Data=>', data);
        let _amenities: any = [];
        Object.keys(data).map((amenityID: any) => {
            if (data[amenityID]["value"] == 1) {

                const tempData = amenities.filter((item: any) => {
                    return item.mainText == amenityID
                })
                console.log({ tempData })

                let param: any = {
                    "AmenityId": tempData[0]["recordId"],
                    "FacilityId": residenceID
                }

                if (prevData) {
                    // param.recordId = prevData?.amenityID;
                    param.recordId = prevData[tempData[0]["recordId"]];
                }
                _amenities.push(param)
            }
        });
        // console.log("subm====>",{_amenities}); return;
        let params: any = {
            "entityName": "Capacity",
            "requestName": "AddMultipleReq",
            "inputParamters": { "Amenities": _amenities }
        }

        showLoader();
        if (_amenities && _amenities.length > 0) {
            commonApi.post(params, (message: string, resp: any) => {
                hideLoader();
                navigate('/upload-property-images')
            }, (message: string) => {
                hideLoader();
                toast.error(message);
            });
        } else {
            hideLoader();
            navigate('/upload-property-images')
        }

    }

    const getPrevData = () => {
        if (residenceID) {
            showLoader();
            //call api reset form values via reset()
            commonApi.retreive({
                "Entity": "Capacity",
                "Top": 100,
                "ReturnCols": ["CapacityId", "Quantity", "AmenityId", "AmenityIdName"],
                "FilterOn": {
                    "Type": "And",
                    "Where": [{
                        "filterCol": "FacilityId", "FilterCondition": "Equals",
                        "FilterValue": residenceID
                    }, {
                        "filterCol": "AmenityTypeId", "FilterCondition": "Equals",
                        "FilterValue": "881"
                    }]
                }
            }, (message: string, resp: any) => {
                hideLoader();
                console.log('prev-------------------', resp, JSON.parse(resp).length)
                let prevData = JSON.parse(resp);
                if (prevData.length) {
                    let resetData: any = {};
                    prevData.map((data: any) => {
                        resetData[data.AmenityId] = data.CapacityId
                    })
                    setPrevData(resetData)
                    console.log({ resetData });

                } else {
                    console.log('More about property has no previous data')
                }
                setPrevDataChecked(true);
            }, (message: string) => {
                hideLoader();
                toast.error(message);
            });
        } else {
            console.log("Start A New Residence")
        }
    }

    useEffect(() => {
        if (residenceID == null) {
            navigate('/add-residence')
        } else {
            getResidenceAdditionalInfo();
        }
    }, [])

    useEffect(() => {
        if (amenities) {
            getPrevData()
        }
    }, [amenities])


    const getRadioStatus = (value: any, prev: any) => {
        if (prev) {
            return value == 1;
        } else {
            return value == 0;
        }
    }

    useEffect(() => {
        console.log({ prevData })
        if (prevData) {
            console.log({ amenities })
            amenities?.map((amenity: any) => {
                console.log("checU ===>", prevData[amenity?.recordId], amenity.recordId)
                console.log("checkDone ===>", prevData[amenity?.recordId] != undefined)
            })
        }
    }, [prevData])

    return (
        <React.Fragment>
            <div className="dashboard">
                <Header />
                <section className="container">
                    <div className="custome-container-inner d-flex">
                        <LeftPanel page={3} />
                        <div className="white-block p-3 white-box-wide">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col">
                                        <h1>More information about your property</h1>
                                    </div>
                                </div>
                                <hr />

                                {/* {prevDataChecked ?
                                    <div className="tab_scrollcontent row">
                                        {amenities?.map((amenity: any, i: any) => (
                                            <div className="col-sm-6" key={i}>
                                                <div className="mb-2">
                                                    <label htmlFor="exampleFormControlInput1" className="form-label">{amenity.mainText}</label>
                                                    <div className="d-flex">
                                                        {options.map((option: any, j: any) => (
                                                            <div className="me-5" key={i + '-' + j}>
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        value={option.value}
                                                                        defaultChecked={prevData ? prevData[amenity?.recordId] ? getRadioStatus(option?.value, true) : getRadioStatus(option?.value, false) : false}
                                                                        type="radio"
                                                                        id={i + '-' + j + '-' + amenity.recordId}
                                                                        {...register(amenity.recordId, { required: true })} />
                                                                    <label className="form-check-label" htmlFor={i + '-' + j + '-' + amenity.recordId}>
                                                                        {option.label}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        ))}


                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    : null
                                } */}

                                {prevDataChecked ?
                                    <div className="tab_scrollcontent row">
                                        {amenities?.map((amenity: any, i: any) => (
                                            <div className="col-sm-6" key={i}>
                                                <div className="mb-2">
                                                    <label htmlFor="exampleFormControlInput1" className="form-label">{amenity.mainText}</label>

                                                    <Controller
                                                        name={amenity.mainText}
                                                        control={control}
                                                        rules={{ required: true }}
                                                        render={({ field: { value, onChange, onBlur } }) => {
                                                            return (<Select
                                                                className={"form-control p-0 css-b62m3t-container"}
                                                                options={options ? options : []}
                                                                placeholder={""}
                                                                onChange={onChange}
                                                                value={value}
                                                                defaultValue={prevData ? prevData[amenity?.recordId] != undefined ? { label: 'Yes', value: 1 } : { label: 'No', value: 0 } : null}
                                                            />)
                                                        }}
                                                    />

                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    : null
                                }

                                <div className="mobbottom_fixbtn next-back-btn">
                                    <button type="submit" className="btn btn-primary w-100 mt-2 next-btn" disabled={!isValid} data-bs-toggle="button" >Next</button>
                                    <Link to={URLS.DESCRIBE_ROOMS} className="back-btn">
                                        <button type="button" className="btn btn-outline-secondary mt-2 w-100" data-bs-toggle="button" >Back</button>
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </React.Fragment>
    );
}