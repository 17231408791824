import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL, STORAGE } from '../../_config'
import { CallApi } from './api/callApi';
const API_BASE_URL = process.env.REACT_APP_API_URL;

export function* post(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_BASE_URL + '/ExecuteRequest', data);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.isSuccess) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* get(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_BASE_URL + '/ExecuteRequest', data);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.isSuccess && resp.data.outputParameters) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getInstitutions(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COMMON.GET_INSTITUTIONS, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* getCumpas(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COMMON.GET_CAMPUS, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* getCapacities(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COMMON.GET_CAPACITIES, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* retreive(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.COMMON.RETREIVE, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.success) {
            // console.log(resp)
            action && action.callbackSuccess && action.callbackSuccess(resp.data.results);
        } else {
            action && action.callbackError && action.callbackError(resp.data.errorMessage);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* retreiveInstitution(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COMMON.RETREIVE_INSTITUTION, data);
        // console.log(resp)
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data.errorMessage);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getRooms(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COMMON.GET_ROOMS, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getStudentCasesClassification(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COMMON.GET_STUDENT_CASES_CLASSIFICATION, data);
        // console.log(resp)
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data.errorMessage);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* retreiveCaseDetails(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COMMON.RETREIVE_CASE_DETAILS, data);
        // console.log(resp)
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data.errorMessage);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* retrieveStudentCaseRegardingId(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COMMON.RETREIVE_STUDENT_CASE_REGARDINGID, data);
        // console.log(resp)
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data.errorMessage);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getImportType(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COMMON.GET_IMPORT_TYPE, data);
        // console.log(resp)
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data.errorMessage);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}