import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Header from '../components/header'
import { useNavigationSelector, useUserSelector } from '../_common/hooks/selectors/userSelector';
import { NAVIGATIONS, URLS } from '../_config';

const MainDashboard = () => {
    const user: any = useUserSelector();
    const navigation: any = useNavigationSelector()
    const navigate = useNavigate();

    const [navigations, setNavigations] = useState<any>(null)

    useEffect(() => {
        if (navigation == undefined) {
            let storedNavigation = sessionStorage.getItem(NAVIGATIONS) || '[]';
            storedNavigation = JSON.parse(storedNavigation);
            setNavigations(storedNavigation)
        } else {
            setNavigations(navigation)
        }
    }, [navigation])

    return (
        <React.Fragment>
            <div className="dashboard">
                <Header />
                <div className="dashboard-wrapper">
                    <div className="dashboard-wrapper-inner">
                        <h3>Welcome</h3>
                        <h2>{user?.name}</h2>
                        <div className="dashboard-block-wrapper">
                            {navigations && navigations.length > 0 ?
                                navigations.map((items: any, index: number) => (
                                    <div className="dashboard-block" onClick={() => navigate(items?.navigate)} key={index}>
                                        <div className="dash-notification-icon">
                                            {items?.notification != 0 && items?.notification != null ?
                                                <span>{items?.notification}</span>
                                                : null
                                            }
                                            <i className={items?.icon} aria-hidden="true"></i>
                                        </div>
                                        <h4>{items?.title}</h4>
                                    </div>
                                ))
                                : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default MainDashboard