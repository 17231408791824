import React, { useEffect, useState } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { FaBeer } from 'react-icons/fa';
import { Link, useParams } from "react-router-dom";
import { useCommonApi } from "../../_common/hooks/api/common/commonApiHook";
import { useAppLoader } from "../../_common/hooks/common/appLoaderHook";
import { useUserSelector } from "../../_common/hooks/selectors/userSelector";
import AccomodationCard from "./components/accomdationCard";
import Breadcrumbs from "./components/breadcrumbs";
import Header from "./components/header";

export default function MyApplications() {
    const commonApi = useCommonApi();
    const { showLoader, hideLoader } = useAppLoader();
    const [applications, setApplications] = useState<any>(null);
    const user: any = useUserSelector();
    const { type } = useParams()

    const getApplications = () => {
        showLoader();
        commonApi.post({
            "recordId": user.recordId,
            "requestName": "RetrieveStudentApplications"
        }, (message: string, resp: any) => {
            hideLoader()
            if (resp.outputParameters.Applications) {
                setApplications(resp.outputParameters.Applications);
            }
            console.log('Residencies =>', resp.outputParameters.Applications)
        }, (message: string) => {
            toast.error(message);
            hideLoader();
        });
    }

    useEffect(() => {
        if (user) {
            getApplications()
        }
    }, [user])

    return (
        <React.Fragment>
            <div className="dashboard" style={{ paddingTop: "0px" }}>
                <Header />
                <div className="container">
                    <Breadcrumbs data={[]} />
                    {type == "success" ?
                        <Alert variant={'success'}>
                            Success! You’ve applied for this accomodation.
                        </Alert>
                        : null
                    }
                    <div><h4>My Applications</h4></div>
                    <Row>
                        {applications && applications.length > 0 ? applications?.map((application: any, i: any) => (
                            <Col md={6} key={i}>
                                <AccomodationCard data={application} revoke={true} getApplications={getApplications} route={"my-application-details"} />
                            </Col>
                        ))
                            :
                            <h6>No Applications</h6>
                        }
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}