import React, { useContext, useEffect, useState } from "react";
import { useAppLoader } from '../_common/hooks/common/appLoaderHook';
import { useUserApi } from "../_common/hooks/api/UserApiHook";
import { Container, Row, Col, Button } from 'react-bootstrap';
import IntroSlider from '../components/introSlider';
import { useForm } from "react-hook-form";
import { useSearchParams, useNavigate } from 'react-router-dom';

export default function Home() {
	const navigate = useNavigate();
	const { showLoader, hideLoader } = useAppLoader();
	const userApi = useUserApi();
	const [loginError, setLoginError] = useState<string | null>(null);
	const { register, handleSubmit, formState: { errors } } = useForm();
	const [searchParams] = useSearchParams();
	const type = searchParams.get('type');

	const onSubmit = (data: any) => {
		let params: any = {
			"entityName": "ExternalLogon",
			"requestName": "LoginExecuteRequest",
			"inputParamters": {
				"ExternalLogon": {
					"Username": data.email,
					"Password": data.password
				}
			}
		}
		showLoader();
		userApi.login(params, (message: string, resp: any) => {
			hideLoader();
			// sessionStorage.setItem('userDetails', JSON.stringify(resp.data.outputParameters.RequestResults))
		}, (message: string) => { // login error..
			setLoginError(message);
			hideLoader();
		});
	}

	useEffect(() => {
		if (type == null) {
			navigate('/entry-type')
		}
	}, [])

	return (
		<React.Fragment>
			<div className="register_page login_page">
				<div className="register_left">
					<div className="mob_logo">
						<a href="#" className="logo_left"><img src="/assets/img/logo.png" /></a>
					</div>
					<div className="logo_txt">
						Sign in to  you session
					</div>
					<form className="register_tab" onSubmit={handleSubmit(onSubmit)}>
						<div className="mb-2">
							<label htmlFor="exampleFormControlInput1" className={"form-label" + (errors?.email ? ' is-invalid' : '')}>Email address</label>
							<input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Email" {...register("email", { required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} />
							{errors?.email ? <div className="invalid-feedback">
								{errors.email.type === "required" ? 'Required' : null}
								{errors.email.type === "pattern" ? 'Invalid email address' : null}
							</div> : null}
						</div>
						<div className="mb-2">
							<label htmlFor="exampleFormControlInput1" className={"form-label" + (loginError || errors?.password ? ' is-invalid' : '')}>Password</label>
							<input type="password" className="form-control" id="exampleFormControlInput1" placeholder="Password" {...register("password", { required: true, maxLength: 80 })} />
							{errors?.password ? <div className="invalid-feedback">Required</div> : null}
							{loginError ? <div className="invalid-feedback">{loginError}</div> : null}
						</div>
						<Row className="mb-2">
							<Col md={5}>
								<div className="form-check">
									<input className="form-check-input" type="checkbox" value="" id="flexCheckCheckedDisabled" {...register("remember", {})} />
									<label className="form-check-label" htmlFor="flexCheckCheckedDisabled">
										Remember me
									</label>
								</div>
							</Col>
							<Col md={7} className="text-end small">
								<a href="/forgot-password">Forgot Password?</a> | <a href="/registration">New User?</a>
							</Col>
						</Row>
						<div className="mobbottom_fixbtn">
							<Button variant="primary" className="w-50 mt-2" type="submit">Login</Button>
						</div>
					</form>
					
				</div>
				<IntroSlider />
			</div>
		</React.Fragment>
	);
}