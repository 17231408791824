import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppLoader } from '../_common/hooks/common/appLoaderHook';
import { useUserApi } from "../_common/hooks/api/UserApiHook";
import toast from 'react-hot-toast';
import { Container, Row, Col, Button } from 'react-bootstrap';
import EntityProviderForm from '../components/registration/entityProviderForm';
import IndividualProviderForm from '../components/registration/individualProviderForm';
import AddressForm from '../components/registration/addressForm';
import IntroSlider from '../components/introSlider';
import { AiOutlineLeft } from 'react-icons/ai';

export default function Home() {
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useAppLoader();
  const userApi = useUserApi();
  const [tab, setTab] = useState<string>('indi');
  const [step, setStep] = useState<number>(1);

  const [entityProviderData, setEntityProviderData] = useState<any>(null);
  const [individualProviderData, setIndividualProviderData] = useState<any>(null);
  const [addressData, setAddressData] = useState<any>(null);
  const [documentData, setDocumentData] = useState<any>(null);



  const onSubmitEntityProviderForm = (data: any) => {
    setEntityProviderData(data);
    setStep(2)
  }
  const onSubmitIndividualProviderForm = (data: any) => {
    setIndividualProviderData(data);
    setStep(2)
  }

  const onSubmitAddressForm = (data: any, documents: any) => {
    setDocumentData(documents)
    setAddressData(data);
    //navigate('/login')

  }

  const registerEntity = () => {
    /* 
     {
    
              "TradingName": "Flexi Suite API ",
              "RegistrationName": "Flexis",
              "RegistrationNumber": "54555456",
              "APTypeId": "865",
              "IDTypeId": "862",
              "IDNumber": "2555656666",
              "VATNumber": "12323",
              "Mobile": "0125623",
              "Telephone": "251565",
              "Email": "siyandas@emial.com",
              "Password": "1234678"
            },
    
            {
              "StreetNumber": "102",
              "StreetName": "Lynwood Road",
              "Surburb": "Hatfield",
              "City": "Pretoria",
              "Province": "176",
              "PostalCode": "0158"
            },
    */
    let params: any = {
      "entityName": "Supplier",
      "requestName": "CreateRecordExecuteRequest",
      "inputParamters": {
        "AccomodationProviderInfo": entityProviderData,
        "Address": addressData,
        "Documents": documentData
      }

    }
    showLoader();
    userApi.registerEntity(params, (message: string, resp: any) => {
      hideLoader();
      toast.success(message);
      console.log(resp);
      navigate('/registration-successful')
      // navigate('/login')
    }, (message: string) => { // login error..
      hideLoader();
      toast.error(message);
    });

  }

  const registerIndividual = () => {

    let params: any = {
      "entityName": "Supplier",
      "requestName": "CreateRecordExecuteRequest",
      "inputParamters": {
        "AccomodationProviderInfo": individualProviderData,
        "Address": addressData,
        "Documents": documentData
      }

    }
    showLoader();
    userApi.registerIndividual(params, (message: string, resp: any) => {
      hideLoader();
      toast.success(message);
      console.log(resp);
      navigate('/registration-successful')
      // navigate('/login')
    }, (message: string) => { // login error..
      hideLoader();
      toast.error(message);
    });


  }


  useEffect(() => {
    if (addressData) {
      if (tab == 'indi') {
        registerIndividual();
      } else {
        registerEntity();
      }
    }
  }, [addressData])

  return (
    <React.Fragment>
      <div className="register_page">
        <div className="register_left">
          <div className="mob_logo">
            <a href="#" className="logo_left"><img src="/assets/img/logo.png" /></a>
          </div>
          <div className="logo_txt">
            Register your account
          </div>
          <div className="register_tab">
            <nav className="dual-menu">
              <a className={"btn-menu " + (tab == 'regi' ? 'active' : '')} onClick={() => { setTab('regi'); setStep(1) }} >Registered entity</a>
              <a className={"btn-menu " + (tab == 'indi' ? 'active' : '')} onClick={() => { setTab('indi'); setStep(1) }} >Individual</a>
            </nav>

            {tab == 'indi' ?
              <div className="tab-content" id="myTabContent">
                <div className={"tab-pane fade " + (step == 1 ? ' show active' : '')} id="profile" role="tabpanel" aria-labelledby="profile-tab">
                  <div className="reg_detailaddress">
                    <ul>
                      <li>
                        <div className="form-check">
                          <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked />
                          <label className="form-check-label" htmlFor="flexRadioDefault1">
                            Details
                          </label>
                        </div>

                      </li>
                      <li className="progress_area">
                        <div className="progress">
                          <div className="progress-bar" role="progressbar" style={{ "width": "50%" }} ></div>
                        </div>
                      </li>
                      <li>
                        <div className="form-check">
                          <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                          <label className="form-check-label" htmlFor="flexRadioDefault2">
                            Addresses
                          </label>
                        </div>

                      </li>
                    </ul>
                  </div>
                  <IndividualProviderForm onSubmitIndividualProviderForm={onSubmitIndividualProviderForm} />

                </div>
                <div className={"tab-pane fade" + (step == 2 ? ' show active' : '')} id="home" role="tabpanel" aria-labelledby="home-tab">
                  <div className="reg_detailaddress">
                  <AiOutlineLeft className="addressBack" onClick={() => setStep(1)}/>
                    <ul>
                      <li>
                        <div className="form-check">
                          <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault1a3" />
                          <label className="form-check-label" htmlFor="flexRadioDefault1a3">
                            Details
                          </label>
                        </div>

                      </li>
                      <li className="progress_area">
                        <div className="progress">
                          <div className="progress-bar" role="progressbar" style={{ "width": "100%" }}  ></div>
                        </div>
                      </li>
                      <li>
                        <div className="form-check">
                          <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault2a2" checked />
                          <label className="form-check-label" htmlFor="flexRadioDefault2a2">
                            Addresses
                          </label>
                        </div>

                      </li>
                    </ul>
                  </div>
                  <AddressForm tab={tab} onSubmitAddressForm={onSubmitAddressForm} />
                </div>

              </div>
              :
              <div className="tab-content" id="myTabContent">
                <div className={"tab-pane fade " + (step == 1 ? ' show active' : '')} id="profile" role="tabpanel" aria-labelledby="profile-tab">
                  <div className="reg_detailaddress">
                    <ul>
                      <li>
                        <div className="form-check">
                          <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked />
                          <label className="form-check-label" htmlFor="flexRadioDefault1">
                            Details
                          </label>
                        </div>

                      </li>
                      <li className="progress_area">
                        <div className="progress">
                          <div className="progress-bar" role="progressbar" style={{ "width": "50%" }} ></div>
                        </div>
                      </li>
                      <li>
                        <div className="form-check">
                          <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                          <label className="form-check-label" htmlFor="flexRadioDefault2">
                            Addresses
                          </label>
                        </div>

                      </li>
                    </ul>
                  </div>
                  <EntityProviderForm onSubmitEntityProviderForm={onSubmitEntityProviderForm} />

                </div>
                <div className={"tab-pane fade" + (step == 2 ? ' show active' : '')} id="home" role="tabpanel" aria-labelledby="home-tab">
                  <div className="reg_detailaddress">
                  <AiOutlineLeft className="addressBack" onClick={() => setStep(1)}/>
                    <ul>
                      
                      <li>
                        <div className="form-check">
                          <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault1a3" />
                          <label className="form-check-label" htmlFor="flexRadioDefault1a3">
                            Details
                          </label>
                        </div>

                      </li>
                      <li className="progress_area">
                        <div className="progress">
                          <div className="progress-bar" role="progressbar" style={{ "width": "100%" }}  ></div>
                        </div>
                      </li>
                      <li>
                        <div className="form-check">
                          <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault2a2" checked />
                          <label className="form-check-label" htmlFor="flexRadioDefault2a2">
                            Addresses
                          </label>
                        </div>

                      </li>
                    </ul>
                  </div>
                  <AddressForm tab={tab} onSubmitAddressForm={onSubmitAddressForm} />

                </div>

              </div>
            }

          </div>

        </div>
        <IntroSlider />
      </div>
    </React.Fragment>
  );
}