import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { AiOutlineLeft } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import IntroSlider from '../../components/introSlider'
import { useUserApi } from '../../_common/hooks/api/UserApiHook'
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook'
import StudentAddressForm from './components/registration/studentaddressForm'
import StudentDetailsForm from './components/registration/studentDetailsForm'

const StudentRegistration = () => {

    const navigate = useNavigate();
    const { showLoader, hideLoader } = useAppLoader();
    const userApi = useUserApi();

    const [step, setStep] = useState<number>(1);
    const [studentData, setStudentData] = useState<any>(null);
    const [addressData, setAddressData] = useState<any>(null);
    //   const [documentData, setDocumentData] = useState<any>(null);

    const onSubmitStudentDetailsForm = (data: any) => {
        setStudentData(data);
        setStep(2)
    }

    const onSubmitAddressForm = (data: any) => {
        // setDocumentData(documents)
        setAddressData(data);
    }

    const registerStudent = () => {
        console.log({ studentData, addressData })
        // return

        let params: any = {
            "entityName": "Employee",
                "requestName": "RegisterStudentExecuteRequest",
                    "inputParamters":
            {
                "Student": studentData,
                    "Address": addressData
            }

        }
        
    showLoader();
    userApi.registerStudent(params, (message: string, resp: any) => {
        hideLoader();
        toast.success(message);
        console.log(resp);
        navigate('/registration-successful')
        // navigate('/login')
    }, (message: string) => { // login error..
        hideLoader();
        toast.error(message);
    });

}

useEffect(() => {
    if (addressData) {
        registerStudent();
    }
}, [addressData])

return (
    <React.Fragment>
        <div className="register_page">
            <div className="register_left">
                <div className="mob_logo">
                    <a href="#" className="logo_left"><img src="/assets/img/logo.png" /></a>
                </div>
                <div className="logo_txt">
                    Register your account
                </div>
                <div className="register_tab">
                    <div className="tab-content" id="myTabContent">
                        <div className={"tab-pane fade " + (step == 1 ? ' show active' : '')} id="profile" role="tabpanel" aria-labelledby="profile-tab">
                            <div className="reg_detailaddress">
                                <ul>
                                    <li>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked />
                                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                Details
                                            </label>
                                        </div>

                                    </li>
                                    <li className="progress_area">
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ "width": "50%" }} ></div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                            <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                Addresses
                                            </label>
                                        </div>

                                    </li>
                                </ul>
                            </div>
                            <StudentDetailsForm onSubmitStudentDetailsForm={onSubmitStudentDetailsForm} />

                        </div>
                        <div className={"tab-pane fade" + (step == 2 ? ' show active' : '')} id="home" role="tabpanel" aria-labelledby="home-tab">
                            <div className="reg_detailaddress">
                                <AiOutlineLeft className="addressBack" onClick={() => setStep(1)} />
                                <ul>
                                    <li>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault1a3" />
                                            <label className="form-check-label" htmlFor="flexRadioDefault1a3">
                                                Details
                                            </label>
                                        </div>

                                    </li>
                                    <li className="progress_area">
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ "width": "100%" }}  ></div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault2a2" checked />
                                            <label className="form-check-label" htmlFor="flexRadioDefault2a2">
                                                Addresses
                                            </label>
                                        </div>

                                    </li>
                                </ul>
                            </div>
                            <StudentAddressForm onSubmitAddressForm={onSubmitAddressForm} />
                        </div>

                    </div>
                </div>
            </div>
            <IntroSlider />
        </div>
    </React.Fragment>
)
}

export default StudentRegistration