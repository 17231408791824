import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useCommonApi } from '../../../_common/hooks/api/common/commonApiHook';
import { useAppLoader } from '../../../_common/hooks/common/appLoaderHook';
import parse from 'html-react-parser';
import { useUserSelector } from '../../../_common/hooks/selectors/userSelector';
import { FaBed } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

interface TenantsRoomModalProps {
  handleClose: () => void;
  shouldShow: boolean;
  residence: any;
}


const StudentRoomModal = ({ shouldShow, handleClose, residence }: TenantsRoomModalProps) => {

  const { handleSubmit, control, formState: { errors }, register } = useForm();

  const commonApi = useCommonApi()
  const { showLoader, hideLoader } = useAppLoader()
  const user: any = useUserSelector();
  const navigate = useNavigate()

  const [studentId, setStudentId] = useState<string>("")

  const onSubmit = (data: any) => {
    console.log({ data })
    if (studentId !== undefined && studentId !== null && studentId !== "") {
      showLoader()
      commonApi.post({
        "entityName": "AccomodationApplications",
        "requestName": "UpsertRecordReq",
        "inputParamters":
        {
          "Entity": {
            "studentId": studentId,
            "capacityId": data.capacityId
          }
        }
      }, (message: string, resp: any) => {
        toast.success(message)
        handleClose()
        hideLoader()
        navigate(`/student/my-applications/success`)
      }, (message: string) => {
        toast.error(message);
        handleClose()
        hideLoader()
      });
    }
    else {
      console.log("studentId required")
    }
  }

  useEffect(() => {
    if (user) {
      setStudentId(user.recordId)
    }
  }, [user])

  return (
    <>
      <Modal show={shouldShow} onHide={handleClose}>
        <Modal.Body>
          <div className="tenant-modal">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <Row>

                  {residence && residence.rooms.length > 0 ? residence.rooms.map((item: any, i: number) => (
                    <Col sm={6} key={i}>
                      <div className="address-features-block">
                        <FaBed />
                        <h5>Single Room</h5>
                        <input
                          className="form-check-input"
                          value={item.capacityId}
                          type="radio"
                          {...register('capacityId', { required: true })}
                          id={item.capacityId}
                        />
                        <label htmlFor={item.capacityId}>
                          <div className="icon">{item.icon ? parse(item.icon) : null}</div>
                          <h4>{item?.iconName}</h4>
                          <div className="address-features-block-inner text-center">
                            <h6>Price</h6>
                            <h5>R{item?.pricePerBed}</h5>
                            <h6>Applications/Capacity</h6>
                            <h5>10/{item?.quantity}</h5>
                          </div>
                        </label>
                      </div>
                    </Col>
                  ))
                    : null
                  }

                </Row>
              </div>
              <div className='text-center'>
                <button type='submit' className='full-width-yellow-btn'>Confirm</button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default StudentRoomModal