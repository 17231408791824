import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";

import { Container, Row, Col, Button } from 'react-bootstrap';
import Select, { components } from 'react-select';
import { useForm, Controller } from "react-hook-form";
import { CheckNumber, handleKeyPressNumber } from "../_common/functions";
import Header from '../components/header';
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import { useResidenceApi } from "../_common/hooks/api/residenceApiHook";
import { access } from "fs/promises";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import toast from "react-hot-toast";
import { URLS } from "../_config";
import LeftPanel from "../components/leftPanel";
export default function AddRoom() {

    const navigate = useNavigate();
    const { showLoader, hideLoader } = useAppLoader();
    const commonApi = useCommonApi();
    const residenceApi = useResidenceApi();

    const residenceID = sessionStorage.getItem('residenceID');
    const residenceName = sessionStorage.getItem('residenceName');
    const [rooms, setRooms] = useState<any>(null);
    const [room, setRoom] = useState<any>(null);

    const [genders, setGenders] = useState<any>(null);
    const [caterings, setCaterings] = useState<any>(null);
    const [capacities, setCapacities] = useState<any>(null);
    const [accesses, setAccesses] = useState<any>(null);

    const { register, handleSubmit, control, formState: { errors, isValid }, getValues, watch, reset } = useForm({ mode: "onChange" });

    const [searchParams] = useSearchParams();
    const roomid = searchParams.get('room');




    const getResidenceRooms = () => {
        console.log('getResidenceRooms =>')
        residenceApi.getResidenceRooms({
            $filter: "FacilityId eq '" + residenceID + "'",
            "entity": "Capacity",
            "$select": 'CapacityId, Name, Quantity, GenderId, PricePerBed, DisabilityAccessId, CateringId, AmenityId, AmenityTypeId',
        }, (message: string, resp: any) => {
            console.log('getResidenceRooms =>  response')
            let data: any = [];

            resp[0].data.map((room: any) => {
                if (room.AmenityTypeId == 859) {
                    data.push(room)
                }
            })
            setRooms(data);
            if (roomid) {
                data.map((_room: any) => {
                    if (roomid == _room.CapacityId) {
                        setRoom(_room);
                        console.log(_room);

                        reset({
                            quantity: _room.Quantity,
                            price: _room.PricePerBed,
                            access: getAccessById(_room.DisabilityAccessId),
                            capacity: getCapacityById(_room.AmenityId),
                            catering: getCateringById(_room.CateringId),
                            gender: _room.GenderId
                        });
                        setTimeout(() => {
                            let el: any = document.getElementById("gender" + _room.GenderId);
                            el.click();
                        }, 500)



                    }
                })
            }

        }, (message: string) => {
            console.log(message);
        });



    }

    const getCaterings = () => {
        commonApi.post({
            "entityName": "Picklist",
            "requestName": "RetrieveSelectOptionExecuteRequest",
            "inputParamters":
            {
                "SelectOption": {
                    "OptionText": "Catering"

                }
            }
        }
            , (message: string, resp: any) => {
                // console.log('getCaterings=>', resp.outputParameters.data)
                setCaterings(resp.outputParameters.data.map((data: any) => {
                    data.label = data.optionText;
                    data.value = data.optionValue;
                    return data
                }))
            }, (message: string) => {
                // console.log(message);
            });
    }

    const getGender = () => {
        commonApi.post({
            "entityName": "Picklist",
            "requestName": "RetrieveSelectOptionExecuteRequest",
            "inputParamters":
            {
                "SelectOption": {
                    "OptionText": "Gender"

                }
            }
        }
            , (message: string, resp: any) => {
                console.log('getGender=>', resp.outputParameters.data)
                setGenders(resp.outputParameters.data)
            }, (message: string) => {
                // console.log(message);
            });
    }

    const getAccesses = () => {
        commonApi.post({
            "entityName": "Picklist",
            "requestName": "RetrieveSelectOptionExecuteRequest",
            "inputParamters":
            {
                "SelectOption": {
                    "OptionText": "YesNo"

                }
            }
        }
            , (message: string, resp: any) => {
                // console.log('getAccesses=>', resp.outputParameters.data);

                setAccesses(resp.outputParameters.data.map((data: any) => {
                    data.label = data.optionText;
                    data.value = data.optionValue;
                    return data
                }))
            }, (message: string) => {
                // console.log(message);
            });
    }

    const getCapacities = () => {
        commonApi.getCapacities({ ViewName: 'Room_Type_Lookup_View' }, (message: string, resp: any) => {
            // console.log('capacities=>', resp);
            let opt: any = [];
            resp.map((o: any) => {
                if (o.recordId.search('000000') !== 0) {
                    o.label = o.mainText;
                    o.value = o.recordId;
                    opt.push(o)
                }
            })
            setCapacities(opt);
        }, (message: string) => {
            // console.log(message);
        });
    }

    const getGenderName = (id: any) => {
        let name = '';
        genders.map((gen: any) => {
            // console.log(gen, id)
            if (gen.optionValue == id) {
                name = gen.optionText
            }
        });
        return name;
    }

    const onSubmit = (data: any) => {
        let params: any = {
            "Quantity": data.quantity,
            "GenderId": data.gender,
            "PricePerBed": data.price,
            "DisabilityAccessId": data.access.value,
            "Capacity": data.capacity.label,
            "AmenityId": data.capacity.value,
            "CateringId": data.catering.value,
            "FacilityId": residenceID,
            "AmenityTypeId": 859
        }

        let dataParams: any = {
            "entityName": "Capacity",
            "requestName": "UpsertRecordReq",
            "inputParamters": { "Entity": params }
        }
        if (room) {
            dataParams.recordId = room.CapacityId
        }
        // console.log(params)
        showLoader();
        commonApi.post(dataParams, (message: string, resp: any) => {
            hideLoader();
            navigate('/residence-summery')
        }, (message: string) => {
            hideLoader();
            toast.error(message);
        });


    }

    const getCateringById = (id: any) => {
        let res = null;
        caterings.map((cat: any) => {
            if (cat.value == id) {
                res = cat
            }
        });
        return res;
    }

    const getCapacityById = (id: any) => {
        let res = null;
        capacities.map((capacity: any) => {
            if (capacity.value == id) {
                res = capacity
            }
        });
        return res;
    }

    const getAccessById = (id: any) => {
        let res = null;
        accesses.map((access: any) => {
            if (access.value == id) {
                res = access
            }
        });
        return res;
    }

    useEffect(() => {
        if (residenceID == null) {
            navigate('/add-residence')
        } else {
            getGender();
            getAccesses();
            getCaterings();
            getCapacities();
            // getResidenceRooms();
        }
    }, []);
    useEffect(() => {
        if (genders && accesses && capacities && caterings) {
            getResidenceRooms();
        }
    }, [genders, accesses, capacities, caterings]);




    return (
        <React.Fragment>
            <div className="dashboard">
                <Header />
                <section className="container">
                    <div className="custome-container-inner d-flex">
                        <LeftPanel page={2} />
                        <div className="white-block p-3 white-box-wide">
                            <div className="row">
                                <div className="col">
                                    <h1>How many rooms do you have available at {residenceName}?</h1>
                                </div>
                            </div>
                            <hr />
                            <form onSubmit={handleSubmit(onSubmit)}>
                                {genders ?
                                    <React.Fragment>
                                        {rooms?.map((room: any, i: any) => (
                                            <div className="alert alert-warning orange-info" role="alert" key={i}>
                                                <div className="row">
                                                    <div className="col">{getGenderName(room.GenderId)} rooms</div>
                                                    <div className="col text-end">Added</div>
                                                </div>
                                            </div>
                                        ))}
                                    </React.Fragment>
                                    : null}




                                <div className="tab_scrollcontent">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="mb-2">
                                                <label className="form-label">Quantity</label>
                                                <input type="text" className="form-control"  {...register("quantity", { required: true })} onKeyPress={(event: any) => handleKeyPressNumber(event)}/>
                                            </div>
                                        </div>

                                        <div className="col-sm-6">
                                            <div className="mb-2">
                                                <label htmlFor="exampleFormControlInput2" className="form-label">Gender</label>
                                                <div className="row">
                                                    {genders?.map((gender: any, i: any) => (
                                                        <div className="col" key={i}>
                                                            <div className="border-box">
                                                                <div className="form-check">

                                                                    <input className="form-check-input" type="radio" value={gender.optionValue} id={"gender" + gender.optionValue} {...register("gender", { required: true })} />
                                                                    <label className="form-check-label text-center" htmlFor={"gender" + gender.optionValue}>
                                                                        <span><img src={"/assets/img/" + gender.optionText + ".png"} alt="" /></span><br />
                                                                        {gender.optionText}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="mb-2">
                                                <label htmlFor="exampleFormControlInput3" className="form-label">Price</label>
                                                <input type="text" className="form-control" id="exampleFormControlInput3" {...register("price", { required: true })}  onKeyPress={(event: any) => handleKeyPressNumber(event)}/>
                                            </div>
                                        </div>

                                        <div className="col-sm-6">
                                            <div className="mb-2">
                                                <label className="form-label">Disability Friendly Access?</label>
                                                <Controller
                                                    name={"access"}
                                                    control={control}
                                                    rules={{ required: true }}
                                                    render={({ field: { value, onChange, onBlur } }) => {
                                                        return (<Select
                                                            className={"form-control p-0" + (errors?.access ? ' is-invalid' : '')}
                                                            options={accesses}
                                                            placeholder={""}
                                                            onChange={onChange}
                                                            value={value}
                                                            defaultValue={value}
                                                        />)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="mb-2">
                                                <label className="form-label">Room Type?</label>
                                                <Controller
                                                    name={"capacity"}
                                                    control={control}
                                                    rules={{ required: true }}
                                                    render={({ field: { value, onChange, onBlur } }) => {
                                                        return (<Select
                                                            className={"form-control p-0" + (errors?.capacity ? ' is-invalid' : '')}
                                                            options={capacities}
                                                            placeholder={""}
                                                            onChange={onChange}
                                                            value={value}
                                                            defaultValue={value}
                                                        />)
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-sm-6">
                                            <div className="mb-2">
                                                <label className="form-label">Catering?</label>
                                                <Controller
                                                    name={"catering"}
                                                    control={control}
                                                    rules={{ required: true }}
                                                    render={({ field: { value, onChange, onBlur } }) => {
                                                        return (<Select
                                                            className={"form-control p-0" + (errors?.catering ? ' is-invalid' : '')}
                                                            options={caterings}
                                                            placeholder={""}
                                                            onChange={onChange}
                                                            value={value}
                                                            defaultValue={value}
                                                        />)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mobbottom_fixbtn next-back-btn">
                                    <button type="submit" className="btn btn-primary w-100 mt-2 next-btn" disabled={!isValid} data-bs-toggle="button" >Next</button>
                                    <Link to={URLS.ADD_RESIDENCE} className="back-btn">
                                        <button type="button" className="btn btn-outline-secondary mt-2 w-100" data-bs-toggle="button" >Back</button>
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </React.Fragment>
    );
}