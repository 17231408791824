import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useCommonApi } from '../../../_common/hooks/api/common/commonApiHook';
import { useAppLoader } from '../../../_common/hooks/common/appLoaderHook';
import parse from 'html-react-parser';
import { useUserSelector } from '../../../_common/hooks/selectors/userSelector';
import Select from 'react-select';

interface TenantsRoomModalProps {
  handleClose: () => void;
  shouldShow: boolean;
  residence?: any;
  getCasesByStudent?: any;
}


const CreateCaseModal = ({ shouldShow, handleClose, residence, getCasesByStudent }: TenantsRoomModalProps) => {

  const { handleSubmit, control, formState: { errors, isValid }, register, watch, reset } = useForm();

  const documents = watch('documents');
  const commonApi = useCommonApi()
  const { showLoader, hideLoader } = useAppLoader()
  const user: any = useUserSelector();

  const [studentId, setStudentId] = useState<string>("")
  const [regardingId, setRegardingId] = useState<any>(null)
  const [queryType, setQueryType] = useState<any>(null)
  const [casesChannel, setCasesChannel] = useState<any>(null)
  const [files, setFiles] = useState<any>([])
  const [docType, setDocType] = useState<any>(null)

  const toBase64 = (file: any) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  const checkIsSelected = (name: string) => {
    console.log('checking.................', files)
    return new Promise((resolve: any, reject: any) => {
      files.map((file: any) => {
        if (file.FileName == name) {
          reject()
        }
      });
      resolve()
    })
  }

  const getDocTypes = () => {
    commonApi.post({
      "entityName": "Picklist",
      "requestName": "RetrieveSelectOptionExecuteRequest",
      "inputParamters":
      {
        "SelectOption": {
          "OptionText": "DocTypes"

        }
      }
    }, (message: string, resp: any) => {
      resp.outputParameters.data.map((type: any) => {
        if (type.optionText == 'AP Documents') {
          setDocType(type.optionValue);
          console.log(type.optionValue)
        }
      })
    }, (message: string) => {
      //toast.error(message);
    });
  }

  const getStudentCasesClassification = () => {
    commonApi.getStudentCasesClassification({
      ViewName: 'StudentCaseClassifications'
    }, (message: string, resp: any) => {

      let options: any = [];
      resp.map((ins: any) => {
        if (ins.recordId.search('000000') !== 0) {
          ins.value = ins.recordId;
          ins.label = ins.mainText;
          options.push(ins);
        }
      })
      setQueryType(options)
      // console.log({ options })
    }, (message: string) => {
      // console.log(message);
    });
  }

  const getCasesChannel = () => {
    commonApi.post({
      "entityName": "Picklist",
      "requestName": "RetrieveSelectOptionExecuteRequest",
      "inputParamters":
      {
        "SelectOption": {
          "OptionText": "Channel"

        }
      }
    }, (message: string, resp: any) => {
      // console.log({ chn: resp.outputParameters.data })
      if (resp.outputParameters.Count > 0) {
        setCasesChannel(resp.outputParameters.data.map((data: any) => {
          data.label = data.optionText;
          data.value = data.optionValue;
          return data;
        }))
      }
    }, (message: string) => {
      // console.log(message);
    });
  }

  const onSubmit = (data: any) => {
    console.log({ data, user, regardingId })
    // if (studentId !== undefined && studentId !== null && studentId !== "") {
    if (user && regardingId) {
      showLoader()
      commonApi.post({
        "entityName": "Cases",
        "requestName": "UpsertRecordReq",
        "inputParamters":
        {
          "Entity": {
            "CaseClassificationId": data.QueryType.value,
            "Subject": data.Subject,
            "Description": data.Message,
            "regardingId": regardingId.FacilityId,
            "regardingIdObjectTypeCode": "Facility",
            "CustomerId": user?.recordId,
            "CustomerIdObjectTypeCode": "Employee",
            "ChannelId": "681",
            "CasesStatusId": "309"
          },
          "Documents": files
        }
      }, (message: string, resp: any) => {
        toast.success(message)
        handleClose()
        reset({
          Subject: null,
          Message: null,
          documents: null
        })
        hideLoader()
        setFiles([])
        getCasesByStudent()
      }, (message: string) => {
        toast.error(message);
        handleClose()
        hideLoader()
      });
    }
    else {
      console.log("studentId required")
    }
  }

  const removeFile = (fileName: string) => {
    let updatedFiles = files.filter((item: any) => (
      item.FileName != fileName
    ))
    console.log({ updatedFiles })
    setFiles(updatedFiles)
  }

  const getStudentCaseRegardingId = () => {
    commonApi.retrieveStudentCaseRegardingId({
      StudentId: user?.recordId
    }, (message: string, resp: any) => {
      console.log({ caseDetails: resp })
      if (resp.length > 0) {
        setRegardingId(resp[0])
      }
      hideLoader()
    }, (message: string) => {
      hideLoader()
      // console.log(message);
    });
  }

  useEffect(() => {
    let _files: any = [...files];
    if (documents?.length) {
      for (let i = 0; i < documents?.length; i++) {
        let file: any = documents[i];
        checkIsSelected(file.name).then(() => {
          if (file.size <= 2 * 1024 * 1024) {
            toBase64(file).then((b64: any) => {
              _files.push({
                "FileName": file.name,
                "FileExtention": file.name.split('.').pop(),
                "DocumentTypeId": docType,
                "FileContent": b64.split(',')[1]
              });
              // if (_files.length == documents?.length) {
              console.log('iFfiles=>', _files);
              setFiles(_files)
              // }
            })
          } else {
            toast.error("File size should be less 2MB")
          }
        }).catch(() => { console.log('already selected') })
      }
    } else {
      console.log('elsefiles=>', _files);
      setFiles(_files)
    }
    // reset({
    //   documents: null
    // })
  }, [documents])

  useEffect(() => {
    if (user) {
      setStudentId(user.recordId)
      getStudentCaseRegardingId()
    }
  }, [user])

  useEffect(() => {
    getStudentCasesClassification()
    getCasesChannel()
    getDocTypes()
  }, [])

  return (
    <>
      <Modal show={shouldShow} onHide={() => {
        handleClose();
        reset({
          Subject: null,
          Message: null,
          documents: null
        });
        setFiles([]);
      }}>
        <Modal.Body>
          <div className="tenant-modal">
            <div className="form-group">

              <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col sm={6}>
                    <div className="form-group">
                      <label className="form-label">Query Type *</label>
                      <Controller
                        name={"QueryType"}
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { value, onChange, onBlur } }) => {
                          return (<Select
                            className={"form-control p-0" + (errors?.QueryType ? ' is-invalid' : '')}
                            options={queryType ? queryType : []}
                            placeholder={""}
                            onChange={onChange}
                            value={value}
                            defaultValue={value}
                          />)
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleFormControlInput1" >Subject *</label>
                      <input className="form-control" id="exampleFormControlInput1" placeholder="" {...register("Subject", { required: true, maxLength: 80 })} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleFormControlInput1" >Message *</label>
                      <textarea className="form-control" id="exampleFormControlInput1" placeholder="" {...register("Message", { required: true, maxLength: 80 })} ></textarea>
                    </div>
                  </Col>

                  <Col sm={6}>
                    <div className="form-group case-modal-upload">
                      <label>Attachments (optional)</label>
                      <div className="upload-btn-wrapper">
                        <button className="btn">
                          <img src="/assets/img/plus.png" alt="upload" />
                          <br />
                          Add new documents
                        </button>
                        <input type="file" multiple accept="image/*, application/pdf" {...register('documents', { required: false })} />
                      </div>
                      <p>maximum 300MB</p>

                      {files && files.length > 0 ? files.map((item: any, index: number) => (
                        <div className="uploaded-file-name" key={index}>
                          <p>{item?.FileName}</p>
                          <i className="fa-solid fa-trash" onClick={() => removeFile(item?.FileName)}></i>
                        </div>
                      )) : null}
                    </div>
                  </Col>
                </Row>

                <div className='text-center'><button type="submit" className='full-width-yellow-btn'>Submit</button></div>
              </form>

            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default CreateCaseModal