import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { URLS } from './_config';

import PrivateRoutes from './_config/privateRoutes';
import OpenRoutes from './_config/openRoutes';

import Login from './pages/login';
import Registration from './pages/registration';
import EntryType from './pages/entryType';

import Dashboard from './pages/dashboard';
import AddResidence from './pages/addResidence';
import AddRoom from './pages/addRoom';
import ResidenceSummery from './pages/residenceSummery';
import DescribeRooms from './pages/describeRooms';
import MoreAboutProperty from './pages/moreAboutProperty';
import UploadPropertyImages from './pages/uploadPropertyImages';
import UploadPropertyDocuments from './pages/uploadPropertyDocuments';
import PaymentInfo from './pages/paymentInfo';
import Payment from './pages/payment';
import CreateCase from './pages/createCase';
import SuccessPage from './pages/successsPage';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './App.scss';
import CancelPage from './pages/cancelPage';
import NotifyPage from './pages/notifyPage';
import MakePayment from './pages/makePayment';
import ForgotPassword from './pages/forgotPassword';
import ResetPassword from './pages/resetPassword';
import RegistrationSuccessful from './pages/registrationSuccessful';
import UpdateProfileBkp from './pages/updateProfileBkp';
import MainDashboard from './pages/mainDashboard';
import OpenCases from './pages/openCases';
import Leases from './pages/leases';


import StudentWeb from './pages/student';


import ResidenceDetails from './pages/residenceDetails';
import OpenCaseDetails from './pages/openCaseDetails';
import Tenants from './pages/tenants';
import TenantsDetails from './pages/tenantsDetails';
import Institution from './pages/institution';
import InstitutionUpload from './pages/institutionUpload';
import InstitutionView from './pages/institutionView';
import InstitutionUploadList from './pages/institutionUploadList';
import InstitutionUploadStatus from './pages/institutionUploadStatus';
import InstitutionViewList from './pages/institutionViewList';
import StudentRegistration from './pages/student/studentRegistration';
import UpdateProfile from './pages/updateProfile';
import PageNotFound from './pages/pageNotFound';

function App() {
	return (
		<Router>
			<Routes>
				<Route element={<OpenRoutes />} >
					<Route path={URLS.ENTRY_TYPE} element={<EntryType />} />
					<Route path={URLS.LOGIN} element={<Login />} />
					<Route path={URLS.REGISTRATION} element={<Registration />} />
					<Route path={URLS.FORGOT_PASSWORD} element={<ForgotPassword />} />
					<Route path={URLS.REGISTRATION_SUCCESSFUL} element={<RegistrationSuccessful />} />
					<Route path={URLS.STUDENT_REGISTRATION} element={<StudentRegistration />} />
				</Route>
				<Route path={URLS.SUCCESS_PAGE} element={<SuccessPage />} />
				<Route path={URLS.CANCEL_PAGE} element={<CancelPage />} />
				<Route path={URLS.NOTIFY_PAGE} element={<NotifyPage />} />
				<Route path={URLS.MAKE_PAYMENT} element={<MakePayment />} />
				<Route element={<PrivateRoutes />} >
					<Route path={URLS.RESET_PASSWORD} element={<ResetPassword />} />
					<Route path={URLS.DASHBOARD} element={<Dashboard />} />
					<Route path={URLS.ADD_RESIDENCE} element={<AddResidence />} />
					<Route path={URLS.ADD_ROOM} element={<AddRoom />} />
					<Route path={URLS.RESIDENCE_SUMMERY} element={<ResidenceSummery />} />
					<Route path={URLS.DESCRIBE_ROOMS} element={<DescribeRooms />} />
					<Route path={URLS.MORE_ABOUT_PROPERTY} element={<MoreAboutProperty />} />
					<Route path={URLS.UPLOAD_PROPERTY_IMAGES} element={<UploadPropertyImages />} />
					<Route path={URLS.UPLOAD_PROPERTY_DOCUMENTS} element={<UploadPropertyDocuments />} />
					<Route path={URLS.PAYMENT_INFO} element={<PaymentInfo />} />
					<Route path={URLS.PAYMENT} element={<Payment />} />
					<Route path={URLS.CREATE_CASE} element={<CreateCase />} />
					{/* <Route path={URLS.UPDATE_PROFILE} element={<UpdateProfileBkp />} /> */}
					<Route path={URLS.UPDATE_PROFILE} element={<UpdateProfile />} />
					<Route path={URLS.HOME} element={<MainDashboard />} />
					<Route path={URLS.OPEN_CASES} element={<OpenCases />} />
					<Route path={URLS.OPEN_CASE_DETAILS} element={<OpenCaseDetails />} />
					<Route path={URLS.LEASES} element={<Leases />} />
					<Route path={URLS.STUDENT.ROOT} element={<StudentWeb />} />
					<Route path={URLS.RESIDENCE_DETAILS} element={<ResidenceDetails />} />
					<Route path={URLS.TENANTS} element={<Tenants />} />
					<Route path={URLS.TENANTS_DETAILS} element={<TenantsDetails />} />
					<Route path={URLS.INSTITUTION} element={<Institution />} />
					<Route path={URLS.INSTITUTION_UPLOAD} element={<InstitutionUpload />} />
					<Route path={URLS.INSTITUTION_VIEW} element={<InstitutionView />} />
					<Route path={URLS.INSTITUTION_VIEW_LIST} element={<InstitutionViewList />} />
					<Route path={URLS.INSTITUTION_UPLOAD_STATUS} element={<InstitutionUploadStatus />} />
					<Route path={URLS.INSTITUTION_UPLOAD_LIST} element={<InstitutionUploadList />} />
					{/* <Route path={URLS.NOT_FOUND} element={<PageNotFound />} /> */}
				</Route>
			</Routes>
		</Router>
	);
}

export default App;
