import { useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import "../../../styles/student/components/accomodationSearch.scss"


interface incomingProps {
    paramName?: any;
    handleSearch: any
}


export default function FindAccomodation({ paramName, handleSearch }: incomingProps) {
    const search: any = useRef();
    useEffect(() => {
        search.current.addEventListener('search', () => {
            handleSearch(search.current.value);
        })
    }, [])
    return (
        <div className="accomodation-search">
            <p>Find accomodation nearby</p>
            <div>
                <input type="search" ref={search} onKeyUp={() => { handleSearch(search.current.value) }} className="search-label" />
                <Button onClick={() => {
                    handleSearch(search.current.value)
                }} variant="dark" className="search-btn">Search</Button>
            </div>
        </div>
    )

}
