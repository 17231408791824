import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useUserApi } from "../../../_common/hooks/api/UserApiHook";
import { useUserSelector } from "../../../_common/hooks/selectors/userSelector";
import { URLS } from "../../../_config";


interface incomingProps {
    paramName?: any;
}

export default function Header({ paramName = "HOME" }: incomingProps) {
    const user: any = useUserSelector()
    const navigate = useNavigate()
    const userApi = useUserApi()

    const [userInitials, setUserInitials] = useState<any>()
    const logout = (e: any) => {

        e.preventDefault();
        e.stopPropagation();
        userApi.logout({}, (message: string, resp: any) => {

        }, (message: string) => {

        })
    }

    useEffect(() => {
        // let userDetails: any = sessionStorage.getItem('nsfas')
        // userDetails = JSON.parse(userDetails)
        // console.log(userDetails)
        let initials = user?.name
        if (initials.split(' ').length > 1) {
            let firstInitials = initials.split(' ')[0]
            let secondInitials = initials.split(' ')[1]
            setUserInitials(firstInitials[0] + secondInitials[0])
        }
        else {
            setUserInitials(initials[0])
        }
    }, []);

    return (
        <div className="header">
            <Link to="/student" className="logo">
                <img src="../../assets/img/logo-white.png" alt="NSFAS" />
            </Link>

            <h2 className="title" onClick={() => navigate('/student')} >{paramName}</h2>

            <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic" className="btn btn-outline-success">
                    {userInitials && userInitials}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => navigate('/student/my-profile')}>Edit Profile</Dropdown.Item>
                    <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )

}
