import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Container, Row, Col, Button } from 'react-bootstrap';
import Select, { components } from 'react-select';
import { useForm, Controller } from "react-hook-form";
import { checkImageBase64Code, CheckNumber } from "../_common/functions";
import Header from '../components/header';
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import { useResidenceApi } from "../_common/hooks/api/residenceApiHook";
import { FaTimes } from 'react-icons/fa';
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import toast from "react-hot-toast";
import { URLS } from "../_config";
import LeftPanel from "../components/leftPanel";

export default function UploadPropertyImages() {
    const navigate = useNavigate();
    const { showLoader, hideLoader } = useAppLoader();
    const commonApi = useCommonApi();
    const residenceApi = useResidenceApi();

    const residenceID = sessionStorage.getItem('residenceID');
    const [docType, setDocType] = useState<any>(null);
    const [files, setFiles] = useState<any>([]);
    const [prevFiles, setPrevFiles] = useState<any>([]);

    const { register, handleSubmit, control, formState: { errors, isValid }, getValues, watch, reset } = useForm({ mode: "onChange" });


    const images = watch('images');

    const toBase64 = (file: any) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const checkIsSelected = (name: string) => {
        console.log('checking.................', files)
        return new Promise((resolve: any, reject: any) => {
            files.map((file: any) => {
                if (file.FileName == name) {
                    reject()
                }
            });
            resolve()
        })
    }


    useEffect(() => {
        // console.log("state files",files)
        let _files: any = [...files];
        if (images?.length) {
            for (let i = 0; i < images?.length; i++) {
                let file: any = images[i];
                checkIsSelected(file.name).then(() => {
                    // console.log(file)
                    if (file.size <= 2 * 1024 * 1024) {
                        let ext: string = file.name.split('.').pop();
                        if (ext == 'jpg' || ext == 'png' || ext == 'gif') {
                            toBase64(file).then((b64: any) => {
                                _files.push({
                                    "FileName": file.name,
                                    "FileExtention": ext,
                                    "DocumentTypeId": docType,
                                    "FileContent": b64.split(',')[1]
                                });
                                // if (_files.length == images?.length) {
                                console.log('files=>', _files);
                                setFiles(_files)
                                // }
                            })
                        } else {
                            toast.error('supported files are .jpg, .png and .gif')
                        }

                    } else {
                        toast.error("File size should be less 2MB")
                    }
                }).catch(() => {
                    console.log(file.name + ' already selected')
                })


            }
        } else {
            setFiles(_files)
        }
        reset({
            images: null
        })
    }, [images])

    const getDocTypes = () => {
        commonApi.post({
            "entityName": "Picklist",
            "requestName": "RetrieveSelectOptionExecuteRequest",
            "inputParamters":
            {
                "SelectOption": {
                    "OptionText": "DocTypes"

                }
            }
        }, (message: string, resp: any) => {
            resp.outputParameters.data.map((type: any) => {
                if (type.optionText == 'Banner Image') {
                    setDocType(type.optionValue);
                    // console.log(type.optionValue)
                }
            })
        }, (message: string) => {
            //oast.error(message);
        });
    }

    const onSubmit = (data: any) => {

        let params: any = {
            "entityName": "Facility",
            "requestName": "CreateDocumentExecuteRequest",
            "recordId": residenceID,
            "inputParamters": { "Documents": files }
        }

        showLoader();
        commonApi.post(params, (message: string, resp: any) => {
            hideLoader();
            navigate('/upload-property-documents')
        }, (message: string) => {
            hideLoader();
            toast.error(message);
        });

    }

    const getPrevData = () => {
        if (residenceID) {
            showLoader();
            //call api reset form values via reset()
            commonApi.retreive({
                "Entity": "FacilityDocLib",
                "Top": 100,
                "ReturnCols": ["FacilityDocLibId", "Name", "DocumentTypeId", "FileExtention", "FileContent"],
                "FilterOn": {
                    "Type": "And",
                    "Where": [{
                        "filterCol": "FacilityId", "FilterCondition": "Equals",
                        "FilterValue": residenceID
                    }, {
                        "filterCol": "DocumentTypeId", "FilterCondition": "Equals",
                        "FilterValue": "893"
                    }]
                }
            }, (message: string, resp: any) => {
                hideLoader();
                let prevData = JSON.parse(resp);
                console.log(prevData);
                setPrevFiles(prevData)
            }, (message: string) => {
                hideLoader();
                toast.error(message);
            });
        } else {
            console.log("Start A New Residence")
        }
    }

    useEffect(() => {
        if (residenceID == null) {
            navigate('/add-residence')
        } else {
            getDocTypes();
        }
    }, [])

    useEffect(() => {
        getPrevData()
    }, [])

    const deleteLocal = (file: any) => {
        let _files: any = [];
        files.map((_file: any) => {
            if (_file.FileName != file.FileName) {
                _files.push(_file)
            }
        });

        setFiles(_files)
    }
    const deleteFromServer = (file: any) => {
        showLoader();
        commonApi.post({
            "entityName": "FacilityDocLib",
            "recordId": file.FacilityDocLibId,
            "requestName": "RemoveRecordReq"
        }
            , (message: string, resp: any) => {
                hideLoader();
                getPrevData();

            }, (message: string) => {
                hideLoader();
                toast.error(message);
            });
    }

    return (
        <React.Fragment>
            <div className="dashboard">
                <Header />
                <section className="container">
                    <div className="custome-container-inner d-flex">
                        <LeftPanel page={4} />
                        <div className="white-block p-3 white-box-wide">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col">
                                        <h1>My Residences</h1>
                                    </div>
                                </div>
                                <hr />
                                <div className="tab_scrollcontent">
                                    <div className="row justify-content-center">
                                        <div className="col-sm-7">
                                            <div className="upload-box">
                                                <div className="residence-attachment-panel">
                                                    <h4>Attachments</h4>
                                                    <div className="residence-attachment-inner">
                                                        {prevFiles && prevFiles.length > 0 ?
                                                            prevFiles.map((file: any, i: any) => (
                                                                <div className="residence-attachment" key={i}>
                                                                    <FaTimes className="delete-me" onClick={() => { deleteFromServer(file) }} />
                                                                    <img data-src={checkImageBase64Code(file.FileContent)} src={checkImageBase64Code(file.FileContent)} alt="" />
                                                                </div>
                                                            ))
                                                            : null}
                                                        {files.map((file: any, i: any) => (
                                                            <div className="residence-attachment" key={i}>
                                                                <FaTimes className="delete-me" onClick={() => { deleteLocal(file) }} />
                                                                <img data-src={checkImageBase64Code(file.FileContent)} src={checkImageBase64Code(file.FileContent)} alt="" />
                                                            </div>
                                                        ))}


                                                    </div>
                                                    {/* <div className="upload-residence-attachment">
                                            <input type="file" accept="image/*" multiple {...register('images', { required: true })} />
                                            <div className="upload-residence-attachment-inner">
                                                <img src="/assets/img/upload-icon.png" />
                                                <span>Upload your attachment</span>
                                            </div>
                                        </div> */}
                                                    <div className="upload-btn-wrapper">
                                                        <button className="btn">
                                                            <img src="/assets/img/plus.png" alt="upload" />
                                                            <br />
                                                            Upload your attachment
                                                        </button>
                                                        <input type="file" multiple accept="image/*" {...register('images', { required: false })} />
                                                    </div>
                                                </div>
                                                <div className="upload-instruction mt-3">
                                                    <h5>Please upload pictures of facility including:</h5>
                                                    <ul>
                                                        <li>Outdoor</li>
                                                        <li>Room / Bathroom</li>
                                                        <li>Kitchen / Kitchenette</li>
                                                        <li>Bathroom</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mobbottom_fixbtn next-back-btn">
                                    {prevFiles && prevFiles.length > 0 && files && files.length == 0 ?
                                        <Link to={URLS.UPLOAD_PROPERTY_DOCUMENTS} className="next-btn">
                                            <button className="btn btn-primary w-100 mt-2" data-bs-toggle="button" >Next</button>
                                        </Link>
                                        :
                                        <button type="submit" className="btn btn-primary w-100 mt-2 next-btn" disabled={files?.length == 0 && prevFiles?.length == 0} data-bs-toggle="button" >Next</button>
                                    }
                                    <Link to={URLS.MORE_ABOUT_PROPERTY} className="back-btn">
                                        <button type="button" className="btn btn-outline-secondary mt-2 w-100" data-bs-toggle="button" >Back</button>
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </React.Fragment>
    );
}