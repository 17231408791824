import React, { useState } from 'react'
import Slider from 'react-slick';

interface incomingprops {
    residence: any;
}

const ImageSlider = ({ residence }: incomingprops) => {
    
    const settings = {
        dots: false,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const thumbSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const [nav1, setNav1] = useState<any>(null);
    const [nav2, setNav2] = useState<any>(null);

    const checkImageBase64Code = (url: any) => {
        if (url.search('data:image/') == 0) {
            return url;
        } else {

            return 'data:image/jpeg;base64,' + url
        }

    }

    return (
        <React.Fragment>
            <Slider {...settings} asNavFor={nav2} ref={(slider1: any) => setNav1(slider1)} >
                {residence?.images?.length > 0 ? residence?.images.map((image: any, i: any) => (
                    <div className="slide-item" key={i}>
                        <img src={checkImageBase64Code(image?.fileContent)} alt="" />
                    </div>
                )) : <div className="slide-item">
                    <img src={"/assets/img/no-image-available.jpg"} alt="" />
                </div>}
            </Slider>

            <Slider {...thumbSettings} className="thumb-slider" asNavFor={nav1} ref={(slider2: any) => setNav2(slider2)} focusOnSelect={true}>
                {residence?.images?.length > 0 ? residence?.images.map((image: any, i: any) => (
                    <div className="slide-item" key={i}>
                        <img src={checkImageBase64Code(image?.fileContent)} alt="" />
                    </div>
                )) : <div className="slide-item">
                    <img src={"/assets/img/no-image-available.jpg"} alt="" />
                </div>}
            </Slider>
        </React.Fragment>
    )
}

export default ImageSlider