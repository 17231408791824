import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Carousel } from 'react-bootstrap';
import Slider from "react-slick";
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
interface incomingProps {

}

export default function IntroSlider({ }: incomingProps) {

	const [currentSlideNo, setCurrentSlideNo] = useState<number>(0)

	const onSlide = (e:any) => {
		setCurrentSlideNo(e)
	}



	return (
		<React.Fragment>
			<div className={"register_right bg" + currentSlideNo}>
				<div className="msg-slider">
					<div className="area">
						<Carousel onSlide={onSlide}>
							<Carousel.Item>
								<div className="intro-slide">
									<p>Register your property with NSFAS and we will help you find the right students for you student residence facility. Just a few easy steps.</p>
									<ol type="1">
										<li>Register as an Accommodation Provider for a fee.</li>
										<li>Get your student residence property(s) accredited by NSFAS for a fee.</li>
										<li>Once accredited, your property is visible to students on our student accommodation site.</li>
										<li>Lease out rooms to NSFAS students and get hassle guaranteed rental payment.</li>
									</ol>
								</div>
							</Carousel.Item>
							<Carousel.Item>
								<div className="intro-slide black">
									<p>The National Student Financial Aid Scheme and the Department of Higher Education and Training take very stringent measures to ensure our
										students have accommodation that is conducive to learning. A standardised approach is applied to the inspection and grading of all properties
										for accreditation. All accommodation is accredited against a grading system based on the “Policy on the Minimum Norms and Standards for
										Student Housing at Public Universities” published by the Department of Higher Education and Training in gazette No 39238 on 29 September
										2015
									</p>
								</div>
							</Carousel.Item>
							<Carousel.Item>
								<div className="intro-slide black">
									<ul style={{ listStyle: "none" }}>
										<li>Are you a student?</li>
										<li>Want to know if you qualify for NSFAS funding?</li>
										<li>Want to know how can you apply for funding?</li>
										<li>Visit <a href="www.nsfas.org.za" target="_blank">www.nsfas.org.za</a></li>
									</ul>
								</div>
							</Carousel.Item>
						</Carousel>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}
