import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Container, Row, Col, Button } from 'react-bootstrap';
import toast from "react-hot-toast";
interface incomingProps {
  onSubmitIndividualProviderForm: any;
}

export default function IndividualProviderForm({ onSubmitIndividualProviderForm }: incomingProps) {

  const { register, handleSubmit, formState: { errors } } = useForm();


  const onSubmit = (data: any) => {
    // console.log({data},data.password === data.confPassword)
    if (data.Password === data.confPassword) {
      let params: any = {
        ...data, ...{
          "APTypeId": "864",
          "IDTypeId": "862",
        }
      }

      console.log('data=>', params);

      onSubmitIndividualProviderForm(params)
    } else {
			toast.error('password and confirm password didnot match.')
		}

  }

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="tab_scrollcontent">
          <div className="mb-2">
            <label htmlFor="exampleFormControlInput1" className={"form-label " + errors.FirstName ? 'is-invalid' : ''}>First name</label>
            <input type="text" className="form-control" id="exampleFormControlInput1" {...register("FirstName", { required: true, maxLength: 80 })} />
            {errors?.FirstName ? <div className="invalid-feedback">Required</div> : null}
          </div>
          <div className="mb-2">
            <label htmlFor="exampleFormControlInput2" className={"form-label " + errors.LastName ? 'is-invalid' : ''}>Last name</label>
            <input type="text" className="form-control" id="exampleFormControlInput2" {...register("LastName", { required: true, maxLength: 80 })} />
            {errors?.LastName ? <div className="invalid-feedback">Required</div> : null}
          </div>
          <div className="mb-2">
            <label htmlFor="exampleFormControlInput3" className={"form-label " + errors.IDNumber ? 'is-invalid' : ''}>ID number</label>
            <input type="text" className="form-control" id="exampleFormControlInput3" {...register("IDNumber", { required: true, maxLength: 80 })} />
            {errors?.IDNumber ? <div className="invalid-feedback">Required</div> : null}
          </div>

          <div className="mb-2">
            <label htmlFor="exampleFormControlInput5" className={"form-label " + errors.Mobile ? 'is-invalid' : ''}>Mobile</label>
            <input type="text" className="form-control" id="exampleFormControlInput5" {...register("Mobile", { required: true, maxLength: 80 })} />
            {errors?.Mobile ? <div className="invalid-feedback">Required</div> : null}
          </div>
          <div className="mb-2">
            <label htmlFor="exampleFormControlInput6" className={"form-label " + errors.Telephone ? 'is-invalid' : ''}>Telephone</label>
            <input type="text" className="form-control" id="exampleFormControlInput6" {...register("Telephone", { required: false, maxLength: 80 })} />
            {/* {errors?.Telephone ? <div className="invalid-feedback">Required</div> : null} */}
          </div>
          <div className="mb-2">
            <label htmlFor="exampleFormControlInput7" className={"form-label " + errors.Email ? 'is-invalid' : ''}>Email</label>
            <input type="text" className="form-control" id="exampleFormControlInput7" {...register("Email", { required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} />
            {errors?.Email ? <div className="invalid-feedback">
              {errors.Email.type === "required" ? 'Required' : null}
              {errors.Email.type === "pattern" ? 'Invalid email address' : null}
            </div> : null}
          </div>
          <div className="mb-2">
            <label htmlFor="exampleFormControlInput7" className={"form-label " + errors.Password ? 'is-invalid' : ''}>Password</label>
            <input type="password" className="form-control" id="exampleFormControlInput7" {...register("Password", { required: true, minLength: 6, maxLength: 80 })} />
            {errors?.Password ? <div className="invalid-feedback">
              {errors.Password.type === "required" ? 'Required' : null}
              {errors.Password.type === "minLength" ? 'Password must be at least 6 characters' : null}
            </div> : null}
          </div>
          <div className="mb-2">
            <label htmlFor="exampleFormControlInput1" className={"form-label" + (errors?.confPassword ? ' is-invalid' : '')}>Confirm Password</label>
            <input type="password" className="form-control" id="exampleFormControlInput1" placeholder="Confirm Password" {...register("confPassword", { required: true, minLength: 6, maxLength: 80 })} />
            {errors?.confPassword?.type == 'required' ? <div className="invalid-feedback">Required</div> : null}
            {errors?.confPassword?.type == 'minLength' ? <div className="invalid-feedback">Password must be at least 6 characters</div> : null}
          </div>
        </div>
        <Row className="align-items-center">
          <Col md={6}>
            <div className="mobbottom_fixbtn">
              <button className="btn btn-primary w-50 mt-2" type="submit">Next</button>
            </div>
          </Col>
          {/* <Col md={6} className="text-end">
                      <a href="/">Sign in</a>
                    </Col> */}
        </Row>

      </form>

    </React.Fragment>
  );
}
