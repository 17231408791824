import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Container, Row, Col, Button } from 'react-bootstrap';
import Select, { components } from 'react-select';
import { useForm, Controller } from "react-hook-form";
import { CheckNumber } from "../_common/functions";
import Header from '../components/header';
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import { useResidenceApi } from "../_common/hooks/api/residenceApiHook";
import { FaTimes } from 'react-icons/fa';
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import toast from "react-hot-toast";
import { URLS } from "../_config";
import LeftPanel from "../components/leftPanel";

export default function UploadPropertyDocuments() {
    const navigate = useNavigate();
    const { showLoader, hideLoader } = useAppLoader();
    const commonApi = useCommonApi();
    const residenceApi = useResidenceApi();

    const residenceID = sessionStorage.getItem('residenceID');
    const [docType, setDocType] = useState<any>(null);
    const [files, setFiles] = useState<any>([]);
    const [prevFiles, setPrevFiles] = useState<any>([]);

    const { register, handleSubmit, control, formState: { errors, isValid }, getValues, watch, reset } = useForm({ mode: "onChange" });


    const documents = watch('documents');

    const toBase64 = (file: any) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });


    useEffect(() => {
        let _files: any = [...files];
        if (documents?.length) {
            for (let i = 0; i < documents?.length; i++) {
                let file: any = documents[i];
                checkIsSelected(file.name).then(() => {
                    if (file.size <= 2 * 1024 * 1024) {
                        toBase64(file).then((b64: any) => {
                            _files.push({
                                "FileName": file.name,
                                "FileExtention": file.name.split('.').pop(),
                                "DocumentTypeId": docType,
                                "FileContent": b64.split(',')[1]
                            });
                            // if (_files.length == documents?.length) {
                            console.log('files=>', _files);
                            setFiles(_files)
                            // }
                        })
                    } else {
                        toast.error("File size should be less 2MB")
                    }
                }).catch(() => { console.log('already selected') })
            }
        } else {
            console.log('files=>', _files);
            setFiles(_files)
        }
        reset({
            documents: null
        })
    }, [documents])

    const getDocTypes = () => {
        commonApi.post({
            "entityName": "Picklist",
            "requestName": "RetrieveSelectOptionExecuteRequest",
            "inputParamters":
            {
                "SelectOption": {
                    "OptionText": "DocTypes"

                }
            }
        }, (message: string, resp: any) => {
            resp.outputParameters.data.map((type: any) => {
                if (type.optionText == 'AP Documents') {
                    setDocType(type.optionValue);
                    console.log(type.optionValue)
                }
            })
        }, (message: string) => {
            //oast.error(message);
        });
    }

    const onSubmit = (data: any) => {

        let params: any = {
            "entityName": "Facility",
            "requestName": "CreateDocumentExecuteRequest",
            "recordId": residenceID,
            "inputParamters": { "Documents": files }
        }

        showLoader();
        commonApi.post(params, (message: string, resp: any) => {
            hideLoader();
            navigate('/payment-info')
        }, (message: string) => {
            hideLoader();
            toast.error(message);
        });

    }

    const checkIsSelected = (name: string) => {
        console.log('checking.................', files)
        return new Promise((resolve: any, reject: any) => {
            files.map((file: any) => {
                if (file.FileName == name) {
                    reject()
                }
            });
            resolve()
        })
    }

    const getPrevData = () => {
        if (residenceID) {
            showLoader();
            //call api reset form values via reset()
            commonApi.retreive({
                "Entity": "FacilityDocLib",
                "Top": 100,
                "ReturnCols": ["FacilityDocLibId", "Name", "DocumentTypeId", "FileExtention", "FileContent"],
                "FilterOn": {
                    "Type": "And",
                    "Where": [{
                        "filterCol": "FacilityId", "FilterCondition": "Equals",
                        "FilterValue": residenceID
                    }, {
                        "filterCol": "DocumentTypeId", "FilterCondition": "Equals",
                        "FilterValue": "892"
                    }]
                }
            }, (message: string, resp: any) => {
                hideLoader();
                let prevData = JSON.parse(resp);
                console.log(prevData);
                setPrevFiles(prevData)
            }, (message: string) => {
                hideLoader();
                toast.error(message);
            });
        } else {
            console.log("Start A New Residence")
        }
    }

    useEffect(() => {
        if (residenceID == null) {
            navigate('/add-residence')
        } else {
            getDocTypes();
        }
    }, [])

    useEffect(() => {
        getPrevData()
    }, [])

    const deleteLocal = (file: any) => {
        let _files: any = [];
        files.map((_file: any) => {
            if (_file.FileName != file.FileName) {
                _files.push(_file)
            }
        });

        setFiles(_files)
    }

    const deleteFromServer = (file: any) => {
        showLoader();
        commonApi.post({
            "entityName": "FacilityDocLib",
            "recordId": file.FacilityDocLibId,
            "requestName": "RemoveRecordReq"
        }
            , (message: string, resp: any) => {
                hideLoader();
                getPrevData();

            }, (message: string) => {
                hideLoader();
                toast.error(message);
            });
    }

    return (
        <React.Fragment>
            <div className="dashboard">
                <Header />
                <section className="container">
                    <div className="custome-container-inner d-flex">
                        <LeftPanel page={4} />
                        <div className="white-block p-3 white-box-wide">
                            <form onSubmit={handleSubmit(onSubmit)}>

                                <div className="row">
                                    <div className="col">
                                        <h1>My Residences</h1>
                                    </div>
                                    {/* <div className="col text-end">
                                    <img src="/assets/img/Add-button.png" alt="Add butten" />
                                </div> */}
                                </div>
                                <hr />
                                <div className="row justify-content-center">
                                    <div className="col-sm-7">
                                        <div className="upload-box mb-2">
                                            <div className="upload-btn-wrapper">
                                                <button className="btn">
                                                    <img src="/assets/img/plus.png" alt="upload" />
                                                    <br />
                                                    Upload your documents
                                                </button>
                                                <input type="file" multiple accept="application/pdf" {...register('documents', { required: false })} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-sm-7">
                                        <div className="documents-fld-panel">
                                            {prevFiles && prevFiles.length > 0 ?
                                                prevFiles.map((file: any, i: any) => (
                                                    <div className="doc-file mb-2" key={i}>
                                                        <FaTimes className="delete-me" onClick={() => { deleteFromServer(file) }} />
                                                        <input type="text" className="form-control" disabled id="exampleFormControlInput3"
                                                            placeholder={file.Name} />
                                                    </div>
                                                ))
                                                : null}
                                            {files.map((file: any, i: any) => (
                                                <div className="doc-file mb-2" key={i}>
                                                    <FaTimes className="delete-me" onClick={() => { deleteLocal(file) }} />
                                                    <input type="text" className="form-control" disabled id="exampleFormControlInput3"
                                                        placeholder={file.FileName} />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-sm-7">
                                        <div className="upload-instruction mt-3">
                                            <h5>Please upload the following:</h5>
                                            <ul>
                                                <li>Floor plan</li>
                                                <li>Municipal Approvals</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="mobbottom_fixbtn next-back-btn">
                                    {prevFiles && prevFiles.length > 0 && files && files.length == 0 ?
                                        <Link to={URLS.PAYMENT_INFO} className="next-btn">
                                            <button className="btn btn-primary w-100 mt-2" data-bs-toggle="button" >Next</button>
                                        </Link>
                                        :
                                        <button type="submit" className="btn btn-primary w-100 mt-2 next-btn" disabled={files?.length == 0 && prevFiles?.length == 0} data-bs-toggle="button" >Next</button>
                                    }
                                    <Link to={URLS.UPLOAD_PROPERTY_IMAGES} className="back-btn">
                                        <button type="button" className="btn btn-outline-secondary mt-2 w-100" data-bs-toggle="button" >Back</button>
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div >
        </React.Fragment >
    );
}