import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from 'react-bootstrap';
import Header from '../components/header';
import Breadcrumbs from './student/components/breadcrumbs';
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import { useResidenceApi } from "../_common/hooks/api/residenceApiHook";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import toast from "react-hot-toast";
import { MdAddCircle } from 'react-icons/md';
import { BsStarFill, BsStarHalf, BsStar } from 'react-icons/bs';
import { useUserSelector } from "../_common/hooks/selectors/userSelector";
import parse from 'html-react-parser';
import ImageSlider from "../components/imageSlider";
// import WithdrawModal from "../components/withdrawModal";

export default function Dashboard() {
    const user: any = useUserSelector()
    const navigate = useNavigate();
    const { showLoader, hideLoader } = useAppLoader();
    const commonApi = useCommonApi();
    const residenceApi = useResidenceApi();

    const [residencies, setResidencies] = useState<any>(null)
    const [provinces, setProvinces] = useState<any>([])
    const [residenceStatus, setResidenceStatus] = useState<any>([])
    // const [show, setShow] = useState<boolean>(false)
    const inspectionStatus: any = [
        {
            "optionValue": 894,
            "optionText": "Complete",
            "colorCode": null
        },
        {
            "optionValue": 845,
            "optionText": "Pending",
            "colorCode": null
        },
        {
            "optionValue": 846,
            "optionText": "Scheduled",
            "colorCode": null
        },
        {
            "optionValue": 847,
            "optionText": "Quality Assurance",
            "colorCode": null
        }
    ]

    const getInspectionStatusNameById = (id: any) => {
        let statusName: string = '';
        inspectionStatus.map((status: any) => {
            if (status.optionValue == id) {
                statusName = status.optionText;
            }
        });
        return statusName;
    }

    const getResidencies = () => {
        showLoader();
        commonApi.post({
            "requestName": "FetchRecordWithDocsReq",
            "recordId": user.supplierId

        }, (message: string, resp: any) => {
            hideLoader();
            setResidencies(resp.outputParameters.Facilities);
            console.log('Residencies =>', resp.outputParameters.Facilities)
        }, (message: string) => {
            toast.error(message);
            hideLoader();
        });
    }

    const getProvinces = () => {
        commonApi.post({
            "entityName": "Picklist",
            "requestName": "RetrieveSelectOptionExecuteRequest",
            "inputParamters":
            {
                "SelectOption": {
                    "OptionText": "Province"

                }
            }
        }, (message: string, resp: any) => {
            if (resp.outputParameters.Count > 0) {
                setProvinces(resp.outputParameters.data)
            }
        }, (message: string) => {
            console.log(message);
        });
    }

    const getResidenceStatus = () => {
        commonApi.post({
            "entityName": "Picklist",
            "requestName": "RetrieveSelectOptionExecuteRequest",
            "inputParamters":
            {
                "SelectOption": {
                    "OptionText": "FacilityStatus"

                }
            }
        }, (message: string, resp: any) => {
            if (resp.outputParameters.Count > 0) {
                setResidenceStatus(resp.outputParameters.data)
            }
        }, (message: string) => {
            console.log(message);
        });
    }

    const getFilteredProvince = (provinceId: any) => {
        const filteredData = provinces.filter((province: any) => province.optionValue == provinceId);
        // console.log(filteredData)
        return filteredData[0]?.optionText
    }

    const getFilteredResidenceStatus = (facilityStatusId: any) => {
        const filteredData = residenceStatus.filter((item: any) => item.optionValue == facilityStatusId);
        // console.log(filteredData[0])
        return <div className="tag-status"><span className="badge" style={{ backgroundColor: filteredData[0]?.colorCode ? filteredData[0]?.colorCode : '#F3981E' }}><>{filteredData[0]?.optionText ? filteredData[0]?.optionText : "NA"}</></span></div>
    }


    useEffect(() => {
        if (user) {
            getResidencies();
            getProvinces();
            getResidenceStatus();
        }

    }, [user])

    const checkImageBase64Code = (url: any) => {
        if (url.search('data:image/') == 0) {
            return url;
        } else {

            return 'data:image/jpeg;base64,' + url
        }

    }

    const withdrawal = (residence: any) => {
        return new Promise((resolve: any, reject: any) => {
            commonApi.post({
                "entityName": "Facility",
                "recordId": residence.facilityId,
                "requestName": "UpsertRecordReq",
                "inputParamters":
                {
                    "Entity": {
                        "FacilityStatusId": "872"
                    }
                }
            }, (message: string, resp: any) => {
                resolve(message)
            }, (message: string) => {
                reject(message);
            });
        })

    }

    const deleteResidence = (residence: any) => {
        return new Promise((resolve: any, reject: any) => {
            commonApi.post({
                "entityName": "Facility",
                "recordId": residence.facilityId,
                "requestName": "RemoveRecordReq"
            }, (message: string, resp: any) => {
                resolve(message)
            }, (message: string) => {
                reject(message);
            });
        })

    }

    const withdrawalNotification = (residence: any) => {
        toast(
            (t) => (
                <span>
                    <b>Please note!</b>
                    <br />
                    should you withdraw your property, your payment will not be refunded.
                    <br />
                    <hr />
                    <div className="d-flex align-items-center justify-content-end">
                        <button
                            className="btn btn-danger btn-sm m-1"
                            onClick={() => {
                                toast.dismiss(t.id);
                                toast.promise(
                                    withdrawal(residence),
                                    {
                                        loading: "working on request...",
                                        success: (msg: any) => { getResidencies(); return <b>{residence.name} withdrawal Successfully!</b> },
                                        error: (msg) => { return <b>{msg}</b> },
                                    },
                                    { duration: 2000 }
                                );
                            }}
                        >
                            Withdraw
                        </button>

                        <button
                            className="btn btn-secondary btn-sm m-1"
                            onClick={() => toast.dismiss(t.id)}
                        >
                            Cancel
                        </button>
                    </div>
                </span>
            ),
            { duration: 15000, className: 'withdraw-toast' }
        );
    }

    const deleteNotification = (residence: any) => {
        toast(
            (t) => (
                <span>

                    Are you sure you want to remove your property.
                    <br />
                    <hr />
                    <div className="d-flex align-items-center justify-content-end">
                        <button
                            className="btn btn-danger btn-sm m-1"
                            onClick={() => {
                                toast.dismiss(t.id);
                                toast.promise(deleteResidence(residence), {
                                    loading: "working on request...",
                                    success: (msg: any) => {
                                        getResidencies();
                                        return <b>{residence.name} Property removed successfully</b>
                                    },
                                    error: (msg) => { return <b>{msg}</b> },
                                }, { duration: 2000 });
                            }}
                        >
                            Yes
                        </button>

                        <button
                            className="btn btn-secondary btn-sm m-1"
                            onClick={() => toast.dismiss(t.id)}
                        >
                            No
                        </button>
                    </div>
                </span>
            ),
            { duration: 15000, className: 'withdraw-toast' }
        );
    }

    const editMe = (residence: any) => {
        if (residence?.facilityStatusId == 872 || residence?.facilityStatusId == 834) {
            sessionStorage.setItem('residenceID', residence.facilityId);
            sessionStorage.setItem('residenceName', residence?.Name);

            sessionStorage.setItem('regrade', 'true');
            navigate("/add-residence");

        }

    }

    const getRating = (rating: any, totalStar: any, outOf: any) => {
        if (rating) {
            let finalRating = (rating / outOf) * totalStar;
            const checkFraction = finalRating % 1 != 0;
            if (checkFraction == false) {
                const blankStar = totalStar - finalRating;
                let span: any = []
                for (let i = 0; i < finalRating; i++) {
                    span.push(<BsStarFill className="text-primary" key={Math.random()} />)
                }
                for (let i = 0; i < blankStar; i++) {
                    span.push(<BsStar key={Math.random()} />)
                }
                return span
            } else {
                let fullStar = Math.round(finalRating * 10) / 10;
                let halfStar = Number(fullStar.toString().split(".")[1]);
                fullStar = Number(finalRating.toString().split(".")[0]);
                let span: any = []
                if (halfStar >= 5) {
                    let blankStar = totalStar - fullStar - 1;
                    for (let i = 0; i < fullStar; i++) {
                        span.push(<BsStarFill className="text-primary" key={Math.random()} />)
                    }
                    span.push(<BsStarHalf className="text-primary" />)
                    for (let i = 0; i < blankStar; i++) {
                        span.push(<BsStar key={Math.random()} />)
                    }
                    // console.log(fullStar,halfStar, blankStar)
                    return span
                }
                else {
                    let blankStar = totalStar - fullStar + 1;
                    for (let i = 0; i < fullStar; i++) {
                        span.push(<BsStarFill className="text-primary" key={Math.random()} />)
                    }
                    for (let i = 0; i < blankStar; i++) {
                        span.push(<BsStar key={Math.random()} />)
                    }
                    // console.log(fullStar, blankStar)
                    return span
                }
            }
        }
    }

    const addResidence = () => {
        sessionStorage.removeItem('residenceID');
        sessionStorage.removeItem('residenceName');
        sessionStorage.removeItem('regrade');
        navigate("/add-residence")
    }


    return (
        <React.Fragment>
            <div className="dashboard">
                <Header />
                <section className="container dashboard-view">
                <Breadcrumbs data={[
                    {label: 'Home', path: '/'},
                    {label: 'Residences'}
                ]}/>
                    <div className="white-block p-3 white-box-wide">
                        <div className="row">
                            <div className="col">
                                <h5>My Residences</h5>
                            </div>
                            <div className="col text-end">
                                <span onClick={addResidence}>
                                    <Button className="icon-btn"><MdAddCircle /></Button>
                                </span>

                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            {residencies && residencies.length > 0 ? residencies?.map((residence: any, index: number) => (
                                <div className="col-md-6 mb-2" key={index}>
                                    <div className="open-case-block">

                                        <div className="open-case-pic my-residences-pic">
                                            {residence?.facilityStatusId == 872 ?
                                                <a onClick={() => { editMe(residence) }} className="my-residences-edit">Edit <i className="fa fa-pencil" aria-hidden="true"></i></a>
                                                : null}
                                            <div className="slider">
                                                <ImageSlider residence={residence}/>
                                            </div>
                                            <span className="active-status">{getFilteredResidenceStatus(residence.facilityStatusId)}</span>
                                        </div>


                                        <div className="my-residences-rating">
                                            {residence?.facilityStatusId == 834 ?
                                                <div>{getRating(residence.rating != null ? residence.rating : 2.5, 5, 5)}</div>
                                                : null
                                            }
                                        </div>
                                        <div className="d-flex my-residences-student-panel" onClick={() => navigate(`/residence-details/${residence.facilityId}`)}>
                                            <div className="col">
                                                <h3>{residence.name}</h3>
                                            </div>
                                            <div className="col">
                                                <p>{residence.distance}</p>
                                            </div>
                                            <div className="col">
                                                <p>{residence.address}</p>
                                            </div>
                                        </div>
                                        <div className="open-case-feature-block-wrapper my-residences-feature-block-wrapper">
                                            {residence.amenities.length > 0 ?
                                                residence.amenities.map((item: any, k: number) => (
                                                    <div className="open-case-feature-block" key={k}>
                                                        {item.icon ? parse(item.icon) : null}
                                                        <h3>{item?.iconName}</h3>
                                                    </div>
                                                ))
                                                : 
                                                <div className="open-case-feature-block d-flex error-msg-text">
                                                    <i className="fa fa-refresh me-5" style={{color: "red"}} aria-hidden="true" />
                                                    <p style={{color: "red"}}>
                                                        For some reason, we couldn't load this amenities, please try reload or try again later
                                                    </p>
                                                </div>
                                                }
                                        </div>
                                        <div className="my-residences-btns">

                                            {residence?.facilityStatusId == 834 ?
                                                <div className="btn-full-width">
                                                    <button onClick={() => { editMe(residence) }} className="full-width-yellow-btn">Regrade</button>
                                                </div>
                                                : null
                                            }
                                            {residence?.facilityStatusId == 835 || residence?.facilityStatusId == 836 ?
                                                <div className="btn-full-width">
                                                    <Link to={"/create-case/" + residence?.facilityId + '?name=' + residence.name + '&type=Appeal'} className="full-width-yellow-btn">Appeal</Link>
                                                </div>
                                                : null
                                            }
                                            {residence?.facilityStatusId == 872 ?
                                                <div className="d-flex w-100 justify-content-between">
                                                    <button onClick={() => { deleteNotification(residence) }} className="full-width-outline-btn"><i className="fa-solid fa-trash"></i> Remove</button>
                                                    <Link to={"/make-payment/" + residence?.facilityId} className="full-width-yellow-btn">Pay Now</Link>
                                                </div>
                                                : null
                                            }
                                            {residence?.facilityStatusId == 833 || residence?.facilityStatusId == 840 || residence?.facilityStatusId == 0 ?
                                                <div className="btn-full-width">
                                                    <button className="full-width-yellow-btn" onClick={() => { withdrawalNotification(residence) }}>Withdrawal</button>
                                                </div>
                                                : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))
                                :
                                <div className="upload-documents" onClick={addResidence}>
                                    <MdAddCircle className="icon" style={{ cursor: "pointer" }} />
                                    <label>Add Residence</label>
                                </div>
                            }
                        </div>
                    </div>
                </section>
            </div>
        </React.Fragment>
    );
}