import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import Header from '../components/header'
import { useCommonApi } from '../_common/hooks/api/common/commonApiHook'
import { useAppLoader } from '../_common/hooks/common/appLoaderHook'
import Breadcrumbs from './student/components/breadcrumbs'

const Tenants = () => {
    const navigate = useNavigate()
    const commonApi = useCommonApi()
    const { id } = useParams()
    const { showLoader, hideLoader } = useAppLoader()

    const [tenants, setTenants] = useState<any>(null)
    const [fundType, setFundType] = useState<any>(null)
    const [tenantStatus, setTenantStatus] = useState<any>(null)

    const getTenants = () => {
        showLoader();
        commonApi.retreive({
            "Entity": "Tenant",
            "Top": 100,
            "ReturnCols": ["TenantId", "Name", "StudentId", "RentDue", "FundTypeId", "TenantStatusId", "FacilityIdName"],
            "FilterOn": {
                "Type": "And",
                "Where": [{
                    "filterCol": "FacilityId", "FilterCondition": "Equals",
                    "FilterValue": id
                }]
            }
        }, (message: string, resp: any) => {
            hideLoader();
            // console.log({ resp: JSON.parse(resp) })
            let data = JSON.parse(resp)
            if (data.length > 0) {
                setTenants(data)
            }
        }, (message: string) => {
            hideLoader();
            toast.error(message);
        });
    }

    const getFundTypeList = () => {
        commonApi.post({
            "entityName": "Picklist",
            "requestName": "RetrieveSelectOptionExecuteRequest",
            "inputParamters":
            {
                "SelectOption": {
                    "OptionText": "FundType"

                }
            }
        }, (message: string, resp: any) => {
            // console.log({ resp })
            if (resp.outputParameters.Count > 0) {
                setFundType(resp.outputParameters.data)
            }
        }, (message: string) => {
            console.log(message);
        });
    }

    const getTenantStatusList = () => {
        commonApi.post({
            "entityName": "Picklist",
            "requestName": "RetrieveSelectOptionExecuteRequest",
            "inputParamters":
            {
                "SelectOption": {
                    "OptionText": "TenantStatus"

                }
            }
        }, (message: string, resp: any) => {
            // console.log({ resp })
            if (resp.outputParameters.Count > 0) {
                setTenantStatus(resp.outputParameters.data)
            }
        }, (message: string) => {
            console.log(message);
        });
    }

    useEffect(() => {
        if (id) {
            getTenants()
        }
    }, [id])

    useEffect(() => {
        getFundTypeList()
        getTenantStatusList()
    }, [])

    return (
        <React.Fragment>
            <div className="dashboard">
                <Header />
                <div className="tenats">
                    <div className="container">
                        <Breadcrumbs data={[
                            { label: 'Home', path: '/' },
                            { label: 'Student Place', path: '/dashboard' },
                            { label: 'Tenants' }
                        ]} />
                        <div className="tenants-bg">
                            <div className="tenats-header">
                                <h2>My Tenants @ {tenants && tenants.length > 0 ? tenants[0].FacilityIdName : null}</h2>
                                <ul>
                                    <li><a href="#"><i className="fa fa-file-text"></i> Import</a></li>
                                    <li><a href="#"><i className="fa fa-file-text"></i> Export</a></li>
                                </ul>
                            </div>
                            <div className="tenats_content">
                                <div className="table-responsive">
                                    <table className='table'>
                                        <tbody>
                                            {tenants && tenants.length > 0 ?
                                                tenants.map((tenant: any, index: number) => (
                                                    <tr key={index} onClick={() => navigate(`/tenants-details/${tenant?.TenantId}`)}>
                                                        <td>
                                                            Tenant
                                                            <h4>{tenant?.Name}</h4>
                                                        </td>
                                                        <td>
                                                            Rent due
                                                            <h4>{tenant?.RentDue}</h4>
                                                        </td>
                                                        <td>
                                                            Funding type
                                                            <h4>{fundType && fundType.length > 0 ?
                                                                fundType.map((item: any) => (
                                                                    item.optionValue == tenant?.FundTypeId ? item.optionText : ""
                                                                )) : null}</h4>
                                                        </td>
                                                        <td>
                                                            Status
                                                            <h4>{tenantStatus && tenantStatus.length > 0 ?
                                                                tenantStatus.map((item: any) => (
                                                                    item.optionValue == tenant?.TenantStatusId ? item.optionText : ""
                                                                )) : null}</h4>
                                                        </td>
                                                    </tr>
                                                ))
                                                :
                                                <tr><td><h4>No Tenants</h4></td></tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div onClick={() => navigate(URLS.INSTITUTION)}>import/export</div> */}
            </div>
        </React.Fragment>
    )
}

export default Tenants