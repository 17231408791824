import React, { useContext, useEffect, useState } from "react";
import IntroSlider from '../components/introSlider';

import { Container, Row, Col, Button } from 'react-bootstrap';
import { useUserApi } from "../_common/hooks/api/UserApiHook";
import { useForm } from "react-hook-form";

export default function EntryType() {

	const [tab, setTab] = useState<any>('company');


	return (
		<React.Fragment>
			<div className="register_page login_page">
				<div className="register_left">
					<div className="mob_logo">
						<a href="#" className="logo_left"><img src="/assets/img/logo.png" /></a>
					</div>

					<section className="container">
						<div className="white-block p-3">
							<div className="register_tab">
								<nav className="dual-menu">
									<a className={"btn-menu " + (tab == 'company' ? 'active' : '')} onClick={() => { setTab('company'); }} >Accomodation Provider</a>
									<a className={"btn-menu " + (tab == 'student' ? 'active' : '')} onClick={() => { setTab('student'); }} >Student</a>
								</nav>

								<br />
								{/* {tab=='company'? <p>NSFAS will fund students who are staying at private accommodation but students will have to make sure that the accommodation is NSFAS accredited in order for NSFAS to pay for it.</p>: <>
						<p>Do you wish to study or are already studying at a public university or TVET college and come from a family with an income of R350 000 or less? Then STOP and read this:</p>
						<p>You qualify for a government bursary offered via the National Student Financial Aid Scheme (NSFAS)</p>
						</>} */}
								{tab == 'company' ? <p>Become an accredited NSFAS Accommodation Provider and list your with to get first preference for accommodating NSFAS funded students.</p> 
								: <>
									<p>Do you wish to study or are already studying at a public university or TVET college and come from a family with an income of R350 000 or less? Then STOP and read this:</p>
									<p>You qualify for a government bursary offered via the National Student Financial Aid Scheme (NSFAS)</p>
								</>}

								<br />
								<div className="d-flex justify-content-between">
									<Button as="a" href={tab == 'company' ? "/registration" : '/student-registration'} style={{ width: '48%' }} className="mt-2">Register</Button>
									<Button as="a" href={tab == 'company' ? "/login?type=provider" : '/login?type=student'} style={{ width: '48%' }} className="mt-2">Login</Button>
								</div>
							</div>


						</div>
					</section>


				</div>
				<IntroSlider />
			</div>

		</React.Fragment>
	);
}