import { SAGA_ACTIONS } from '../../../_config';
import { useApiCall } from '../common/appApiCallHook';


export function useResidenceApi() {

  const callApi = useApiCall();

  const getResidence = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.RESIDENCE.GET_RESIDENCE, data, onSuccess, onError);
  }
  const getResidenceRooms = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.RESIDENCE.GET_RESIDENCE_ROOMS, data, onSuccess, onError);
  }

  const getResidenceAmenities = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.RESIDENCE.GET_RESIDENCE_AMENITIES, data, onSuccess, onError);
  }
  const getResidenceAdditionalInfo = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.RESIDENCE.GET_RESIDENCE_ADDITIONAL_INFO, data, onSuccess, onError);
  }
  const getResidencies = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.RESIDENCE.GET_RESIDENCIES, data, onSuccess, onError);
  }
  const getCaseQueryTypes = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.RESIDENCE.GET_CASE_QUERY_TYPES, data, onSuccess, onError);
  }
  const getPaymentValue = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.RESIDENCE.GET_PAYMENT_VALUE, data, onSuccess, onError);
  }
  



  return {
    getResidence: getResidence,
    getResidenceRooms: getResidenceRooms,
    getResidenceAmenities: getResidenceAmenities,
    getResidenceAdditionalInfo: getResidenceAdditionalInfo,
    getResidencies: getResidencies,
    getCaseQueryTypes: getCaseQueryTypes,
    getPaymentValue: getPaymentValue,
  }
}