import { SAGA_ACTIONS } from '../../../../_config';
import { useApiCall } from '../../common/appApiCallHook';
import {
    ContentReq,
    DetailsReq,
    BlankReq,
    BannerReq
} from '../../../interfaces/ApiReqRes'


export function useCommonApi() {

    const callApi = useApiCall();
        const post = (data: BlankReq, onSuccess: Function, onError: Function) => {
            callApi(SAGA_ACTIONS.COMMON.POST, data, onSuccess, onError);
        }

        const get = (data: BlankReq, onSuccess: Function, onError: Function) => {
            callApi(SAGA_ACTIONS.COMMON.GET, data, onSuccess, onError);
        }
        const getInstitutions = (data: BlankReq, onSuccess: Function, onError: Function) => {
            callApi(SAGA_ACTIONS.COMMON.GET_INSTITUTIONS, data, onSuccess, onError);
        }

        const getCampus = (data: BlankReq, onSuccess: Function, onError: Function) => {
            callApi(SAGA_ACTIONS.COMMON.GET_CAMPUS, data, onSuccess, onError);
        }
        const getCapacities = (data: BlankReq, onSuccess: Function, onError: Function) => {
            callApi(SAGA_ACTIONS.COMMON.GET_CAPACITIES, data, onSuccess, onError);
        }

        const retreive = (data: BlankReq, onSuccess: Function, onError: Function) => {
            callApi(SAGA_ACTIONS.COMMON.RETREIVE, data, onSuccess, onError);
        }

        const retreiveInstitution = (data: BlankReq, onSuccess: Function, onError: Function) => {
            callApi(SAGA_ACTIONS.COMMON.RETREIVE_INSTITUTION, data, onSuccess, onError);
        }
       
        const getRooms = (data: BlankReq, onSuccess: Function, onError: Function) => {
            callApi(SAGA_ACTIONS.COMMON.GET_ROOMS, data, onSuccess, onError);
        }

        const getStudentCasesClassification = (data: BlankReq, onSuccess: Function, onError: Function) => {
            callApi(SAGA_ACTIONS.COMMON.GET_STUDENT_CASES_CLASSIFICATION, data, onSuccess, onError);
        }

        const retreiveCaseDetails = (data: BlankReq, onSuccess: Function, onError: Function) => {
            callApi(SAGA_ACTIONS.COMMON.RETREIVE_CASE_DETAILS, data, onSuccess, onError);
        }
        
        const retrieveStudentCaseRegardingId = (data: BlankReq, onSuccess: Function, onError: Function) => {
            callApi(SAGA_ACTIONS.COMMON.RETREIVE_STUDENT_CASE_REGARDINGID, data, onSuccess, onError);
        }
        
        const getImportType = (data: BlankReq, onSuccess: Function, onError: Function) => {
            callApi(SAGA_ACTIONS.COMMON.GET_IMPORT_TYPE, data, onSuccess, onError);
        }

    return {
        post: post,
        get: get,
        getInstitutions: getInstitutions,
        getCampus: getCampus,
        getCapacities: getCapacities,
        retreive: retreive,
        retreiveInstitution: retreiveInstitution,
        getRooms: getRooms,
        getStudentCasesClassification: getStudentCasesClassification,
        retreiveCaseDetails: retreiveCaseDetails,
        retrieveStudentCaseRegardingId: retrieveStudentCaseRegardingId,
        getImportType: getImportType,
    }
}