import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Container, Row, Col, Button } from 'react-bootstrap';
import toast from "react-hot-toast";
import { useCommonApi } from "../../../../_common/hooks/api/common/commonApiHook";
import Select from "react-select";
interface incomingProps {
  onSubmitStudentDetailsForm: any;
}

export default function StudentDetailsForm({ onSubmitStudentDetailsForm }: incomingProps) {

  const { register, handleSubmit, control, formState: { errors }, watch } = useForm();
  const institution = watch('institution');

  const commonApi = useCommonApi();

  const [institutions, setInstitutions] = useState<any>(null);
  const [campuses, setCampuses] = useState<any>(null);
  const [yearOfStudy, setYearOfStudy] = useState<any>(null);

  const onSubmit = (data: any) => {
    // console.log({data},data.password === data.confPassword)
    if (data.Password === data.confPassword) {
      let params: any = {
        FirstName: data.FirstName,
        LastName: data.LastName,
        IDNumber: data.IDNumber,
        StudentNumber: data.StudentNumber,
        InstitutionId: data.institution.value,
        CampusId: data.campus.value,
        YearOfStudyId: data.YearOfStudyId.value,
        Mobile: data.Mobile,
        Telephone: data.Telephone,
        Email: data.Email,
        Password: data.Password
      }
      console.log('data=>', params);

      onSubmitStudentDetailsForm(params)
    } else {
      toast.error('password and confirm password didnot match.')
    }
  }

  const getYearOfStudy = () => {
    commonApi.post({
      "entityName": "Picklist",
      "requestName": "RetrieveSelectOptionExecuteRequest",
      "inputParamters":
      {
        "SelectOption": {
          "OptionText": "YearOfStudy"

        }
      }
    }, (message: string, resp: any) => {
      console.log({ resp: resp.outputParameters.data })
      let data = resp.outputParameters.data;
      let options: any = [];
      data.map((ins: any) => {
          ins.value = ins.optionValue;
          ins.label = ins.optionText;
          options.push(ins);
      })
      console.log(options)
      setYearOfStudy(options)
    }, (message: string) => {
      // console.log(message);
    });
  }

  const getInstitutions = () => {
    commonApi.retreiveInstitution({}, (message: string, resp: any) => {
      // console.log({ resp })
      let options: any = [];
      resp.map((ins: any) => {
        if (ins.recordId.search('000000') !== 0) {
          ins.value = ins.recordId;
          ins.label = ins.mainText;
          options.push(ins);
        }
      })
      // console.log(options)
      setInstitutions(options)
    }, (message: string) => {
      // console.log(message);
    });
  }

  const getCampus = (id: any = institution?.value) => {
    return new Promise((resolve: any, reject: any) => {
      commonApi.getCampus({
        filters: "InstitutionId eq '" + id + "'",
        ViewName: 'LookUpView'
      }, (message: string, resp: any) => {
        let options: any = [];
        resp.map((ins: any) => {
          if (ins.recordId.search('000000') !== 0) {
            ins.value = ins.recordId;
            ins.label = ins.mainText;
            options.push(ins);
          }
        })
        setCampuses(options)
        setTimeout(() => {
          resolve()
        }, 300)
      }, (message: string) => {
        // console.log(message);
        reject()
      });
    })
  }

  useEffect(() => {
    getInstitutions();
    getYearOfStudy();
  }, [])

  useEffect(() => {
    if (institution) {
      getCampus()
    }
  }, [institution])

  useEffect(() => {
    console.log({ institutions })
  }, [institutions])

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="tab_scrollcontent">
          <div className="mb-2">
            <label htmlFor="exampleFormControlInput1" className={"form-label " + errors.FirstName ? 'is-invalid' : ''}>First name</label>
            <input type="text" className="form-control" id="exampleFormControlInput1" {...register("FirstName", { required: true, maxLength: 80 })} />
            {errors?.FirstName ? <div className="invalid-feedback">Required</div> : null}
          </div>
          <div className="mb-2">
            <label htmlFor="exampleFormControlInput2" className={"form-label " + errors.LastName ? 'is-invalid' : ''}>Last name</label>
            <input type="text" className="form-control" id="exampleFormControlInput2" {...register("LastName", { required: true, maxLength: 80 })} />
            {errors?.LastName ? <div className="invalid-feedback">Required</div> : null}
          </div>
          <div className="mb-2">
            <label htmlFor="exampleFormControlInput3" className={"form-label " + errors.IDNumber ? 'is-invalid' : ''}>ID number</label>
            <input type="text" className="form-control" id="exampleFormControlInput3" {...register("IDNumber", { required: true, maxLength: 80 })} />
            {errors?.IDNumber ? <div className="invalid-feedback">Required</div> : null}
          </div>
          <div className="mb-2">
            <label htmlFor="exampleFormControlInput4" className={"form-label " + errors.StudentNumber ? 'is-invalid' : ''}>Student number</label>
            <input type="text" className="form-control" id="exampleFormControlInput4" {...register("StudentNumber", { required: true, maxLength: 80 })} />
            {errors?.StudentNumber ? <div className="invalid-feedback">Required</div> : null}
          </div>
          <div className="mb-2">
            <label className="form-label">Institution</label>
            <Controller
              name={"institution"}
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange, onBlur } }) => {
                return (<Select
                  className={"form-control p-0" + (errors?.institution ? ' is-invalid' : '')}
                  options={institutions}
                  placeholder={""}
                  onChange={onChange}
                  value={value}
                  defaultValue={value}
                />)
              }}
            />
          </div>
          <div className="mb-2">
            <label className="form-label">Campus</label>
            <Controller
              name={"campus"}
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange, onBlur } }) => {
                return (<Select
                  className={"form-control p-0" + (errors?.campus ? ' is-invalid' : '')}
                  options={campuses}
                  placeholder={""}
                  onChange={onChange}
                  value={value}
                  defaultValue={value}
                />)
              }}
            />
          </div>
          <div className="mb-2">
            <label className="form-label">Years Of Study</label>
            <Controller
              name={"YearOfStudyId"}
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange, onBlur } }) => {
                return (<Select
                  className={"form-control p-0" + (errors?.YearOfStudyId ? ' is-invalid' : '')}
                  options={yearOfStudy}
                  placeholder={""}
                  onChange={onChange}
                  value={value}
                  defaultValue={value}
                />)
              }}
            />
          </div>
          <div className="mb-2">
            <label htmlFor="exampleFormControlInput7" className={"form-label " + errors.Mobile ? 'is-invalid' : ''}>Mobile</label>
            <input type="text" className="form-control" id="exampleFormControlInput7" {...register("Mobile", { required: true, maxLength: 80 })} />
            {errors?.Mobile ? <div className="invalid-feedback">Required</div> : null}
          </div>
          <div className="mb-2">
            <label htmlFor="exampleFormControlInput8" className={"form-label " + errors.Telephone ? 'is-invalid' : ''}>Telephone</label>
            <input type="text" className="form-control" id="exampleFormControlInput8" {...register("Telephone", { required: false, maxLength: 80 })} />
            {/* {errors?.Telephone ? <div className="invalid-feedback">Required</div> : null} */}
          </div>
          <div className="mb-2">
            <label htmlFor="exampleFormControlInput9" className={"form-label " + errors.Email ? 'is-invalid' : ''}>Email</label>
            <input type="text" className="form-control" id="exampleFormControlInput9" {...register("Email", { required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} />
            {errors?.Email ? <div className="invalid-feedback">
              {errors.Email.type === "required" ? 'Required' : null}
              {errors.Email.type === "pattern" ? 'Invalid email address' : null}
            </div> : null}
          </div>
          <div className="mb-2">
            <label htmlFor="exampleFormControlInput10" className={"form-label " + errors.Password ? 'is-invalid' : ''}>Password</label>
            <input type="password" className="form-control" id="exampleFormControlInput10" {...register("Password", { required: true, minLength: 6, maxLength: 80 })} />
            {errors?.Password ? <div className="invalid-feedback">
              {errors.Password.type === "required" ? 'Required' : null}
              {errors.Password.type === "minLength" ? 'Password must be at least 6 characters' : null}
            </div> : null}
          </div>
          <div className="mb-2">
            <label htmlFor="exampleFormControlInput11" className={"form-label" + (errors?.confPassword ? ' is-invalid' : '')}>Confirm Password</label>
            <input type="password" className="form-control" id="exampleFormControlInput11" placeholder="Confirm Password" {...register("confPassword", { required: true, minLength: 6, maxLength: 80 })} />
            {errors?.confPassword?.type == 'required' ? <div className="invalid-feedback">Required</div> : null}
            {errors?.confPassword?.type == 'minLength' ? <div className="invalid-feedback">Password must be at least 6 characters</div> : null}
          </div>
        </div>
        <Row className="align-items-center">
          <Col md={6}>
            <div className="mobbottom_fixbtn">
              <button className="btn btn-primary w-50 mt-2" type="submit">Next</button>
            </div>
          </Col>
        </Row>
      </form>

    </React.Fragment>
  );
}
