import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useNavigationSelector, useUserSelector } from "../../_common/hooks/selectors/userSelector";
import { NAVIGATIONS } from "../../_config";
import Header from "./components/header";

export default function Dashboard() {
    const user: any = useUserSelector();
    const navigation: any = useNavigationSelector()
    const navigate = useNavigate();

    const [navigations, setNavigations] = useState<any>(null)

    useEffect(() => {
        if (navigation == undefined) {
            let storedNavigation = sessionStorage.getItem(NAVIGATIONS) || '[]';
            storedNavigation = JSON.parse(storedNavigation);
            setNavigations(storedNavigation)
        } else {
            setNavigations(navigation)
        }
    }, [navigation])

    return (
        <React.Fragment>
            <div className="dashboard" style={{ paddingTop: "0px" }}>
                <Header />
                <div className="dashboard-wrapper">
                    <div className="dashboard-wrapper-inner">
                        <h3>Welcome</h3>
                        <h2>{user?.name}</h2>
                        <div className="dashboard-block-wrapper">
                            {navigations && navigations.length > 0 ?
                                navigations.map((items: any, index: number) => (
                                    <div className="dashboard-block" onClick={() => navigate(items?.navigate)} key={index}>
                                        <div className="dash-notification-icon">
                                            {items?.notification != 0 && items?.notification != null?
                                                <span>{items?.notification}</span>
                                                : null
                                            }
                                            <i className={items?.icon} aria-hidden="true"></i>
                                        </div>
                                        <h4>{items?.title}</h4>
                                    </div>
                                ))
                                : null
                            }
                            {/* <div className="dashboard-block" onClick={() => navigate('/student/my-residences')}>
                                <i className="fa fa-home" aria-hidden="true"></i>
                                <h4>My Residences</h4>
                            </div>
                            <div className="dashboard-block" onClick={() => navigate('/student/my-cases')}>
                                <div className="dash-notification-icon">
                                    <span>2</span>
                                    <i className="fa fa-briefcase" aria-hidden="true"></i>
                                </div>
                                <h4>My Cases</h4>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}