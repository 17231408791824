import React, { useContext, useEffect, useState } from "react";
import { useAppLoader } from '../_common/hooks/common/appLoaderHook';
import { useUserApi } from "../_common/hooks/api/UserApiHook";
import { Container, Row, Col, Button } from 'react-bootstrap';
import IntroSlider from '../components/introSlider';
import { useForm } from "react-hook-form";
import { useSearchParams, useNavigate }  from 'react-router-dom';
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import { toast } from "react-hot-toast";
import { useUserSelector } from "../_common/hooks/selectors/userSelector";

export default function ResetPassword() {
	const user:any = useUserSelector()
	const navigate = useNavigate();
	const { showLoader, hideLoader } = useAppLoader();
	const userApi = useUserApi();
	const commonApi = useCommonApi();
	const [loginError, setLoginError] = useState<string | null>(null);
	const { register, handleSubmit, formState: { errors } } = useForm();
	const [searchParams] = useSearchParams();
  	const type = searchParams.get('type');

	  const logout = () => {

		userApi.logout({}, (message: string, resp: any) => {
		
		}, (message: string) => {

		})
	}

	console.log(errors)
	const onSubmit = (data: any) => {
		console.log('data=>',data);
		if(data.password === data.confPassword){
			showLoader();
			commonApi.post({
				"entityName": "ExternalLogon",
				"requestName": "ResetPasswordExecuteRequest",
				"inputParamters":  
					{   
					  "ResetPassword": {
						 "Username":user.email,
						 "Password":data.password             
					  }  
					}
			  }, (message: string, resp: any) => {
				hideLoader();
				toast.success(message);
				logout();
			}, (message: string) => {
				hideLoader();
				toast.error(message);
			});
		} else {
			toast.error('password and confirm password didnot match.')
		}
		
	}

	// useEffect(() => {
	// 	if(type == null){
	// 		navigate('/entry-type')
	// 	}
	// },[])

	return (
		<React.Fragment>
			<div className="register_page login_page">
				<div className="register_left">
					<div className="mob_logo">
						<a href="#" className="logo_left"><img src="/assets/img/logo.png" /></a>
					</div>
					<div className="logo_txt">
						Reset Password
					</div>
					{/* <form className="register_tab" onSubmit={handleSubmit(onSubmit)}> */}
					<form className="register_tab" onSubmit={handleSubmit(onSubmit)}>
						<div className="mb-2">
							<label htmlFor="exampleFormControlInput1" className={"form-label" + (loginError || errors?.password ? ' is-invalid' : '')}>Password</label>
							<input type="password" className="form-control" id="exampleFormControlInput1" placeholder="Password" {...register("password", { required: true, minLength:6, maxLength: 80 })} />
							{errors?.password?.type == 'required' ? <div className="invalid-feedback">Required</div> : null}
							{errors?.password?.type == 'minLength' ? <div className="invalid-feedback">Password must be at least 6 characters</div> : null}
						</div>
						<div className="mb-2">
							<label htmlFor="exampleFormControlInput1" className={"form-label" + (loginError || errors?.confPassword ? ' is-invalid' : '')}>Confirm Password</label>
							<input type="password" className="form-control" id="exampleFormControlInput1" placeholder="Confirm Password" {...register("confPassword", { required: true, minLength:6, maxLength: 80 })} />
							{errors?.confPassword?.type == 'required' ? <div className="invalid-feedback">Required</div> : null}
							{errors?.confPassword?.type == 'minLength' ? <div className="invalid-feedback">Password must be at least 6 characters</div> : null}
						</div>
						<div className="mobbottom_fixbtn">
							<Button variant="primary" className="w-50 mt-2" type="submit">Submit</Button>
						</div>
					</form>
				</div>
				<IntroSlider/>
			</div>
		</React.Fragment>
	);
}