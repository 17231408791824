import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Dropdown } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import { useUserApi } from "../_common/hooks/api/UserApiHook";
import { useUserSelector } from "../_common/hooks/selectors/userSelector";
import { URLS } from "../_config";

interface incomingProps { }

export default function Header({ }: incomingProps) {
	const userApi = useUserApi();
	const user:any = useUserSelector()
	const navigate = useNavigate();
	
	const [userInitials, setUserInitials] = useState<any>()
	const logout = (e: any) => {

		e.preventDefault();
		e.stopPropagation();
		userApi.logout({}, (message: string, resp: any) => {
		
		}, (message: string) => {

		})
	}

	const updateProfile = () => {
		navigate(URLS.UPDATE_PROFILE)
	}

	useEffect(() => {
		// let userDetails: any = sessionStorage.getItem('nsfas')
		// userDetails = JSON.parse(userDetails)
		// console.log(userDetails)
		let initials = user?.name
		if(initials.split(' ').length > 1){
			let firstInitials = initials.split(' ')[0]
			let secondInitials = initials.split(' ')[1]
			setUserInitials(firstInitials[0]+secondInitials[0])
		}
		else {
			setUserInitials(initials[0])
		}
	},[]);

	return (
		<nav className="navbar navbar-expand-lg navbar-light bg-orange">
			<div className="container-fluid">
				<div className="collapse navbar-collapse" id="navbarTogglerDemo01">
					<a className="navbar-brand" href="#">
						<img src="/assets/img/logo-white.png" alt="NSFAS" />
					</a>
					<ul className="navbar-nav me-auto ms-auto mb-2 mb-lg-0">
						<li className="nav-item">
							<Link className="nav-link active" aria-current="page" to="/">Home</Link>
						</li>
					</ul>
					<Dropdown>
						<Dropdown.Toggle variant="success" id="dropdown-basic" className="btn btn-outline-success">
							{userInitials && userInitials}
						</Dropdown.Toggle>

						<Dropdown.Menu>
							<Dropdown.Item onClick={updateProfile}>Edit Profile</Dropdown.Item>
							<Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</div>
			</div>
		</nav>
	);
}
