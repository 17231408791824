import React, { useContext, useEffect, useState } from "react";
import { useAppLoader } from '../_common/hooks/common/appLoaderHook';
import { useUserApi } from "../_common/hooks/api/UserApiHook";
import { Container, Row, Col, Button } from 'react-bootstrap';
import IntroSlider from '../components/introSlider';
import { useForm } from "react-hook-form";
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import { toast } from "react-hot-toast";

export default function ForgotPassword() {
	const navigate = useNavigate();
	const { showLoader, hideLoader } = useAppLoader();
	const userApi = useUserApi();
	const commonApi = useCommonApi();
	const [send, setSend] = useState<boolean>(false);
	const { register, handleSubmit, formState: { errors } } = useForm();
	const [searchParams] = useSearchParams();
	const type = searchParams.get('type');

	const onSubmit = (data: any) => {
		console.log(data)
		showLoader();
		commonApi.post({
			"entityName": "ExternalLogon",
			"requestName": "ForgotPasswordExecuteRequest",
			"inputParamters":
			{
				"ForgotPassword": {
					"Username": data.email
				}
			}
		}, (message: string, resp: any) => {
            hideLoader();
			toast.success(message);
			setSend(true)
        }, (message: string) => {
			hideLoader();
            toast.error(message);
        });
	}

	useEffect(() => {
		if (send == true) {
			setTimeout(() => {
				navigate('/login')
			}, 3000);
		}
	}, [send])

	return (
		<React.Fragment>
			<div className="register_page login_page">
				<div className="register_left">
					<div className="mob_logo">
						<a href="#" className="logo_left"><img src="/assets/img/logo.png" /></a>
					</div>
					<div className="logo_txt">
						Forgot Password
					</div>
					{send?
					<p className="text-center">Please check your mail.</p>
					:
					<form className="register_tab" onSubmit={handleSubmit(onSubmit)}>
					<div className="mb-2">
						<label htmlFor="exampleFormControlInput1" className={"form-label" + (errors?.email ? ' is-invalid' : '')}>Email address</label>
						<input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Email" {...register("email", { required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} />
						{errors?.email ? <div className="invalid-feedback">
							{errors.email.type === "required" ? 'Required' : null}
							{errors.email.type === "pattern" ? 'Invalid email address' : null}
						</div> : null}
					</div>
					<div className="mobbottom_fixbtn">
						<Button variant="primary" className="w-50 mt-2" type="submit">Submit</Button>
					</div>
				</form>
					}
				</div>
				<IntroSlider />
			</div>
		</React.Fragment>
	);
}