import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useCommonApi } from "../../_common/hooks/api/common/commonApiHook";
import Select, { components } from 'react-select';
import { MdAddCircle } from 'react-icons/md';
import toast from "react-hot-toast";
import { FaTimes } from 'react-icons/fa';
interface incomingProps {
    onSubmitAddressForm: any;
    tab: any
}

export default function AddressForm({ onSubmitAddressForm, tab }: incomingProps) {

    const commonApi = useCommonApi();

    const [province, setProvince] = useState<any>(null);
    const [provinces, setProvinces] = useState<any>(null);

    const { register, handleSubmit, control, formState: { errors }, watch, reset } = useForm();
    const [selectedFileNames, setSelectedFileNames] = useState<any>([]);
    const [selectedFiles, setSelectedFiles] = useState<any>([]);

    const files: any = watch('Documents');
    useEffect(() => {
        /* if (files?.length) {
            let names: any = []
            for (let i = 0; i < files?.length; i++) {
                if (files[i].size <= 2 * 1024 * 1024) {
                    names.push(files[i].name);
                } else {
                    toast.error("File size should be less 2MB")
                }
            }
            setSelectedFileNames(names)
        } */

        let documentsData: any = [];
        if (files?.length) {
            for (let i = 0; i < files?.length; i++) {
                let file: any = files[i];
                if (file.size <= 2 * 1024 * 1024) {
                    toBase64(file).then((b64: any) => {
                        documentsData.push({
                            "FileName": file.name,
                            "FileExtention": file.name.split('.').pop(),
                            "DocumentTypeId": 697,
                            "FileContent": b64.split(',')[1]
                        });

                        setSelectedFiles(documentsData);
                    })
                }
            }
        }

        



    }, [files])

    const toBase64 = (file: any) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const onSubmit = (data: any) => {
        let addressData: any = {
            "StreetNumber": data.StreetNumber,
            "StreetName": data.StreetName,
            "suburb": data.Surburb,
            "City": data.City,
            "Province": data.Province.value,
            "PostalCode": data.PostalCode,
            "APTypeId": "865",
            "IDTypeId": "862"
        }

        onSubmitAddressForm(addressData, selectedFiles)
    }

    const getProvinces = () => {

        commonApi.post({
            "entityName": "Picklist",
            "requestName": "RetrieveSelectOptionExecuteRequest",
            "inputParamters":
            {
                "SelectOption": {
                    "OptionText": "Province"

                }
            }
        }, (message: string, resp: any) => {

            if (resp.outputParameters.Count > 0) {
                setProvinces(resp.outputParameters.data.map((data: any) => {
                    data.label = data.optionText;
                    data.value = data.optionValue;
                    return data;
                }))
            }

        }, (message: string) => {
            console.log(message);
        });
    }

    const checkFileSize = (data: any) => {
        // console.log(data[0].name, data[0].size, data[0])
        let count: number = 0;
        for (let i = 0; i < data.length; i++) {
            if (data[i].size > 2 * 1024 * 1024) {
                count += 1;
            }
        }
        if (count > 0) {
            reset({
                Documents: null
            })
            toast.error("File size should be less 2MB")
        }
    }

    useEffect(() => {
        getProvinces();
    }, []);

    const deleteLocal = (file:any)=>{
        let _files:any = [];
        selectedFiles.map((_file:any)=>{
            if(_file.FileName != file.FileName){
                _files.push(_file)
            }
        });

        setSelectedFiles(_files)
    }

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="tab_scrollcontent">
                    <div className="mb-2">
                        <label htmlFor="exampleFormControlInput1a" className={"form-label " + errors.StreetNumber ? 'is-invalid' : ''}>Street Number</label>
                        <input type="text" className="form-control" id="exampleFormControlInput1a" placeholder="" {...register("StreetNumber", { required: true, maxLength: 160 })} />
                        {errors?.StreetNumber ? <div className="invalid-feedback">Required</div> : null}
                    </div>
                    <div className="mb-2">
                        <label htmlFor="exampleFormControlInput1a" className={"form-label " + errors.StreetName ? 'is-invalid' : ''}>Street Address</label>
                        <input type="text" className="form-control" id="exampleFormControlInput1a" placeholder="" {...register("StreetName", { required: true, maxLength: 160 })} />
                        {errors?.StreetName ? <div className="invalid-feedback">Required</div> : null}
                    </div>
                    <div className="mb-2">
                        <label htmlFor="exampleFormControlInput1a" className={"form-label " + errors.City ? 'is-invalid' : ''}>City</label>
                        <input type="text" className="form-control" id="exampleFormControlInput1a" placeholder="" {...register("City", { required: true, maxLength: 160 })} />
                        {errors?.City ? <div className="invalid-feedback">Required</div> : null}
                    </div>
                    <div className="row">
                        <div className="col-9">
                            <div className="mb-2">
                                <label htmlFor="exampleFormControlInput2a" className={"form-label " + errors.Surburb ? 'is-invalid' : ''}>Surburb</label>
                                <input type="text" className="form-control" id="exampleFormControlInput2a" placeholder="" {...register("Surburb", { required: true, maxLength: 80 })} />
                                {errors?.Surburb ? <div className="invalid-feedback">Required</div> : null}
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="mb-2">
                                <label htmlFor="exampleFormControlInput3a" className={"form-label " + errors.PostalCode ? 'is-invalid' : ''}>Postal Code</label>
                                <input type="text" className="form-control" id="exampleFormControlInput3a" placeholder="" {...register("PostalCode", { required: true, maxLength: 80 })} />
                                {errors?.PostalCode ? <div className="invalid-feedback">Required</div> : null}
                            </div>
                        </div>
                    </div>

                    <div className="mb-2">
                        <label className="form-label">Province</label>

                        <Controller
                            name={"Province"}
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { value, onChange, onBlur } }) => {
                                return (<Select
                                    className={"form-control p-0" + (errors?.Province ? ' is-invalid' : '')}
                                    options={provinces}
                                    placeholder={""}
                                    onChange={onChange}
                                    value={value}
                                    defaultValue={value}
                                />)
                            }}
                        />
                    </div>

                </div>


                <div className="upload-documents">
                    <input type="file" id="profile_image" className={"form-control" + (errors?.pic ? ' is-invalid' : '')} multiple={true} accept=".png,.jpg,.jpeg,application/pdf" {...register("Documents", { required: false })} />
                    <MdAddCircle className="icon" />
                    <label>Add Documents</label>
                </div>
                {selectedFiles.map((file: any) => (<p className="fname"><FaTimes className="delete-me" onClick={()=>{deleteLocal(file)}} />{file.FileName}</p>))}
                <div className="upload-instruction mt-3">
                    <h5>Please upload the following:</h5>
                    <ul>
                        <li>ID Copy of the director</li>
                        {tab != 'indi' ? <li>Company registration as per CIPC</li> : null}
                    </ul>
                </div>

                <Row className="align-items-center">
                    <Col md={6}>
                        <div className="mobbottom_fixbtn">
                            <Button variant="primary" className="w-50 mt-2" type="submit">   Register  </Button>
                        </div>
                    </Col>
                    {/* <Col md={6} className="text-end">
                        <a href="/">Sign in</a>
                    </Col> */}
                </Row>





            </form>

        </React.Fragment>
    );
}
