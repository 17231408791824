
import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import Header from './components/header';
import Breadcrumbs from './components/breadcrumbs';
import FindAccomodation from './components/findAccomodation';
import AccomodationCard from './components/accomdationCard';
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import toast from "react-hot-toast";
import { useUserSelector } from '../../_common/hooks/selectors/userSelector';



export default function SearchResidence() {

    const commonApi = useCommonApi();
    const { showLoader, hideLoader } = useAppLoader();
    const [residencies, setResidencies] = useState<any>(null);
    const [searchKey, setSearchKey] = useState<string>('');
    const user: any = useUserSelector();


    const getResidencies = () => {
        showLoader();
        commonApi.post({
            "recordId": user.recordId,
            "requestName": "RetrieveStudentsResidencies"
        }, (message: string, resp: any) => {
            hideLoader();
            setResidencies(resp.outputParameters.Facilities);
            console.log('Residencies =>', resp.outputParameters.Facilities)
        }, (message: string) => {
            toast.error(message);
            hideLoader();
        });
    }

    const handleSearch = (txt: string) => {
        setSearchKey(txt);
    }

    useEffect(() => {
        getResidencies()
    }, [])

    return (
        <React.Fragment>
            <div className="dashboard" style={{ paddingTop: "0px" }}>
                <Header />
                <div className="container">
                    <Breadcrumbs data={[]} />
                    <FindAccomodation handleSearch={handleSearch} />
                    <div className='my-4'>Residence</div>
                    <Row>
                        {residencies?.map((residence: any, i: any) => {
                            if (searchKey !== '') {
                                if (residence.name.toLocaleLowerCase().search(searchKey.toLocaleLowerCase()) >= 0) {
                                    return (
                                        <Col md={6} key={i}>
                                            <AccomodationCard data={residence} route={"accomodation-details"} />
                                        </Col>
                                    )
                                }
                            } else {
                                return (
                                    <Col md={6} key={i}>
                                        <AccomodationCard data={residence} route={"accomodation-details"} />
                                    </Col>
                                )
                            }


                        })}
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}