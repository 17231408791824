import {Outlet, Navigate} from 'react-router-dom';
import { useAuthStatus, useChangePasswordHook } from '../_common/hooks/authHook';
import { useUserSelector } from '../_common/hooks/selectors/userSelector';
const OpenRoutes = () => {
    const isAuth = useAuthStatus();
    const user: any = useUserSelector();
    const changePassword = useChangePasswordHook();
    console.log('user=> ', user)
    return (
        isAuth?changePassword?<Navigate to="/reset-password" />:<Navigate to={user.supplierId?"/":'/student/'} />:<Outlet/>
    )

}

export default  OpenRoutes;