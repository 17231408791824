
import React, { useEffect, useState } from 'react';
import { Accordion, Button, Col, Row } from 'react-bootstrap';
import Header from './components/header';
import Breadcrumbs from './components/breadcrumbs';
import FindAccomodation from './components/findAccomodation';
import { BsStarFill, BsStarHalf, BsStar } from 'react-icons/bs';
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { toast } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { AiOutlinePlus } from 'react-icons/ai';
import parse from 'html-react-parser';
import StudentRoomModal from './components/studentRoomModal';


export default function AccomodationDetails() {
    const commonApi = useCommonApi();
    const { showLoader, hideLoader } = useAppLoader();
    const [residence, setResidence] = useState<any>(null);
    const [show, setShow] = useState<boolean>(false);

    const { id } = useParams();

    const getResidence = () => {
        showLoader();
        commonApi.post({
            "recordId": id,
            "requestName": "RetrieveFacilityDetails"
        }, (message: string, resp: any) => {
            hideLoader();
            setResidence(resp.outputParameters.FacilitiyDetails[0]);
            console.log('Residence =>', resp.outputParameters.FacilitiyDetails[0])
        }, (message: string) => {
            toast.error(message);
            hideLoader();
        });
    }

    const checkImageBase64Code = (url: any) => {
        if (url.search('data:image/') == 0) {
            return url;
        } else {
            return 'data:image/jpeg;base64,' + url
        }
    }

    const getRating = (rating: any, totalStar: any, outOf: any) => {
        if (rating) {
            let finalRating = (rating / outOf) * totalStar;
            const checkFraction = finalRating % 1 != 0;
            if (checkFraction == false) {
                const blankStar = totalStar - finalRating;
                let span: any = []
                for (let i = 0; i < finalRating; i++) {
                    span.push(<BsStarFill className="text-primary" key={Math.random()} />)
                }
                for (let i = 0; i < blankStar; i++) {
                    span.push(<BsStarFill style={{ color: '#d4d4d4' }} key={Math.random()} />)
                }
                return span
            } else {
                let fullStar = Math.round(finalRating * 10) / 10;
                let halfStar = Number(fullStar.toString().split(".")[1]);
                fullStar = Number(finalRating.toString().split(".")[0]);
                let span: any = []
                if (halfStar >= 5) {
                    let blankStar = totalStar - fullStar - 1;
                    for (let i = 0; i < fullStar; i++) {
                        span.push(<BsStarFill className="text-primary" key={Math.random()} />)
                    }
                    span.push(<BsStarHalf className="text-primary" />)
                    for (let i = 0; i < blankStar; i++) {
                        span.push(<BsStarFill key={i} style={{ color: '#d4d4d4' }} />)
                    }
                    // console.log(fullStar,halfStar, blankStar)
                    return span
                }
                else {
                    let blankStar = totalStar - fullStar + 1;
                    for (let i = 0; i < fullStar; i++) {
                        span.push(<BsStarFill className="text-primary" key={Math.random()} />)
                    }
                    for (let i = 0; i < blankStar; i++) {
                        span.push(<BsStarFill key={i} style={{ color: '#d4d4d4' }} />)
                    }
                    // console.log(fullStar, blankStar)
                    return span
                }
            }
        }
    }

    const handleClose = () => {
        setShow(false)
    }

    useEffect(() => {
        getResidence()
    }, [])


    return (
        <React.Fragment>

            <div className="dashboard" style={{ paddingTop: "0px" }}>
                <Header />
                <div className="container">
                    <Breadcrumbs data={[
                        { label: 'Home', path: '/student' },
                        { label: 'Residences', path: '/student/search-residence' },
                        { label: 'Residence' }
                    ]} />

                    <div style={{ textAlign: "center" }}>Residences</div>

                    {residence ?
                        <div className="accomodation-details">
                            <div className="card">
                                <div className="banner">
                                    {residence.images?.length > 0 ?
                                        <img src={checkImageBase64Code(residence.images[0].fileContent)} />
                                        :
                                        <img src="/assets/img/no-image-available.jpg" />}
                                </div>
                                <div style={{ padding: '10px' }}>
                                    <div className="rating">{getRating(residence.rating, 5, 5)}</div>
                                    {residence.message ?
                                        <div className="note">{residence.message}</div>
                                        : null}
                                    <Row className="student-place pt-4 pb-4">
                                        <Col md={6}>
                                            <h2>{residence.name}</h2>
                                        </Col>
                                        <Col md={6}>
                                            <p>{residence.distance}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Row>
                                                {residence.amenities.length > 0 ? residence.amenities?.map((amenity: any, i: any) => (
                                                    <Col className="text-center" key={i}>
                                                        {amenity.icon ? parse(amenity.icon) : null}
                                                        <p>{amenity.iconName}</p>
                                                    </Col>
                                                ))
                                                    :
                                                    <div className="amenity d-flex error-msg-text">
                                                        <i className="fa fa-refresh me-5" style={{ color: "red" }} aria-hidden="true" />
                                                        <p style={{ color: "red" }}>
                                                            For some reason, we couldn't load this amenities, please try reload or try again later
                                                        </p>
                                                    </div>
                                                }
                                            </Row>
                                        </Col>
                                        <Col md={4}>

                                        </Col>
                                        <Col md={2}>
                                            <Button variant="success" className='w-100'>Available</Button>
                                            <Row>
                                                <Col sm={6} className="price">
                                                    <label>Price</label>
                                                    <div>R{residence.price}</div>
                                                </Col>
                                                <Col sm={6} className="capacity">
                                                    <label>Capacity</label>
                                                    <div>{residence.capacity}</div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            {residence && residence.rooms.length > 0 ?
                                                <Button variant='primary' className='w-100' onClick={() => setShow(true)}>Apply</Button>
                                                : null}
                                        </Col>

                                    </Row>

                                    <Row className="pt-4">
                                        <Col sm={6} className="address-panel">
                                            <h4>Addresses</h4>
                                            <p>{residence.address}</p>
                                            <h6>{residence.distance}</h6>
                                            <div className='mt-3'>
                                                <img src="/assets/img/map.jpg" />
                                            </div>
                                        </Col>
                                        <Col sm={6} className="address-features">
                                            <h4>Addresses</h4>
                                            <Row>
                                                {residence && residence.rooms.length > 0 ? residence.rooms.map((item: any, i: number) => (
                                                    <Col sm={4} key={i}>
                                                        <div className="address-features-block">
                                                            <div className="icon">{item.icon ? parse(item.icon) : null}</div>
                                                            <h4>{item?.iconName}</h4>
                                                            <div className="address-features-block-inner text-center">
                                                                <h6>Price</h6>
                                                                <h5>R{item?.pricePerBed}</h5>
                                                                <h6>Applications/Capacity</h6>
                                                                <h5>10/{item?.quantity}</h5>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                ))
                                                    : null}
                                            </Row>
                                        </Col>
                                    </Row>

                                    <div className="pt-4">
                                        <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header><AiOutlinePlus></AiOutlinePlus> Property Overview</Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="student-accordian-content">
                                                        <Row className="row-alternet">
                                                            <Col sm={6}>
                                                                <Row>
                                                                    <Col sm={6}>
                                                                        <h5>Listing Number</h5>
                                                                    </Col>
                                                                    <Col sm={6} className="text-end">
                                                                        <h6>{residence?.propertyOverView?.listingNumber}</h6>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col sm={6}>
                                                                        <h5>Study Area</h5>
                                                                    </Col>
                                                                    <Col sm={6} className="text-end">
                                                                        <h6>{residence?.propertyOverView?.studyArea}</h6>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col sm={6}>
                                                                        <h5>Address</h5>
                                                                    </Col>
                                                                    <Col sm={6} className="text-end">
                                                                        <h6>{residence?.address}</h6>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col sm={6}>
                                                                        <h5>Description</h5>
                                                                    </Col>
                                                                    <Col sm={6} className="text-end">
                                                                        <h6>{residence?.propertyOverView?.description}</h6>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col sm={6}>
                                                                        <h5>Lease Period</h5>
                                                                    </Col>
                                                                    <Col sm={6} className="text-end">
                                                                        <h6>{residence?.propertyOverView?.leasePeriod}</h6>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col sm={6}>
                                                                        <h5>Disability Friendly</h5>
                                                                    </Col>
                                                                    <Col sm={6} className="text-end">
                                                                        <h6>{residence?.propertyOverView?.disbilityFriendly}</h6>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col sm={6}>
                                                                <Row>
                                                                    <Col sm={6}>
                                                                        <h5>Self-Catering</h5>
                                                                    </Col>
                                                                    <Col sm={6} className="text-end">
                                                                        <h6>{residence?.propertyOverView?.selfCatering}</h6>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col sm={6}>
                                                                        <h5>Room Type</h5>
                                                                    </Col>
                                                                    <Col sm={6} className="text-end">
                                                                        <h6>{residence?.propertyOverView?.roomType}</h6>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col sm={6}>
                                                                        <h5>Gender</h5>
                                                                    </Col>
                                                                    <Col sm={6} className="text-end">
                                                                        <h6>{residence?.propertyOverView?.gender}</h6>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col sm={6}>
                                                                        <h5>Shuttle Service</h5>
                                                                    </Col>
                                                                    <Col sm={6} className="text-end">
                                                                        <h6>{residence?.propertyOverView?.shuttleService}</h6>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col sm={6}>
                                                                        <h5>Wifi</h5>
                                                                    </Col>
                                                                    <Col sm={6} className="text-end">
                                                                        <h6>{residence?.propertyOverView?.wifi}</h6>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col sm={6}>
                                                                        <h5>Gym</h5>
                                                                    </Col>
                                                                    <Col sm={6} className="text-end">
                                                                        <h6>{residence?.propertyOverView?.gym}</h6>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header><AiOutlinePlus></AiOutlinePlus> Amenities</Accordion.Header>
                                                <Accordion.Body>
                                                    <Row>
                                                        <Col md={6}>
                                                            <Row>
                                                                {residence.amenities?.map((amenity: any, i: any) => (
                                                                    <Col className="text-center" key={i}>
                                                                        {amenity.icon ? parse(amenity.icon) : null}
                                                                        <p>{amenity.iconName}</p>
                                                                    </Col>
                                                                ))}
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>



                                    <div className="pt-3">
                                        <h3>Gallery</h3>
                                        <Row>
                                            {residence?.images.map((image: any, i: any) => (
                                                <Col sm={4} key={i}>
                                                    <div className="student-gallery-pic">
                                                        <img src={checkImageBase64Code(image.fileContent)} />
                                                    </div>
                                                </Col>
                                            ))}
                                        </Row>
                                    </div>
                                </div>



                            </div>




                        </div>
                        : null}
                </div>
            </div>
            <StudentRoomModal shouldShow={show} handleClose={handleClose} residence={residence} />
        </React.Fragment>
    )
}