import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'
import Header from '../components/header';
import { MdAddCircle } from 'react-icons/md';
import Breadcrumbs from './student/components/breadcrumbs';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { useAppLoader } from '../_common/hooks/common/appLoaderHook';
import { useCommonApi } from '../_common/hooks/api/common/commonApiHook';
import toast from 'react-hot-toast';
import '../styles/institutionUpload.scss'
import { FaFileDownload } from 'react-icons/fa';
import { useUserSelector } from '../_common/hooks/selectors/userSelector';
import ViewModal from '../components/modals/viewModal';
// import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
// import { DocumentViewer } from 'react-documents';
// import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';

const InstitutionUpload = () => {

  const { register, handleSubmit, control, formState: { errors, isValid }, getValues, watch, reset } = useForm({ mode: "onChange" });

  const navigate = useNavigate();
  const { showLoader, hideLoader } = useAppLoader();
  const commonApi = useCommonApi();
  const user: any = useUserSelector();

  const [docType, setDocType] = useState<any>(null);
  const [files, setFiles] = useState<any>([]);
  const [templateType, setTemplateType] = useState<any>(null);
  const [uploadTypeValue, setUploadTypeValue] = useState<any>(null);
  const [downloadTemplate, setdownloadTemplate] = useState<any>(null);
  const [dwnldBtnAction, setDwnldBtnAction] = useState<boolean>(true);
  const [view, setView] = useState<boolean>(false);

  const documents = watch('documents');
  const downloadType = watch('downloadType');
  const uploadType = watch('uploadType');

  const toBase64 = (file: any) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  const checkIsSelected = (name: string) => {
    // console.log('checking.................', files)
    return new Promise((resolve: any, reject: any) => {
      files.map((file: any) => {
        if (file.FileName == name) {
          reject()
        }
      });
      resolve()
    })
  }

  const getDocTypes = () => {
    commonApi.post({
      "entityName": "Picklist",
      "requestName": "RetrieveSelectOptionExecuteRequest",
      "inputParamters":
      {
        "SelectOption": {
          "OptionText": "DocTypes"

        }
      }
    }, (message: string, resp: any) => {
      resp.outputParameters.data.map((type: any) => {
        if (type.optionText == 'AP Documents') {
          setDocType(type.optionValue);
        }
      })
    }, (message: string) => {
      //oast.error(message);
    });
  }

  const deleteLocal = (file: any) => {
    let _files: any = [];
    files.map((_file: any) => {
      if (_file.FileName != file.FileName) {
        _files.push(_file)
      }
    });

    setFiles(_files)
  }

  const getImportType = () => {
    commonApi.getImportType({},
      (message: string, resp: any) => {
        // console.log({ resp })
        if (Array.isArray(resp)) {
          setTemplateType(resp.map((data: any) => {
            data.label = data.Name;
            data.value = data.DataImportTemplateId;
            return data;
          }))
        }
      }, (message: string) => {
        //toast.error(message);
      });
  }

  const downLoadTemplate = (id: string) => {
    commonApi.post({
      "recordId": id,
      "requestName": "DownloadTemplate"
    }, (message: string, resp: any) => {
      // console.log({ down: resp })
      if (resp.isSuccess) {
        setdownloadTemplate(resp?.outputParameters?.DocumentTemplate)
      }
    }, (message: string) => {
      //oast.error(message);
    });
  }

  const handleFileDownload = () => {
    const a = document.createElement('a')
    a.download = downloadTemplate?.name
    a.href = `data:application/${downloadTemplate?.fileExtention};base64,${downloadTemplate?.fileContent}`
    a.click()
  }

  const viewFile = (data: any) => {
    console.log(data)
    // const docs = [{uri: window.location.origin+"/"+data?.FileName}]
    // const docs = [{ uri: downloadTemplate?.link }]
    setView(true)
    const docs = [{ uri: "https://docs.google.com/spreadsheets/d/1JlbQBrt9y04HO6FnnZmJEipALcxpBQsUbpkkCInPoZk/edit#gid=0" }]
    // return <DocViewer documents={docs} />
  }

  const handleClose = () => {
    setView(false)
  }

  const onSubmit = (data?: any) => {
    console.log("called", uploadTypeValue, files)
    if (uploadTypeValue && uploadTypeValue != undefined && files.length > 0) {
      showLoader()
      commonApi.post({
        "entityName": "DataImportDocLib",
        "requestName": "UpsertRecordReq",
        "inputParamters":
        {
          "Entity": {
            "Name": files[0].FileName,
            "FileExtention": files[0].FileExtention,
            "RegardingId": user?.supplierId,
            "RegardingIdObjectTypeCode": "Supplier",
            "DataImportTemplateId": uploadTypeValue?.DataImportTemplateId,
            "ImportEntityId": uploadTypeValue?.ImportEntityId,
            "FileContent": files[0].FileContent
          }
        }
      }, (message: string, resp: any) => {
        console.log(resp)
        toast.success("You will be notified once the data has been succesfully uploaded")
        hideLoader()
        navigate(`/`)
      }, (message: string) => {
        toast.error(message);
        hideLoader()
      });
    } else {
      if (!uploadTypeValue || uploadTypeValue == undefined) {
        toast.error('Kindly select upload type')
      } else if (files.length == 0) {
        toast.error('Kindly upload the file')
      }
    }
  }

  useEffect(() => {
    let _files: any = [...files];
    if (documents?.length) {
      for (let i = 0; i < documents?.length; i++) {
        let file: any = documents[i];
        checkIsSelected(file.name).then(() => {
          if (file.size <= 2 * 1024 * 1024) {
            toBase64(file).then((b64: any) => {
              _files.push({
                "FileName": file.name,
                "FileExtention": file.name.split('.').pop(),
                "DocumentTypeId": docType,
                "FileContent": b64.split(',')[1]
              });
              // if (_files.length == documents?.length) {
              console.log('files=>', _files);
              setFiles(_files)
              // }
            })
          } else {
            toast.error("File size should be less 2MB")
          }
        }).catch(() => { console.log('already selected') })
      }
    } else {
      console.log('files=>', _files);
      setFiles(_files)
    }
    reset({
      documents: null
    })
  }, [documents])

  useEffect(() => {
    getDocTypes()
    getImportType()
  }, [])

  useEffect(() => {
    // console.log(downloadType)
    if (downloadType && downloadType != undefined) {
      downLoadTemplate(downloadType?.value)
      setDwnldBtnAction(false)
    } else {
      setDwnldBtnAction(true)
    }
  }, [downloadType])

  useEffect(() => {
    // console.log(downloadType)
    if (uploadType && uploadType != undefined) {
      setUploadTypeValue(uploadType)
    }
  }, [uploadType])


  return (
    <React.Fragment>
      <div className="dashboard">
        <Header />
        <section className="container dashboard-view">
          < Breadcrumbs data={[
            { label: 'Home', path: '/' },
            { label: 'Upload', path: '/institution' },
            { label: 'Bulk Upload' }
          ]} />

          <div className="container dashboard-view">
            <div className="container dashboard-view">
              <div className="white-block p-3 white-box-wide institution-wrapper">
                <Row>
                  <Col>
                    <h5>Upload Residences</h5>
                  </Col>
                  <Col className='text-end'>
                    <span>
                      <Button className="icon-btn"><MdAddCircle /></Button>
                    </span>
                  </Col>
                </Row>
                <hr></hr>
                <div className="institution-upload-wrapper">
                  <h2>Import data from excel file</h2>
                  <h3>This tool allows you to import (or merge) student data from a CSV or Excel file</h3>
                  <Row className="pt-4">
                    <Col sm={6}>
                      <fieldset className={"input" + (errors?.DialCode ? ' invalid' : '')}>
                        <legend>Select Template Type</legend>
                        <Controller
                          name={"downloadType"}
                          control={control}
                          rules={{ required: false }}
                          render={({ field: { value, onChange, onBlur } }) => {
                            return (<Select
                              // components={{ Option: dialCodeOptionUI }}
                              className={"select-box"}
                              options={templateType ? templateType : []}
                              placeholder={""}
                              onChange={onChange}
                              value={value}
                              defaultValue={value}
                            />)
                          }}
                        />
                      </fieldset>
                    </Col>
                    <Col sm={6}>
                      <Button className='mt-2' onClick={handleFileDownload} disabled={dwnldBtnAction}>
                        <span><FaFileDownload />{" "}Download</span>
                      </Button>
                    </Col>
                  </Row>
                  <hr></hr>
                  {/* <form onSubmit={handleSubmit(onSubmit)}> */}
                  <Row className="pt-4">
                    <Col>
                      <Row>
                        <h3>Choose an Excel file from your computer:</h3>
                      </Row>
                      <Row>
                        <fieldset className={"input" + (errors?.DialCode ? ' invalid' : '')}>
                          <legend>Upload Type</legend>
                          <Controller
                            name={"uploadType"}
                            control={control}
                            rules={{ required: false }}
                            render={({ field: { value, onChange, onBlur } }) => {
                              return (<Select
                                // components={{ Option: dialCodeOptionUI }}
                                className={"select-box"}
                                options={templateType ? templateType : []}
                                placeholder={""}
                                onChange={onChange}
                                value={value}
                                defaultValue={value}
                              />)
                            }}
                          />
                        </fieldset>
                      </Row>
                    </Col>
                    <Col>
                      <label className="pb-1">Attachments</label>
                      <div className="upload-btn-wrapper">
                        <button className="btn">
                          <img src="/assets/img/plus.png" alt="upload" />
                          <br />
                          Add new documents
                        </button>
                        <input type="file" multiple accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" disabled={files.length > 0} {...register('documents', { required: false })} />
                      </div>
                      {files && files.length > 0 ?
                        files.map((file: any, index: number) => (
                          <div className="uploaded-file-name mb-2" key={index}>
                            <p onClick={() => viewFile(file)}>{file?.FileName}</p>
                            <i className="fa-solid fa-trash" style={{ cursor: "pointer" }} onClick={() => { deleteLocal(file) }}></i>
                          </div>
                        ))
                        : null
                      }
                      <label className="pt-2">maximum 300MB</label>
                    </Col>
                  </Row>

                  <Row className="pt-5">
                    <Col>
                      <h3>Update existing records</h3>
                    </Col>
                    <Col>
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                        <label className="form-check-label">
                          Existing records that match by ID or Student number will be updated, those that do not will be skipped
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <hr></hr>
                  <div className="text-end">
                    <button className="btn btn-primary w-50 mt-2" type='submit' onClick={onSubmit}>Next</button>
                  </div>
                  {/* </form> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* {view ?
      <DocViewer pluginRenderers={DocViewerRenderers} documents={[{ uri: "https://docs.google.com/spreadsheets/d/1JlbQBrt9y04HO6FnnZmJEipALcxpBQsUbpkkCInPoZk/edit#gid=0" }]} />: null} */}
      {/* <ViewModal shouldShow={view} handleClose={handleClose} data={files[0]} /> */}
    </React.Fragment>
  )
}

export default InstitutionUpload