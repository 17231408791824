import React, { useEffect, useState } from 'react'
import { Row, Tabs, Col, Button, Carousel } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook'
import { useUserSelector } from '../../_common/hooks/selectors/userSelector'
import Breadcrumbs from './components/breadcrumbs'
import Header from './components/header'
import '../../styles/student/mycases.scss';
import CreateCaseModal from './components/createCaseModal'
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook'
import parse from 'html-react-parser'

const MyCases = () => {

    const user: any = useUserSelector()
    const commonApi = useCommonApi()
    const navigate = useNavigate()
    const { showLoader, hideLoader } = useAppLoader()

    const [caseStatus, setCaseStatus] = useState<any>(null)
    const [resolutionStatus, setResolutionStatus] = useState<any>(null)
    const [show, setShow] = useState<boolean>(false)
    const [openCases, setOpenCases] = useState<any>(null)
    const [closeCases, setCloseCases] = useState<any>(null)

    const getCasesByStudent = () => {
        showLoader()
        commonApi.post({
            "recordId": user?.recordId,
            "requestName": "RetrieveCasesByStudentReq"
        }, (message: string, resp: any) => {
            // console.log({ cases: resp.outputParameters })
            if (resp.outputParameters) {
                setOpenCases(resp.outputParameters.OpenCases)
                setCloseCases(resp.outputParameters.ClosedCases)
            }
            hideLoader()
        }, (message: string) => {
            console.log(message)
            hideLoader()
            //toast.error(message);
        });
    }

    const getCasesStatus = () => {
        commonApi.post({
            "entityName": "Picklist",
            "requestName": "RetrieveSelectOptionExecuteRequest",
            "inputParamters":
            {
                "SelectOption": {
                    "OptionText": "CaseStatus"

                }
            }
        }, (message: string, resp: any) => {
            if (resp.outputParameters.Count > 0) {
                setCaseStatus(resp.outputParameters.data)
            }
        }, (message: string) => {
            console.log(message)
            //toast.error(message);
        });
    }

    const getResolutionStatus = () => {
        commonApi.post({
            "entityName": "Picklist",
            "requestName": "RetrieveSelectOptionExecuteRequest",
            "inputParamters":
            {
                "SelectOption": {
                    "OptionText": "ResolutionStatus"

                }
            }
        }, (message: string, resp: any) => {
            // console.log({ resolutionStatus: resp.outputParameters })
            if (resp.outputParameters.Count > 0) {
                setResolutionStatus(resp.outputParameters.data.map((data: any) => {
                    data.label = data.optionText;
                    data.value = data.optionValue;
                    return data;
                }))
            }
        }, (message: string) => {
            console.log(message)
            //toast.error(message);
        });
    }

    const handleClose = () => {
        setShow(false)
    }

    const checkImageBase64Code = (url: any) => {
        if (url.search('data:image/') == 0) {
            return url;
        } else {

            return 'data:image/jpeg;base64,' + url
        }
    }

    const setImage = (images?: any) => {
        sessionStorage.setItem("detailsImage", JSON.stringify(images))
    }

    useEffect(() => {
        // getCasesByStudent()
        getCasesStatus()
        getResolutionStatus()
    }, [])

    useEffect(() => {
        if (user) {
            getCasesByStudent()
        }
    }, [user])

    return (
        <React.Fragment>
            <div className="dashboard my-cases-wrapper" style={{ paddingTop: "0px" }}>
                <Header />

                <div className="container">
                    <Breadcrumbs data={[
                        { label: "Home", path: "/student" },
                        // { label: "My Residence", path: "/student/my-residences" },
                        { label: "My Cases" }
                    ]} />
                    <h1 className="mb-4">My Cases</h1>
                    <h2>Current term</h2>
                    <hr></hr>
                    <h3>Open Cases</h3>
                    <Row>
                        <Col sm={8}>
                            <div className="cases-panel">
                                <div className="row open-case-block-wrapper">
                                    {openCases && openCases.length > 0 ?
                                        openCases.map((openCase: any, i: number) => (
                                            <div className="col-sm-6" key={i}>
                                                <div className="open-case-block2">
                                                    <div className="open-case-pic">
                                                        {openCase?.images && openCase?.images.length > 0 ?
                                                            <Carousel>
                                                                {openCase?.images.map((item: any, i: number) => (
                                                                    <Carousel.Item key={i}>
                                                                        <img
                                                                            src={checkImageBase64Code(item?.fileContent)}
                                                                            alt="img"
                                                                        />
                                                                    </Carousel.Item>
                                                                ))}
                                                            </Carousel>
                                                            :
                                                            <img src="/assets/img/no-image-available.jpg" alt="img" />
                                                        }
                                                    </div>
                                                    <h3>{openCase?.facilityName}</h3>
                                                    <div className="open-case-feature-block-wrapper">
                                                        <div className="open-case-feature-block">
                                                            <h5>Tenant</h5>
                                                            <p>{openCase?.tenantIdName}</p>
                                                        </div>
                                                        <div className="open-case-feature-block">
                                                            <h5>Description</h5>
                                                            {openCase.description ? parse(openCase.description) : null}
                                                        </div>
                                                        <div className="open-case-feature-block">
                                                            <h5>Status</h5>
                                                            <p>{caseStatus && caseStatus.length > 0 ?
                                                                caseStatus.map((item: any) => (
                                                                    item.optionValue == openCase.casesStatusId ? item.optionText : ""
                                                                )) : null}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <Button onClick={() => { navigate(`/student/my-cases-details/${openCase?.casesId}`); setImage(openCase?.images) }} className="btn btn-primary w-100">Case Detail</Button>
                                                </div>
                                            </div>
                                        ))
                                        : null
                                    }
                                </div>
                                <div className="row open-case-block-wrapper">
                                    <div className="col-sm-12">
                                        <h3>Resolved Cases</h3>
                                        <hr></hr>
                                    </div>
                                    {closeCases && closeCases.length > 0 ?
                                        closeCases.map((closeCase: any, i: number) => (
                                            <div className="col-sm-6" key={i}>
                                                <div className="open-case-block2">
                                                    <div className="open-case-pic">
                                                        {closeCase?.images && closeCase?.images.length > 0 ?
                                                            <Carousel>
                                                                {closeCase?.images.map((item: any, i: number) => (
                                                                    <Carousel.Item key={i}>
                                                                        <img
                                                                            src={checkImageBase64Code(item?.fileContent)}
                                                                            alt="img"
                                                                        />
                                                                    </Carousel.Item>
                                                                ))}
                                                            </Carousel>
                                                            :
                                                            <img src="/assets/img/no-image-available.jpg" alt="img" />
                                                        }
                                                    </div>
                                                    <h3>{closeCase?.facilityName}</h3>
                                                    <div className="open-case-feature-block-wrapper">
                                                        <div className="open-case-feature-block">
                                                            <h5>Tenant</h5>
                                                            <p>{closeCase?.tenantIdName}</p>
                                                        </div>
                                                        <div className="open-case-feature-block">
                                                            <h5>Description</h5>
                                                            {closeCase.description ? parse(closeCase.description) : null}
                                                        </div>
                                                        <div className="open-case-feature-block">
                                                            <h5>Status</h5>
                                                            <p>{caseStatus && caseStatus.length > 0 ?
                                                                caseStatus.map((item: any) => (
                                                                    item.optionValue == closeCase.casesStatusId ? item.optionText : ""
                                                                )) : null}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                        : null
                                    }
                                </div>
                            </div>
                        </Col>
                        <Col sm={4}>
                            <div className="card p-3 important-note-panel">
                                <h2>Some important things to note:</h2>
                                <ol>
                                    <li>Your case will be attended to within the SLA</li>
                                    <li>Please ensure that the technician assigned to your case has access to make the necessary repairs.</li>
                                    <li>Do not log another case if one has been opened already, no matter how urgent it may be.</li>
                                </ol>
                                <Button className="btn btn-primary" onClick={() => setShow(true)}>Log a new case</Button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <CreateCaseModal shouldShow={show} handleClose={handleClose} getCasesByStudent={getCasesByStudent} />
        </React.Fragment>
    )
}

export default MyCases