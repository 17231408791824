import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import { useUserSelector } from "../_common/hooks/selectors/userSelector";
import Select, { components } from 'react-select';
import { useForm, Controller } from "react-hook-form";
import { CheckNumber } from "../_common/functions";
import Header from '../components/header';
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import toast from "react-hot-toast";
import { useResidenceApi } from "../_common/hooks/api/residenceApiHook";
import { URLS } from "../_config";
import LeftPanel from "../components/leftPanel";

export default function AddResidence() {

    const user: any = useUserSelector()
    const commonApi = useCommonApi();
    const residenceApi = useResidenceApi();
    const navigate: any = useNavigate();
    const { showLoader, hideLoader } = useAppLoader();
    const [provinces, setProvinces] = useState<any>(null);
    const [institutions, setInstitutions] = useState<any>(null);
    const [campuses, setCampuses] = useState<any>(null);
    const [setPrevCampus, set_setPrevCampus] = useState<any>(null);

    const { register, handleSubmit, control, formState: { errors, isValid }, getValues, watch, reset } = useForm({ mode: "onChange" });

    const institution = watch('institution');
    // console.log("top------->",institution)


    useEffect(() => {
        if (institution) {
            // console.log("called");
            getCampus()
        }
    }, [institution])

    useEffect(() => {
        if (campuses) {
            if (setPrevCampus) {
                let opt: any = getSelectDataById(setPrevCampus, campuses);
                set_setPrevCampus(null)
                // console.log(opt)
                // console.log({...getValues(), ...{campus: opt}})
                reset({
                    ...getValues(), campus: opt
                })
            }
        }
    }, [campuses])



    const getProvinces = () => {
        commonApi.post({
            "entityName": "Picklist",
            "requestName": "RetrieveSelectOptionExecuteRequest",
            "inputParamters":
            {
                "SelectOption": {
                    "OptionText": "Province"

                }
            }
        }, (message: string, resp: any) => {
            if (resp.outputParameters.Count > 0) {
                setProvinces(resp.outputParameters.data.map((data: any) => {
                    data.label = data.optionText;
                    data.value = data.optionValue;
                    return data;
                }))
            }
        }, (message: string) => {
            // console.log(message);
        });
    }

    const getInstitutions = () => {
        commonApi.getInstitutions({}, (message: string, resp: any) => {

            let options: any = [];
            resp.map((ins: any) => {
                if (ins.recordId.search('000000') !== 0) {
                    ins.value = ins.recordId;
                    ins.label = ins.mainText;
                    options.push(ins);
                }
            })
            setInstitutions(options)
        }, (message: string) => {
            // console.log(message);
        });
    }

    const getCampus = (id: any = institution?.value) => {
        return new Promise((resolve: any, reject: any) => {
            commonApi.getCampus({
                filters: "InstitutionId eq '" + id + "'",
                ViewName: 'LookUpView'
            }, (message: string, resp: any) => {
                let options: any = [];
                resp.map((ins: any) => {
                    if (ins.recordId.search('000000') !== 0) {
                        ins.value = ins.recordId;
                        ins.label = ins.mainText;
                        options.push(ins);
                    }
                })
                setCampuses(options)
                setTimeout(() => {
                    resolve()
                }, 300)
            }, (message: string) => {
                // console.log(message);
                reject()
            });
        })
    }

    const onSubmit = (data: any) => {
        let params: any = {
            "Name": data.Name,
            "ProvinceId": data.Province.value,
            "InstitutionId": data.institution.value,
            "CampusId": data.campus.value,
            "KMToCampus": data.KMToCampus,
            "AccomodationProviderId": user?.supplierId,
            "Address": data.Address,
            "FacilityStatusId": 872
        }
        let residenceId = sessionStorage.getItem('residenceID');
        if (residenceId) {
            // console.log("if")
            showLoader();
            commonApi.post({
                "entityName": "Facility",
                "requestName": "UpsertRecordReq",
                "recordId": residenceId,
                "inputParamters": { "Entity": params }
            }, (message: string, resp: any) => {
                hideLoader();
                sessionStorage.setItem('residenceID', resp?.outputParameters?.UpsertResponse.recordId);
                sessionStorage.setItem('residenceName', data?.Name);
                getResidenceRooms(residenceId);
                // navigate('/add-room')
            }, (message: string) => {
                hideLoader();
                toast.error(message);
            });
        } else {
            // console.log("else")
            showLoader();
            commonApi.post({
                "entityName": "Facility",
                "requestName": "UpsertRecordReq",
                "inputParamters": { "Entity": params }
            }, (message: string, resp: any) => {
                hideLoader();
                sessionStorage.setItem('residenceID', resp.outputParameters.UpsertResponse.recordId);
                sessionStorage.setItem('residenceName', data.Name);
                navigate('/add-room')
            }, (message: string) => {
                hideLoader();
                toast.error(message);
            });
        }
    }

    const getPrevData = () => {
        let residenceId = sessionStorage.getItem('residenceID');
        if (residenceId) {
            showLoader();
            // console.log("residenceID: " + residenceId);
            //call api reset form values via reset()
            commonApi.retreive({
                "Entity": "Facility",
                "Top": 100,
                "ReturnCols": ["FacilityId", "Name", "ProvinceId", "InstitutionId", "CampusId", "KMToCampus", "Address"],
                "FilterOn": {
                    "Type": "And",
                    "Where": [{
                        "filterCol": "FacilityId", "FilterCondition": "Equals",
                        "FilterValue": residenceId
                    }]
                }
            }, (message: string, resp: any) => {
                hideLoader();
                let prevData = JSON.parse(resp)[0];
                set_setPrevCampus(prevData.CampusId);
                reset({
                    Name: prevData.Name,
                    Province: getSelectDataById(prevData.ProvinceId, provinces),
                    institution: getSelectDataById(prevData.InstitutionId, institutions),
                    // campus: getSelectDataById(prevData.CampusId, campuses),
                    KMToCampus: prevData.KMToCampus,
                    Address: prevData.Address
                })
            }, (message: string) => {
                hideLoader();
                toast.error(message);
            });
        } else {
            console.log("Start A New Residence")
        }
    }

    const getSelectDataById = (id: any, data: any) => {
        let option = null
        data?.map((_option: any) => {
            if (_option.value == id) {
                option = _option
            }
        })
        return option
    }

    const getResidenceRooms = (residenceId: any) => {
        residenceApi.getResidenceRooms({
            $filter: "FacilityId eq '" + residenceId + "'",
            "entity": "Capacity",
            "$select": 'CapacityId, Name, Quantity, GenderId, PricePerBed, DisabilityAccessId, CateringId',
        }, (message: string, resp: any) => {
            if (resp[0].data && resp[0].data.length > 0) {
                navigate(URLS.RESIDENCE_SUMMERY)
            } else {
                navigate(URLS.ADD_ROOM)
            }
            // console.log('rooms=>', resp[0].data)
        }, (message: string) => {
            // console.log(message);
        });
    }

    useEffect(() => {
        getProvinces();
        getInstitutions();
    }, [])

    useEffect(() => {
        if (provinces && institutions) {
            getPrevData()
        }
    }, [provinces, institutions])

    return (
        <React.Fragment>
            <div className="dashboard">
                <Header />
                <section className="container">
                    <div className="custome-container-inner d-flex">
                        <LeftPanel page={1} />
                        <div className="white-block p-3 white-box-wide">
                            <div className="row">
                                <div className="col">
                                    <h1>Add residence</h1>
                                </div>
                            </div>
                            <hr />
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="tab_scrollcontent">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="mb-2">
                                                <label htmlFor="exampleFormControlInput1" className="form-label">Name</label>
                                                <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="" {...register("Name", { required: true, maxLength: 80 })} />
                                            </div>

                                            <div className="mb-2">
                                                <label className="form-label">Province</label>
                                                <Controller
                                                    name={"Province"}
                                                    control={control}
                                                    rules={{ required: true }}
                                                    render={({ field: { value, onChange, onBlur } }) => {
                                                        return (<Select
                                                            className={"form-control p-0" + (errors?.Province ? ' is-invalid' : '')}
                                                            options={provinces}
                                                            placeholder={""}
                                                            onChange={onChange}
                                                            value={value}
                                                            defaultValue={value}
                                                        />)
                                                    }}
                                                />
                                            </div>

                                            <div className="mb-2">
                                                <label className="form-label">Target institution</label>
                                                <Controller
                                                    name={"institution"}
                                                    control={control}
                                                    rules={{ required: true }}
                                                    render={({ field: { value, onChange, onBlur } }) => {
                                                        return (<Select
                                                            className={"form-control p-0" + (errors?.institution ? ' is-invalid' : '')}
                                                            options={institutions}
                                                            placeholder={""}
                                                            onChange={onChange}
                                                            value={value}
                                                            defaultValue={value}
                                                        />)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="mb-2">
                                                <label className="form-label">Target campus</label>
                                                <Controller
                                                    name={"campus"}
                                                    control={control}
                                                    rules={{ required: true }}
                                                    render={({ field: { value, onChange, onBlur } }) => {
                                                        return (<Select
                                                            className={"form-control p-0" + (errors?.campus ? ' is-invalid' : '')}
                                                            options={campuses}
                                                            placeholder={""}
                                                            onChange={onChange}
                                                            value={value}
                                                            defaultValue={value}
                                                        />)
                                                    }}
                                                />
                                            </div>

                                            <div className="mb-2">
                                                <label htmlFor="exampleFormControlInput5" className="form-label">KM from campus</label>
                                                <input type="text" className="form-control" id="exampleFormControlInput5" placeholder=""  {...register("KMToCampus", { required: true })} onChange={CheckNumber} />
                                            </div>
                                            <div className="mb-2">
                                                <label htmlFor="exampleFormControlInput6" className="form-label">Address</label>
                                                <input type="text" className="form-control" id="exampleFormControlInput6" placeholder=""  {...register("Address", { required: true })} />
                                            </div>
                                        </div>
                                    </div>



                                </div>
                                <div className="mobbottom_fixbtn next-back-btn">
                                    <button type="submit" className="btn btn-primary w-100 mt-2 next-btn" disabled={!isValid} data-bs-toggle="button" >Next</button>
                                    <Link to="/dashboard" className="back-btn">
                                        <button type="button" className="btn btn-outline-secondary mt-2 w-100" data-bs-toggle="button" >Back</button>
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </React.Fragment>
    );
}