import React from "react";
import { Button } from 'react-bootstrap';
import IntroSlider from '../components/introSlider';
import { useNavigate } from 'react-router-dom';

export default function RegistrationSuccessful() {
	const navigate = useNavigate();

	return (
		<React.Fragment>
			<div className="register_page login_page">
				<div className="register_left">
					<div className="mob_logo">
						<a href="#" className="logo_left"><img src="/assets/img/logo.png" /></a>
					</div>
					<div className="logo_txt">
						Registration successful.
					</div>
					<p className="text-center">You profile has been successfully created
						and an email has been sent to you
						mailbox with more details.
						<br/><br/>
						Please familiarise yourself with the NSFAS
						Accreditation Guidelines before adding a
						property to you profile.
						<br/><br/>
						You will be charged a once off
						registration fee that will be payable when
						you request a inspection of your first
						student residence property.</p>
					<div className="mobbottom_fixbtn">
						<Button variant="primary" className="w-50 mt-2" type="button" onClick={() => navigate('/login')}>Login</Button>
					</div>
				</div>
				<IntroSlider />
			</div>
		</React.Fragment>
	);
}