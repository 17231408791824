import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { useCommonApi } from '../_common/hooks/api/common/commonApiHook';
import { useAppLoader } from '../_common/hooks/common/appLoaderHook';
import { URLS } from '../_config';

interface OpenCaseCloseModalProps {
  handleClose: () => void;
  shouldShow: boolean;
  id: any;
  path: string
}

const OpenCaseCloseModal = ({ shouldShow, handleClose, id, path }: OpenCaseCloseModalProps) => {

  const commonApi = useCommonApi();
  const navigate = useNavigate()
  const { showLoader, hideLoader } = useAppLoader();

  const [resolutionStatus, setResolutionStatus] = useState<any>(null);
  const [files, setFiles] = useState<any>([]);
  const [docType, setDocType] = useState<any>(null);

  const { register, handleSubmit, control, formState: { errors, isValid }, watch, reset } = useForm({ mode: "onChange" });

  const documents = watch('documents');
  // console.log({ errors })
  const toBase64 = (file: any) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  useEffect(() => {console.log({documents})
    let _files: any = [...files];
    if (documents?.length) {
      for (let i = 0; i < documents?.length; i++) {
        let file: any = documents[i];
        checkIsSelected(file.name).then(() => {
          if (file.size <= 2 * 1024 * 1024) {
            toBase64(file).then((b64: any) => {
              _files.push({
                "FileName": file.name,
                "FileExtention": file.name.split('.').pop(),
                "DocumentTypeId": docType,
                "FileContent": b64.split(',')[1]
              });
              // if (_files.length == documents?.length) {
              console.log('files=>', _files);
              setFiles(_files)
              // }
            })
          } else {
            toast.error("File size should be less 2MB")
          }
        }).catch(() => { console.log('already selected') })
      }
    } else {
      console.log('files=>', _files);
      setFiles(_files)
    }
    reset({
      documents: null
    })
  }, [documents])

  const checkIsSelected = (name: string) => {
    console.log('checking.................', files)
    return new Promise((resolve: any, reject: any) => {
      files.map((file: any) => {
        if (file.FileName == name) {
          reject()
        }
      });
      resolve()
    })
  }

  const getDocTypes = () => {
    commonApi.post({
      "entityName": "Picklist",
      "requestName": "RetrieveSelectOptionExecuteRequest",
      "inputParamters":
      {
        "SelectOption": {
          "OptionText": "DocTypes"

        }
      }
    }, (message: string, resp: any) => {
      resp.outputParameters.data.map((type: any) => {
        if (type.optionText == 'AP Documents') {
          setDocType(type.optionValue);
          console.log(type.optionValue)
        }
      })
    }, (message: string) => {
      //toast.error(message);
    });
  }

  const getResolutionStatusList = () => {
    commonApi.post({
      "entityName": "Picklist",
      "requestName": "RetrieveSelectOptionExecuteRequest",
      "inputParamters":
      {
        "SelectOption": {
          "OptionText": "CaseStatus"
        }
      }
    }, (message: string, resp: any) => {
      console.log({ resp })
      if (resp.outputParameters.Count > 0) {
        let options: any = [];
        resp.outputParameters.data.map((ins: any) => {
          if (ins.optionValue == 311 || ins.optionValue == 312) {
            ins.value = ins.optionValue;
            ins.label = ins.optionText;
            options.push(ins);
          }
        })
        setResolutionStatus(options)
      }
    }, (message: string) => {
      console.log(message);
    });
  }

  const onSubmit = (data: any) => {
    // console.log("called", { data, files });
    showLoader()
    commonApi.post({
      "entityName": "Cases",
      "requestName": "UpsertRecordReq",
      "recordId": id,
      "inputParamters":
      {
        "Entity": {
          "CasesStatusId": data.Reason.value,
          "Comments": data.Description
        },
        "Documents": files
      }
    }, (message: string, resp: any) => {
      toast.success(message)
      handleClose()
      navigate(path)
      hideLoader()
      // console.log({ resp })
    }, (message: string) => {
      toast.error(message)
      handleClose()
      hideLoader()
      // console.log(message);
    });
  }

  const deleteLocal = (file: any) => {
    let _files: any = [];
    files.map((_file: any) => {
      if (_file.FileName != file.FileName) {
        _files.push(_file)
      }
    });

    setFiles(_files)
  }

  useEffect(() => {
    getResolutionStatusList();
    getDocTypes();
  }, [])

  return (
    <>
      <Modal className='opencase-modal' show={shouldShow} onHide={handleClose}>
        <Modal.Body>
          <h4>You are closing this case please select a reason below:</h4>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <label className="form-label">Reason</label>
              <Controller
                name={"Reason"}
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange, onBlur } }) => {
                  return (<Select
                    className={"form-control p-0" + (errors?.Reason ? ' is-invalid' : '')}
                    options={resolutionStatus ? resolutionStatus : []}
                    placeholder={""}
                    onChange={onChange}
                    value={value}
                    defaultValue={value}
                  />)
                }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleFormControlInput1" >Description</label>
              <textarea className="form-control" id="exampleFormControlInput1" placeholder="" {...register("Description", { required: true, maxLength: 80 })} ></textarea>
            </div>
            <div className="form-group">
              <label>Attachments</label>
              <div className="upload-btn-wrapper">
                <button className="btn">
                  <img src="/assets/img/plus.png" alt="upload" />
                  <br />
                  Add new documents
                </button>
                <input type="file" multiple accept="image/*, application/pdf" {...register('documents', { required: false })} />
              </div>
              <p>maximum 300MB</p>
              {files.map((file: any, i: any) => (
                <div className="doc-file mb-2" key={i}>
                  <FaTimes className="delete-me" onClick={() => { deleteLocal(file) }} />
                  <input type="text" className="form-control" disabled id="exampleFormControlInput3"
                    placeholder={file.FileName} />
                </div>
              ))}
            </div>
            <button type="submit" className='full-width-yellow-btn'>Close case</button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default OpenCaseCloseModal