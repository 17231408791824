import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Container, Row, Col, Button } from 'react-bootstrap';
import Select, { components } from 'react-select';
import { useForm, Controller } from "react-hook-form";
import { CheckNumber } from "../_common/functions";
import Header from '../components/header';
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import { useResidenceApi } from "../_common/hooks/api/residenceApiHook";
import { access } from "fs/promises";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import toast from "react-hot-toast";
import { URLS } from "../_config";
import LeftPanel from "../components/leftPanel";

export default function DescribeRooms() {
    const navigate = useNavigate();
    const { showLoader, hideLoader } = useAppLoader();
    const commonApi = useCommonApi();
    const residenceApi = useResidenceApi();

    const residenceID = sessionStorage.getItem('residenceID');
    const [amenities, setAmenities] = useState<any>(null);

    const [prevData, setPrevData] = useState<any>(null);
    const [prevDataChecked, setPrevDataChecked] = useState<boolean>(false);

    const options: any = [
        { label: 'None', value: 0 },
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3+', value: 3 },
    ]

    const { register, handleSubmit, control, formState: { errors, isValid }, getValues, watch, reset } = useForm({ mode: "onChange" });

    const getResidenceAmenities = () => {
        residenceApi.getResidenceAmenities({}, (message: string, resp: any) => {
            // console.log('getResidenceAmenities=>', resp);
            setAmenities(resp);
        }, (message: string) => {
            // console.log(message);
        });
    }

    const onSubmit = (data: any) => {
        console.log('Data=>', data, prevData);
        let _amenities: any = [];
        Object.keys(data).map((amenityID: any) => {
            let params: any = {
                "Quantity": data[amenityID],
                "AmenityId": amenityID,
                "FacilityId": residenceID
            }
            if (prevData) {
                params.recordId = prevData[amenityID]?.CapacityId
            }
            _amenities.push(params)
        });

        // console.log(_amenities)


        let params: any = {
            "entityName": "Capacity",
            "requestName": "AddMultipleReq",
            "inputParamters":
            {
                "Amenities": _amenities
            }
        }

        // console.log(params)
        showLoader();
        commonApi.post(params, (message: string, resp: any) => {
            hideLoader();
            navigate('/more-about-property')
        }, (message: string) => {
            hideLoader();
            toast.error(message);
        });
    }

    const getPrevData = () => {
        if (residenceID) {
            showLoader();
            //call api reset form values via reset()
            commonApi.retreive({
                "Entity": "Capacity",
                "Top": 100,
                "ReturnCols": ["CapacityId", "Quantity", "AmenityId", "AmenityIdName"],
                "FilterOn": {
                    "Type": "And",
                    "Where": [{
                        "filterCol": "FacilityId", "FilterCondition": "Equals",
                        "FilterValue": residenceID
                    }, {
                        "filterCol": "AmenityTypeId", "FilterCondition": "Equals",
                        "FilterValue": "860"
                    }]
                }
            }, (message: string, resp: any) => {
                hideLoader();
                console.log('prev-------------------', resp, JSON.parse(resp).length)
                let prevData = JSON.parse(resp);
                if (prevData.length) {
                    let resetData: any = {};
                    prevData.map((data: any) => {
                        resetData[data.AmenityId] = {
                            quantity: data.Quantity,
                            CapacityId: data.CapacityId
                        }
                    })
                    setPrevData(resetData)
                    // console.log({resetData});
                } else {
                    console.log('Describe room has no previous data')
                }
                setPrevDataChecked(true);
            }, (message: string) => {
                hideLoader();
                toast.error(message);
            });
        } else {
            console.log("Start A New Residence")
        }
    }

    useEffect(() => {
        if (residenceID == null) {
            navigate('/add-residence')
        } else {
            getResidenceAmenities();
        }
    }, [])

    useEffect(() => {
        if (amenities) {
            getPrevData()
        }
    }, [amenities])

    const getRadioStatus = (myValue: any, value: any, prev: any) => {
        console.log(myValue, value, (myValue == value))
        if (prev) {
            return myValue == value;
        } else {
            return false
        }
    }

    return (
        <React.Fragment>
            <div className="dashboard">
                <Header />
                <section className="container">
                    <div className="custome-container-inner d-flex">
                        <LeftPanel page={3} />
                        <div className="white-block p-3 white-box-wid describe-room">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row mb-4">
                                    <div className="col">
                                        <h1>Describe Your Property - Amenities</h1>
                                    </div>
                                </div>
                                {prevDataChecked ?
                                    <div className="row">
                                        {amenities?.map((amenity: any, i: any) => (
                                            <div className="col-sm-6" key={i}>
                                                <div className="mb-4">
                                                    <div className="d-flex justify-content-between">
                                                        <div className="radio-option-title"><label htmlFor="exampleFormControlInput1" className="form-label">{amenity.Name}</label></div>
                                                        <div className="radio-tooltip text-end">
                                                            <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title={amenity.ToolTip}>
                                                                <img src="/assets/img/info.png" alt="" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="row radio-options-panel">
                                                        {options.map((option: any, j: any) => (
                                                            <div className="col" key={i + "-" + j}>
                                                                <div className="form-check">
                                                                    {/* {prevData?getRadioStatus(option.value,prevData[amenity.AmenityId].quantity,true)?'true':'false':false} */}
                                                                    <input
                                                                        className="form-check-input"
                                                                        value={option.value}
                                                                        type="radio"
                                                                        defaultChecked={prevData ? getRadioStatus(option?.value, prevData[amenity?.AmenityId]?.quantity, true) : false}
                                                                        id={j + '-' + amenity.AmenityId}
                                                                        {...register(amenity.AmenityId, { required: true })}
                                                                    />
                                                                    <label className="form-check-label" htmlFor={j + '-' + amenity.AmenityId}>
                                                                        {option.label}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        ))}

                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    : null}
                                <div className="mobbottom_fixbtn next-back-btn">
                                    <button type="submit" className="btn btn-primary w-100 mt-2 next-btn" disabled={!isValid} data-bs-toggle="button" >Next</button>
                                    <Link to={URLS.RESIDENCE_SUMMERY} className="back-btn">
                                        <button type="button" className="btn btn-outline-secondary mt-2 w-100" data-bs-toggle="button" >Back</button>
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </React.Fragment>
    );
}