import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { Container, Row, Col, Button } from 'react-bootstrap';
import Select, { components } from 'react-select';
import { useForm, Controller } from "react-hook-form";
import { CheckNumber } from "../_common/functions";
import Header from '../components/header';
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import { useResidenceApi } from "../_common/hooks/api/residenceApiHook";
import { access } from "fs/promises";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import toast from "react-hot-toast";
import { URLS } from "../_config";
import LeftPanel from "../components/leftPanel";
export default function ResidenceSummery() {

    const navigate = useNavigate();
    const { showLoader, hideLoader } = useAppLoader();
    const commonApi = useCommonApi();
    const residenceApi = useResidenceApi();


    const residenceID = sessionStorage.getItem('residenceID');
    const [rooms, setRooms] = useState<any>(null);
    const [genders, setGenders] = useState<any>(null);
    const [caterings, setCaterings] = useState<any>(null);
    const [capacities, setCapacities] = useState<any>(null);
    const [accesses, setAccesses] = useState<any>(null);



    const getResidenceRooms = () => {
        residenceApi.getResidenceRooms({
            $filter: "FacilityId eq '" + residenceID + "'",
            "entity": "Capacity",
            "$select": 'CapacityId, Name, Quantity, GenderId, PricePerBed, DisabilityAccessId, CateringId, AmenityId, AmenityTypeId',
        }, (message: string, resp: any) => {
            let data: any = [];

            resp[0].data.map((room: any) => {
                if (room.AmenityTypeId == 859) {
                    data.push(room)
                }
            })
            console.log('rooms =>', data);
            setRooms(data);
        }, (message: string) => {
            console.log(message);
        });

    }

    const getCaterings = () => {
        commonApi.post({
            "entityName": "Picklist",
            "requestName": "RetrieveSelectOptionExecuteRequest",
            "inputParamters":
            {
                "SelectOption": {
                    "OptionText": "Catering"

                }
            }
        }
            , (message: string, resp: any) => {
                console.log('getCaterings=>', resp.outputParameters.data)
                setCaterings(resp.outputParameters.data.map((data: any) => {
                    data.label = data.optionText;
                    data.value = data.optionValue;
                    return data
                }))
            }, (message: string) => {
                console.log(message);
            });
    }

    const getGender = () => {
        commonApi.post({
            "entityName": "Picklist",
            "requestName": "RetrieveSelectOptionExecuteRequest",
            "inputParamters":
            {
                "SelectOption": {
                    "OptionText": "Gender"

                }
            }
        }
            , (message: string, resp: any) => {
                console.log('getGender=>', resp.outputParameters.data)
                setGenders(resp.outputParameters.data)
            }, (message: string) => {
                console.log(message);
            });
    }

    const getAccesses = () => {
        commonApi.post({
            "entityName": "Picklist",
            "requestName": "RetrieveSelectOptionExecuteRequest",
            "inputParamters":
            {
                "SelectOption": {
                    "OptionText": "YesNo"

                }
            }
        }
            , (message: string, resp: any) => {
                console.log('getAccesses=>', resp.outputParameters.data);

                setAccesses(resp.outputParameters.data.map((data: any) => {
                    data.label = data.optionText;
                    data.value = data.optionValue;
                    return data
                }))
            }, (message: string) => {
                console.log(message);
            });
    }

    const getCapacities = () => {
        commonApi.getCapacities({ ViewName: 'Room_Type_Lookup_View' }, (message: string, resp: any) => {
            console.log('capacities=>', resp);
            let opt: any = [];
            resp.map((o: any) => {
                if (o.recordId.search('000000') !== 0) {
                    o.label = o.mainText;
                    o.value = o.recordId;
                    opt.push(o)
                }
            })
            setCapacities(opt);
        }, (message: string) => {
            console.log(message);
        });
    }

    const getCateringName = (id: any) => {
        let name = '';
        caterings.map((cat: any) => {
            if (cat.value == id) {
                name = cat.label
            }
        });
        return name;
    }

    const getGenderName = (id: any) => {
        let name = '';
        genders.map((gen: any) => {
            if (gen.optionValue == id) {
                name = gen.optionText
            }
        });
        return name;
    }

    const getAccessName = (id: any) => {
        let name = '';
        accesses.map((access: any) => {
            if (access.value == id) {
                name = access.label
            }
        });
        return name;
    }

    const editRoom = (room: any) => {
        console.log(room);
        navigate('/add-room?room=' + room.CapacityId);
    }

    useEffect(() => {
        if (residenceID == null) {
            navigate('/add-residence')
        } else {
            getGender();
            getAccesses();
            getCaterings();
            getCapacities();
            getResidenceRooms();
        }
    }, [])

    return (
        <React.Fragment>
            <div className="dashboard">
                <Header />
                <section className="container">
                    <div className="custome-container-inner d-flex">
                        <LeftPanel page={2} />
                        <div className="white-block p-3 white-box-wide">
                            <div className="row">
                                <div className="col">
                                    <h1>Here's a summary of your residence's rooms</h1>
                                </div>
                            </div>
                            <hr />
                            <div className="tab_scrollcontent">
                                <div className="residence-summary-panel row">
                                    {(rooms && genders && caterings && accesses && capacities) ?
                                        <React.Fragment>
                                            {rooms.map((room: any, i: any) => (
                                                <div className="residence-summary-block mb-2 summary-block-50" key={i} onClick={() => { editRoom(room) }}>
                                                    <h4>{getGenderName(room.GenderId)} Rooms</h4>
                                                    <div className="row mb-2">
                                                        <div className="col-8">
                                                            <h5>No. of rooms</h5>
                                                        </div>
                                                        <div className="col-4 text-end">
                                                            <h6>{room.Quantity}</h6>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-8">
                                                            <h5>Price</h5>
                                                        </div>
                                                        <div className="col-4 text-end">
                                                            <h6>R{room.PricePerBed}</h6>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-8">
                                                            <h5>Catering</h5>
                                                        </div>
                                                        <div className="col-4 text-end">
                                                            <h6>{getCateringName(room.CateringId)}</h6>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-8">
                                                            <h5>Disability friendly</h5>
                                                        </div>
                                                        <div className="col-4 text-end">
                                                            <h6>{getAccessName(room.DisabilityAccessId)}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </React.Fragment>
                                        : null}

                                    <div className="residence-summary-add">
                                        <Link to="/add-room">
                                            <img src="/assets/img/plus-icon.png" />
                                            Add another room type
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="mobbottom_fixbtn next-back-btn">
                                <Link to="/describe-rooms" className="next-btn">
                                    <button type="button" className="btn btn-primary w-100 mt-2" data-bs-toggle="button" >Next</button>
                                </Link>
                                <Link to={URLS.ADD_RESIDENCE} className="back-btn">
                                    <button type="button" className="btn btn-outline-secondary mt-2 w-100" data-bs-toggle="button" >Back</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </React.Fragment>
    );
}