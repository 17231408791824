import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import Header from '../components/header'
import { store } from '../store';
import { useCommonApi } from '../_common/hooks/api/common/commonApiHook';
import { useAppLoader } from '../_common/hooks/common/appLoaderHook';
import { useUserSelector } from '../_common/hooks/selectors/userSelector';
import { ACTIONS, STORAGE, URLS } from '../_config';

const UpdateProfile = () => {


    const { register, handleSubmit, formState: { errors, isDirty }, reset, control } = useForm();

    const user: any = useUserSelector();
    const commonApi = useCommonApi();
    const navigate = useNavigate();
    const { showLoader, hideLoader } = useAppLoader();

    const [userDetails, setUserDetails] = useState<any>(user)
    const [entityProviderData, setEntityProviderData] = useState<any>(null);
    const [individualProviderData, setIndividualProviderData] = useState<any>(null);
    const [addressData, setAddressData] = useState<any>(null);
    const [tab, setTab] = useState<any>(null);
    const [step, setStep] = useState<number>(1)
    const [addressId, setAddressId] = useState<any>(null)
    const [provinces, setProvinces] = useState<any>(null)

    const onSubmitEntityProviderForm = (data?: any) => {
        if (data) {
            setEntityProviderData(data);
        }
        setStep(2)
    }

    const onSubmitIndividualProviderForm = (data?: any) => {
        if (data) {
            setIndividualProviderData(data);
        }
        setStep(2)
    }

    const onSubmitAddressForm = (data?: any) => {
        if (data) {
            setAddressData(data);
        } else {
            navigate(URLS.HOME)
        }
    }

    const updateIndividual = () => {
        let params: any = {
            "entityName": "Supplier",
            "recordId": user?.supplierId,
            "requestName": "UpsertRecordReq",
            "inputParamters":
            {
                "Entity": individualProviderData
            }
        }
        showLoader();
        commonApi.post(params, (message: string, resp: any) => {
            hideLoader();
            toast.success(message);
            getUserDetails();
        }, (message: string) => {
            hideLoader();
            toast.error(message);
        });
    }

    const updateEntity = () => {
        let params: any = {
            "entityName": "Supplier",
            "recordId": user?.supplierId,
            "requestName": "UpsertRecordReq",
            "inputParamters":
            {
                "Entity": entityProviderData
            }
        }
        showLoader();
        commonApi.post(params, (message: string, resp: any) => {
            hideLoader();
            toast.success(message);
            getUserDetails();
        }, (message: string) => {
            hideLoader();
            toast.error(message);
        });
    }

    const updateAddress = () => {
        let params: any = {
            "entityName": "Address",
            "requestName": "UpsertRecordReq",
            "inputParamters": { "Entity": addressData }
        }

        if (addressId) {
            params.recordId = addressId;
        } else {
            params.inputParamters = {
                "Entity": {
                    ...addressData, ...{
                        "RelatedObjectId": user.supplierId,
                        "RelatedObjectIdTypeCode": "Supplier"
                    }
                }
            }
        }
        showLoader();
        commonApi.post(params, (message: string, resp: any) => {
            hideLoader();
            toast.success(message);
            navigate(URLS.HOME)
        }, (message: string) => {
            hideLoader();
            toast.error(message);
        });
    }

    const getUserDetails = () => {
        showLoader();
        commonApi.retreive({
            "Entity": "Supplier",
            "Top": 100,
            "ReturnCols": ["APtypeId", "FirstName", "LastName", "Name", "RegistrationName", "RegistrationNo", "VATNumber", "Mobile", "Telephone", "Email"],
            "FilterOn": {
                "Type": "And",
                "Where": [{
                    "filterCol": "SupplierId", "FilterCondition": "Equals",
                    "FilterValue": user.supplierId
                }]
            }
        }, (message: string, resp: any) => {
            hideLoader();
            let userNewData: any = JSON.parse(resp)[0];
            console.log('userNewData =>', userNewData);
            let margedUserData: any = { ...user, ...userNewData };
            console.log('margedUserData =>', margedUserData);
            sessionStorage.setItem(STORAGE, JSON.stringify(margedUserData));
            store.dispatch({
                type: ACTIONS.USER.LOGIN,
                payload: {
                    user: JSON.parse(margedUserData),
                    token: null
                }
            });

            setUserDetails(margedUserData)

        }, (message: string) => {
            hideLoader();
        });
    }

    const getProvinces = () => {
        commonApi.post({
            "entityName": "Picklist",
            "requestName": "RetrieveSelectOptionExecuteRequest",
            "inputParamters":
            {
                "SelectOption": {
                    "OptionText": "Province"

                }
            }
        }, (message: string, resp: any) => {
            if (resp.outputParameters.Count > 0) {
                setProvinces(resp.outputParameters.data.map((data: any) => {
                    data.label = data.optionText;
                    data.value = data.optionValue;
                    return data;
                }))
            }
        }, (message: string) => {
        });
    }

    const getPrevAddressData = () => {
        if (userDetails?.supplierId) {
            showLoader();
            //call api reset form values via reset()
            commonApi.retreive({
                "Entity": "Address",
                "Top": 100,
                "ReturnCols": ["AddressId", "StreetNumber", "StreetName", "suburb", "City", "Province", "PostalCode"],
                "FilterOn": {
                    "Type": "And",
                    "Where": [{
                        "filterCol": "RelatedObjectId", "FilterCondition": "Equals",
                        "FilterValue": userDetails?.supplierId
                    }]
                }
            }, (message: string, resp: any) => {
                hideLoader();
                let prevData = JSON.parse(resp)[0];
                setAddressId(prevData.AddressId);
                reset({
                    StreetNumber: prevData.StreetNumber,
                    StreetName: prevData.StreetName,
                    City: prevData.City,
                    Surburb: prevData.suburb,
                    PostalCode: prevData.PostalCode,
                    Province: getSelectDataById(prevData.Province, provinces)
                })
            }, (message: string) => {
                hideLoader();
                //toast.error(message);
            });
        } else {
        }
    }

    const getSelectDataById = (id: any, data: any) => {
        let option = null
        data?.map((_option: any) => {
            if (_option.value == id) {
                option = _option
            }
        })
        return option
    }

    const onSubmit = (data: any) => {
        console.log({ data })
        if (tab == 864) {
            let indvData = {
                "FirstName": data?.FirstName,
                "LastName": data?.LastName,
                "IDNumber": data?.IDNumber,
                "Mobile": data?.Mobile,
                "Telephone": data?.Telephone,
                "Email": data?.Email
            }
            onSubmitIndividualProviderForm(indvData)
        } else if (tab == 865) {
            let entityData = {
                "TradingName": data?.TradingName,
                "RegistrationName": data?.RegistrationName,
                "IDNumber": data?.IDNumber,
                "VATNumber": data?.VATNumber,
                "Mobile": data?.Mobile,
                "Telephone": data?.Telephone,
                "Email": data?.Email
            }
            onSubmitEntityProviderForm(entityData)
        }
        
        let addressData = {
            "StreetNumber": data.StreetNumber,
            "StreetName": data.StreetName,
            "suburb": data.Surburb,
            "City": data.City,
            "Province": data.Province.value,
            "PostalCode": data.PostalCode
        }
        onSubmitAddressForm(addressData)

    }

    useEffect(() => {
        if (user) {
            setUserDetails(user)
            setTab(user?.aPtypeId)
        }
    }, [user])

    useEffect(() => {
        if (individualProviderData) {
            if (tab == 864) {
                updateIndividual();
            }
        }
    }, [individualProviderData])

    useEffect(() => {
        if (entityProviderData) {
            if (tab == 865) {
                updateEntity();
            }
        }
    }, [entityProviderData])

    useEffect(() => {
        if (addressData) {
            updateAddress();
        }
    }, [addressData, addressId])

    useEffect(() => {
        console.log(userDetails, tab)
        if (userDetails && tab == 864) {
            reset({
                FirstName: (userDetails?.name).split(' ')[0],
                LastName: (userDetails?.name).split(' ')[1] ? (userDetails?.name).split(' ')[1] : "",
                IDNumber: userDetails?.idNumber,
                Mobile: userDetails?.mobile,
                Telephone: userDetails?.telephone,
                Email: userDetails?.email
            })
        } else if (userDetails && tab == 865) {
            reset({
                TradingName: userDetails?.name,
                RegistrationName: userDetails?.registrationName,
                IDNumber: userDetails?.idNumber,
                VATNumber: userDetails?.vatNumber,
                Mobile: userDetails?.mobile,
                Telephone: userDetails?.telephone,
                Email: userDetails?.email
            })
        }

    }, [userDetails, tab])

    useEffect(() => {
        getProvinces();
    }, [])

    useEffect(() => {
        if (provinces) {
            getPrevAddressData()
        }
    }, [provinces])

    return (
        <React.Fragment>
            <div className="dashboard">
                <Header />
                <div className="view-profile-wrapper">
                    <h2>Personal information</h2>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="view-profile-wrapper-inner">

                            {/* individual */}
                            {tab == 864 ?
                                <Row>
                                    <Col sm={6}>
                                        <div className="view-profile-fld-row">
                                            <label>Name:</label>
                                            <input type="text" {...register("FirstName", { required: true, maxLength: 80 })} />
                                        </div>
                                        <div className="view-profile-fld-row">
                                            <label>Surname:</label>
                                            <input type="text" {...register("LastName", { required: true, maxLength: 80 })} />
                                        </div>
                                        <div className="view-profile-fld-row">
                                            <label>ID number:</label>
                                            <input type="text" {...register("IDNumber", { required: true, maxLength: 80 })} />
                                        </div>
                                    </Col>
                                    <Col sm={6}>
                                        <div className="view-profile-fld-row">
                                            <label>Mobile:</label>
                                            <input type="text" value="0867854432" {...register("Mobile", { required: true, maxLength: 80 })} />
                                        </div>
                                        <div className="view-profile-fld-row">
                                            <label>Tel.:</label>
                                            <input type="text" {...register("Telephone", { required: false, maxLength: 80 })} />
                                        </div>
                                        <div className="view-profile-fld-row">
                                            <label>Email:</label>
                                            <input type="text" {...register("Email", { required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} />
                                        </div>
                                    </Col>
                                </Row>
                                :
                                <React.Fragment>
                                    {/* entity */}
                                    <Row>
                                        <Col sm={6}>
                                            <div className="view-profile-fld-row">
                                                <label>Trading Name:</label>
                                                <input type="text" {...register("TradingName", { required: true, maxLength: 80 })} />
                                            </div>
                                            <div className="view-profile-fld-row">
                                                <label>Registered Name:</label>
                                                <input type="text" {...register("RegistrationName", { required: true, maxLength: 80 })} />
                                            </div>
                                            <div className="view-profile-fld-row">
                                                <label>Registration Number:</label>
                                                <input type="text" {...register("IDNumber", { required: true, maxLength: 80 })} />
                                            </div>
                                            <div className="view-profile-fld-row">
                                                <label>VAT number:</label>
                                                <input type="text" {...register("VATNumber", { required: false, maxLength: 80 })} />
                                            </div>
                                        </Col>
                                        <Col sm={6}>
                                            <div className="view-profile-fld-row">
                                                <label>Mobile:</label>
                                                <input type="text" {...register("Mobile", { required: true, maxLength: 80 })} />
                                            </div>
                                            <div className="view-profile-fld-row">
                                                <label>Tel.:</label>
                                                <input type="text" {...register("Telephone", { required: false, maxLength: 80 })} />
                                            </div>
                                            <div className="view-profile-fld-row">
                                                <label>Email:</label>
                                                <input type="text" {...register("Email", { required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} />
                                            </div>
                                        </Col>
                                    </Row>
                                </React.Fragment>
                            }

                        </div>
                        <h2>Addresses</h2>
                        <div className="view-profile-wrapper-inner">
                            <Row>
                                <Col sm={6}>
                                    <div className="view-profile-fld-row">
                                        <label>Street Number:</label>
                                        <input type="text" {...register("StreetNumber", { required: true, maxLength: 160 })} />
                                    </div>
                                    <div className="view-profile-fld-row">
                                        <label>Street Address:</label>
                                        <input type="text" {...register("StreetName", { required: true, maxLength: 160 })} />
                                    </div>
                                    <div className="view-profile-fld-row">
                                        <label>City:</label>
                                        <input type="text" {...register("City", { required: true, maxLength: 160 })} />
                                    </div>
                                </Col>
                                <Col sm={6}>
                                    <div className="view-profile-fld-row">
                                        <label>Surburb:</label>
                                        <input type="text" {...register("Surburb", { required: true, maxLength: 80 })} />
                                    </div>
                                    <div className="view-profile-fld-row">
                                        <label>Postal Code:</label>
                                        <input type="text" {...register("PostalCode", { required: true, maxLength: 80 })} />
                                    </div>
                                    <div className="view-profile-fld-row">
                                        <label>Province:</label>
                                        <Controller
                                            name={"Province"}
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field: { value, onChange, onBlur } }) => {
                                                return (<Select
                                                    className={"form-control p-0" + (errors?.Province ? ' is-invalid' : '')}
                                                    options={provinces ? provinces : []}
                                                    placeholder={""}
                                                    onChange={onChange}
                                                    value={value}
                                                    defaultValue={value}
                                                />)
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <ul className="view-profile-tab">
                            <li className="active">Contact Information</li>
                            <li>Draft Properties</li>
                        </ul>
                        <div className="view-profile-btn">
                            <button className="btn btn-primary" type='submit'>Update</button>
                        </div>
                    </form>
                </div>
            </div>
        </React.Fragment >
    )
}

export default UpdateProfile