import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import Select from 'react-select';
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { useUserSelector } from '../../_common/hooks/selectors/userSelector';
import Header from './components/header';
import '../../styles/student/myprofile.scss';
import { IoIosArrowDropdownCircle } from 'react-icons/io';

const MyProfile = () => {
    const { register, handleSubmit, control, formState: { errors, isValid }, getValues, watch, reset } = useForm({ mode: "onChange" })
    const institution = watch('CompanyId');

    const user: any = useUserSelector()
    const commonApi = useCommonApi()
    const { showLoader, hideLoader } = useAppLoader()

    const [edit, setEdit] = useState<boolean>(false)
    const [institutions, setInstitutions] = useState<any>(null);
    const [campuses, setCampuses] = useState<any>(null);
    const [yearOfStudy, setYearOfStudy] = useState<any>(null);
    const [setPrevCampus, set_setPrevCampus] = useState<any>(null);

    const getStudentDetails = () => {
        showLoader();
        //call api reset form values via reset()
        commonApi.retreive({
            "Entity": "Employee",
            "Top": 100,
            "ReturnCols": ["FirstName","LastName","IdNumber","EmployeeNo","CompanyId" ,"CompanyIdName","CampusId","CampusIdName","Mobile","Email","YearOfStudyId"],
            "FilterOn": {
                "Type": "And",
                "Where": [{
                    "filterCol": "EmployeeId", "FilterCondition": "Equals",
                    "FilterValue": user.recordId
                }]
            }
        }, (message: string, resp: any) => {
            console.log({ resp: JSON.parse(resp)[0] })
            hideLoader();
            let prevData = JSON.parse(resp)[0];
            set_setPrevCampus(prevData.CampusId);
            reset({
                FirstName: prevData.FirstName,
                LastName: prevData.LastName,
                IDNumber: prevData.IdNumber,
                EmoployeeNo: prevData.EmployeeNo,
                CompanyId: getSelectDataById(prevData.CompanyId, institutions),
                // CampusId: prevData.CampusId,
                YearOfStudyId: getSelectDataById(prevData.YearOfStudyId, yearOfStudy),
                Mobile: prevData.Mobile,
                Telephone: prevData.Telephone,
                Email: prevData.Email
            })
        }, (message: string) => {
            hideLoader();
            toast.error(message);
        });
    }

    const getSelectDataById = (id: any, data: any) => {
        let option = null
        data?.map((_option: any) => {
            if (_option.value == id) {
                option = _option
            }
        })
        return option
    }

    const getInstitutions = () => {
        commonApi.retreiveInstitution({}, (message: string, resp: any) => {
            let options: any = [];
            resp.map((ins: any) => {
                if (ins.recordId.search('000000') !== 0) {
                    ins.value = ins.recordId;
                    ins.label = ins.mainText;
                    options.push(ins);
                }
            })
            // console.log(options)
            setInstitutions(options)
        }, (message: string) => {
            // console.log(message);
        });
    }

    const getCampus = (id: any = institution?.value) => {
        return new Promise((resolve: any, reject: any) => {
            commonApi.getCampus({
                filters: "InstitutionId eq '" + id + "'",
                ViewName: 'LookUpView'
            }, (message: string, resp: any) => {
                let options: any = [];
                resp.map((ins: any) => {
                    if (ins.recordId.search('000000') !== 0) {
                        ins.value = ins.recordId;
                        ins.label = ins.mainText;
                        options.push(ins);
                    }
                })
                setCampuses(options)
                setTimeout(() => {
                    resolve()
                }, 300)
            }, (message: string) => {
                // console.log(message);
                reject()
            });
        })
    }

    const getYearOfStudy = () => {
        commonApi.post({
            "entityName": "Picklist",
            "requestName": "RetrieveSelectOptionExecuteRequest",
            "inputParamters":
            {
                "SelectOption": {
                    "OptionText": "YearOfStudy"

                }
            }
        }, (message: string, resp: any) => {
            let data = resp.outputParameters.data;
            let options: any = [];
            data.map((ins: any) => {
                ins.value = ins.optionValue;
                ins.label = ins.optionText;
                options.push(ins);
            })
            // console.log(options)
            setYearOfStudy(options)
        }, (message: string) => {
            // console.log(message);
        });
    }

    const onSubmit = (data: any) => {
        console.log({ data })
        let params: any = {
            FirstName: data.FirstName,
            LastName: data.LastName,
            IDNumber: data.IDNumber,
            EmoployeeNo: data.EmoployeeNo,
            CompanyId: data.CompanyId.value,
            CampusId: data.CampusId.value,
            YearOfStudyId: data.YearOfStudyId.value,
            Mobile: data.Mobile,
            Telephone: data.Telephone,
            Email: data.Email
        }
        showLoader()
        commonApi.post({
            "entityName": "Employee",
            "recordId": user?.recordId,
            "requestName": "UpsertRecordReq",
            "inputParamters":
            {
                "Entity": params
            }
        }, (message: string, resp: any) => {
            toast.success(message)
            hideLoader()
            setEdit(false)
        }, (message: string) => {
            toast.error(message)
            hideLoader()
            setEdit(false)
        });
    }

    useEffect(() => {
        if (user && institutions && yearOfStudy) {
            getStudentDetails()
        }
    }, [user, institutions, yearOfStudy])

    useEffect(() => {
        if (campuses) {
            if (setPrevCampus) {
                let opt: any = getSelectDataById(setPrevCampus, campuses);
                set_setPrevCampus(null)
                // console.log({ opt, setPrevCampus, campuses })
                // console.log({...getValues(), ...{campus: opt}})
                reset({
                    ...getValues(), CampusId: opt
                })
            }
        }
    }, [campuses])

    useEffect(() => {
        getInstitutions()
        getYearOfStudy()
    }, [])

    useEffect(() => {
        if (institution) {
            getCampus()
        }
    }, [institution])

    return (
        <React.Fragment>
            <div className="dashboard my-profile-wrapper" style={{ paddingTop: "0px" }}>
                <Header />
                {/* <div className="my-profile-top-panel">
                    <Row>
                        <Col sm={6}>
                            <p>Application Status</p>
                            <p>Your recent application has been received and is under review, you will receive an email as soon as the status changes</p>
                        </Col>
                        <Col sm={6} className="text-end">
                            <div className="d-flex align-items-center justify-content-end"><span className="pe-2">Application Status</span> <IoIosArrowDropdownCircle /></div>
                        </Col>
                    </Row>
                </div> */}
                <Container>
                    <div className="white-block p-3 white-box-wide">
                        <div className="text-center mb-3"><h1>My Profile</h1></div>
                        <Row>
                            <Col sm={6}>Personal Information</Col>
                            {!edit ? <Col sm={6} className="text-end edit-btn"><Button onClick={() => setEdit(true)}>Edit</Button></Col> : null}
                        </Row>
                        <form className={!edit?"disabled-select":""} onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                <Col sm={6}>
                                    <div>
                                        <label htmlFor="exampleFormControlInput1">First Name</label>
                                    </div>
                                    <input disabled={!edit} type="text" id='exampleFormControlInput1' placeholder="" {...register("FirstName", { required: true, maxLength: 80 })} />
                                </Col>
                                <Col sm={6}>
                                    <div>
                                        <label htmlFor="exampleFormControlInput2">Campus</label>
                                    </div>
                                    <Controller
                                        name={"CampusId"}
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { value, onChange, onBlur } }) => {
                                            return (<Select
                                                className={"form-control p-0" + (errors?.CampusId ? ' is-invalid' : '')}
                                                options={campuses}
                                                placeholder={""}
                                                onChange={onChange}
                                                value={value}
                                                defaultValue={value}
                                                isDisabled={!edit}
                                            />)
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <div>
                                        <label htmlFor="exampleFormControlInput3">Last Name</label>
                                    </div>
                                    <input disabled={!edit} type="text" id='exampleFormControlInput3' placeholder="" {...register("LastName", { required: true, maxLength: 80 })} />
                                </Col>
                                <Col sm={6}>
                                    <div>
                                        <label htmlFor="exampleFormControlInput4">Year of study</label>
                                    </div>
                                    <Controller
                                        name={"YearOfStudyId"}
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { value, onChange, onBlur } }) => {
                                            return (<Select
                                                className={"form-control p-0" + (errors?.YearOfStudyId ? ' is-invalid' : '')}
                                                options={yearOfStudy}
                                                placeholder={""}
                                                onChange={onChange}
                                                value={value}
                                                defaultValue={value}
                                                isDisabled={!edit}
                                            />)
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <div>
                                        <label htmlFor="exampleFormControlInput5">ID Number</label>
                                    </div>
                                    <input disabled={!edit} type="text" id='exampleFormControlInput5' placeholder="" {...register("IDNumber", { required: true })} />
                                </Col>
                                <Col sm={6}>
                                    <div>
                                        <label htmlFor="exampleFormControlInput6">Mobile phone</label>
                                    </div>
                                    <input disabled={!edit} type="text" id='exampleFormControlInput6' placeholder="" {...register("Mobile", { required: true })} />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <div>
                                        <label htmlFor="exampleFormControlInput7">Student Number</label>
                                    </div>
                                    <input disabled={!edit} type="text" id='exampleFormControlInput7' placeholder="" {...register("EmoployeeNo", { required: true })} />
                                </Col>
                                <Col sm={6}>
                                    <div>
                                        <label htmlFor="exampleFormControlInput8">Email</label>
                                    </div>
                                    <input disabled={!edit} type="text" id='exampleFormControlInput8' placeholder="" {...register("Email", { required: true })} />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <div>
                                        <label htmlFor="exampleFormControlInput9">Institution</label>
                                    </div>
                                    <Controller
                                        name={"CompanyId"}
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { value, onChange, onBlur } }) => {
                                            return (<Select
                                                className={"form-control p-0" + (errors?.CompanyId ? ' is-invalid' : '')}
                                                options={institutions}
                                                placeholder={""}
                                                onChange={onChange}
                                                value={value}
                                                defaultValue={value}
                                                isDisabled={!edit}
                                            />)
                                        }}
                                    />
                                </Col>
                                <Col sm={6}>
                                    <div>
                                        <label htmlFor="exampleFormControlInput10">Password</label>
                                    </div>
                                    <input disabled={!edit} type="text" id='exampleFormControlInput10' placeholder="" {...register("Password", { required: false })} />
                                </Col>
                            </Row>
                            <Row className="mt-4"><h4>Documents</h4></Row>
                            <Row>
                                <Col sm={6}>
                                    <div>
                                        <label htmlFor="exampleFormControlInput11">ID Document</label></div>
                                    <input disabled={!edit} type="text" id='exampleFormControlInput11' placeholder="" {...register("IdDocument", { required: false })} />
                                </Col>
                                <Col sm={6}>
                                    <div>
                                        <label htmlFor="exampleFormControlInput12">Proof of registration</label>
                                    </div>
                                    <input disabled={!edit} type="text" id='exampleFormControlInput12' placeholder="" {...register("PfOfRegt", { required: false })} />
                                </Col>
                            </Row>
                            {edit ? <Row>
                                        <Col sm={6} className="save-btn">
                                        <Button type='submit'>Save</Button>
                                        </Col>
                                        
                                    </Row> : null}
                        </form>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default MyProfile